import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
const eventBus = EventBus();
const uuid = require("uuid/v4");

export const createCollection = (data) => {
  return async (dispatch, getState) => {
    try {
      let uniqueId = uuid();
      const firestore = firebase.firestore();
      let verifyCollection = firestore.collection("collections").doc(uniqueId);
      let verifyCollectionDoc = await verifyCollection.get();

      if (!verifyCollectionDoc.exists) {
        await firestore.collection("collections").doc(uniqueId).set({
          id: uniqueId,
          name: data.name.trim(),
          categories: data.categories,
          priority: data.priority,
        });

        eventBus.emit("collection-function-success");
      } else
        eventBus.emit(
          "collection-function-error",
          null,
          "The series has been created"
        );
    } catch (err) {
      eventBus.emit("collection-function-error", null, err);
    }
  };
};

export const editCollection = (data) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyCollection = firestore.collection("collections").doc(data.id);
      await verifyCollection.update({
        name: data.name,
        categories: data.categories,
        priority: data.priority,
      });

      eventBus.emit("collection-function-success");
    } catch (err) {
      eventBus.emit("collection-function-error", null, err);
    }
  };
};

export const deleteCollection = (data) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyCollection = firestore.collection("collections").doc(data.id);
      await verifyCollection.delete();
      eventBus.emit("collection-function-success");
    } catch (err) {
      eventBus.emit("collection-function-error", null, err);
    }
  };
};
