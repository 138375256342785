import firebase from "../../utils/firebase";

//==================================== Data Listener =============================================
let dataPointsListener = [];
let dataPoints = [
  { data: "books", orderBy: "title" },
  { data: "series", orderBy: "priority" },
  { data: "categories", orderBy: "priority" },
  { data: "collections", orderBy: "priority" },
  { data: "tags", orderBy: "title" },
  { data: "roles", orderBy: "name" },
  { data: "stickers", orderBy: "name" },
  { data: "banners" },
  { data: "notice" },
  { data: "faceStickers" },
  { data: "soundEffects" },
];
let dataLimit = 0;
export const userQueryLimit = 20;

export const getDatasetListener = (dispatch) => {
  try {
    if (dataPointsListener.length === 0)
      dataPoints.map((eachData) => {
        let singleDataListener = getSpecificData(
          dispatch,
          eachData.data,
          dataLimit,
          eachData.orderBy,
          eachData.where
        );
        if (singleDataListener) dataPointsListener.push(singleDataListener);
      });
  } catch (err) {}
};

export const removeDatasetListener = () => {
  return async (dispatch, getState) => {
    try {
      if (dataPointsListener.length > 0)
        dataPointsListener.map((eachDataListener) => {
          eachDataListener();
        });
      dataPointsListener = [];
    } catch (err) {}
  };
};

const getSpecificData = (dispatch, data, limit, orderBy, where) => {
  if (data) {
    let upperCaseData = data.toUpperCase();
    let queryDetails = firebase.firestore().collection(data);
    if (limit) queryDetails = queryDetails.limit(limit);
    if (where) queryDetails = queryDetails.where(where[0], where[1], where[2]);
    if (orderBy) queryDetails = queryDetails.orderBy(orderBy);

    let queryListener = queryDetails.onSnapshot((querySnapshot) => {
      let dataList = [];
      if (querySnapshot)
        querySnapshot.forEach(function (doc) {
          dataList.push(doc.data());
        });
      dispatch({
        type: `UPDATE_${upperCaseData}_DATA`,
        payload: {
          data: dataList,
        },
      });
    });

    return queryListener;
  } else return null;
};
