import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
const eventBus = EventBus();

export const createBanner = (data) => {
  return async (dispatch, getState) => {
    try {
      let selectedBanner = data.selectedBanner;
      let bannerFile = data.bannerFile;

      const firestore = firebase.firestore();
      let verifyBanner = firestore.collection("banners").doc(bannerFile.name);
      let verifyBannerDoc = await verifyBanner.get();

      if (!verifyBannerDoc.exists) {
        await firebase
          .storage()
          .ref(`assets_banner/${bannerFile.name}`)
          .put(bannerFile);
        let url = await firebase
          .storage()
          .ref("assets_banner")
          .child(bannerFile.name)
          .getDownloadURL();
        await firestore
          .collection("banners")
          .doc(bannerFile.name)
          .set({
            imageName: bannerFile.name,
            image: url.split("token=")[1],
            action: selectedBanner.action,
          });
        eventBus.emit("banner-function-success");
      } else
        eventBus.emit(
          "banner-function-error",
          null,
          "The banner has been created"
        );
    } catch (err) {
      eventBus.emit("banner-function-error", null, err);
    }
  };
};

export const editBanner = (data) => {
  return async (dispatch, getState) => {
    try {
      let selectedBanner = data.selectedBanner;
      let bannerFile = data.bannerFile;

      const firestore = firebase.firestore();
      let verifyBanner = firestore
        .collection("banners")
        .doc(selectedBanner.imageName);

      if (data.bannerFile) {
        await firebase
          .storage()
          .ref("assets_banner")
          .child(selectedBanner.imageName)
          .delete();
        await firebase
          .storage()
          .ref(`assets_banner/${bannerFile.name}`)
          .put(bannerFile);
        let url = await firebase
          .storage()
          .ref("assets_banner")
          .child(bannerFile.name)
          .getDownloadURL();

        await verifyBanner.delete();
        await firestore
          .collection("banners")
          .doc(bannerFile.name)
          .set({
            imageName: bannerFile.name,
            image: url.split("token=")[1],
            action: selectedBanner.action,
          });
      } else
        await verifyBanner.update({
          action: selectedBanner.action,
        });

      eventBus.emit("banner-function-success");
    } catch (err) {
      eventBus.emit("banner-function-error", null, err);
    }
  };
};

export const deleteBanner = (imageName) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyBanner = firestore.collection("banners").doc(imageName);
      await verifyBanner.delete();
      await firebase.storage().ref("assets_banner").child(imageName).delete();
      eventBus.emit("banner-function-success");
    } catch (err) {
      eventBus.emit("banner-function-error", null, err);
    }
  };
};
