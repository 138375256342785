import React, { Component } from "react";
import { Input } from "antd";
//Redux Firebase
import { connect } from "react-redux";
//Actions
import { editNotice } from "../../store/actions/noticeActions";
// Components
import {
  BackButton,
  ContentContainer,
  TopBarBreadcrumb,
  BreadcrumbActive,
  TopBarSaveButton,
  TopBar,
  FormText,
  CustomForm,
} from "./Layout";
import { SaveDialog } from "../Dialog";
//Utils
import EventBus from "js-event-bus";

const { TextArea } = Input;
const eventBus = EventBus();

class Notice extends Component {
  state = {
    notice: "",
    noticeError: "",
  };

  componentDidMount() {
    this.handleUpdateNoticeState();
    eventBus.on("notice-function-success", this.handleClearState);
    eventBus.on("notice-function-error", this.handleError);
  }

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("notice-function-success");
    eventBus.detach("notice-function-error");
  }

  componentDidUpdate() {
    this.handleUpdateNoticeState();
  }

  handleUpdateNoticeState = () => {
    if (
      this.state.notice === "" &&
      this.props.notice.length > 0 &&
      this.state.notice !== this.props.notice[0].notice
    )
      this.setState({ notice: this.props.notice[0].notice });
  };

  handleError = (err) => {
    this.setState(
      {
        noticeError: err,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleClearState = () => {
    this.setState(
      {
        notice: this.props.notice[0].notice,
        noticeError: "",
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleCancel = () => {
    this.setState({ notice: this.props.notice[0].notice }, () => {
      this.props.handleDataChanged(false);
    });
  };

  handleChange = (event) => {
    this.setState(
      {
        notice: event.target.value,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleSubmit = (type) => {
    this.props.handleLoading(true);

    if (this.state.notice === "")
      this.setState({ noticeError: "Please insert the notice" });
    else this.setState({ noticeError: "" });

    setTimeout(() => {
      if (!this.state.noticeError) {
        this.props.editNotice({
          notice: this.state.notice,
        });
        this.props.handleDataChanged(false);
      } else this.props.handleLoading(false);
    }, 500);
  };

  renderTopBarContent = () => {
    return (
      <>
        <BackButton onClick={this.handleCancel} />
        <TopBarBreadcrumb>
          <BreadcrumbActive>Notice</BreadcrumbActive>
        </TopBarBreadcrumb>

        <div style={{ flex: 1 }} />
        <TopBarSaveButton onClick={this.handleSubmit}>Save</TopBarSaveButton>
        <div style={{ width: 30 }} />
      </>
    );
  };

  renderContent = () => {
    return (
      <CustomForm style={{ width: "96%", marginLeft: "20px" }}>
        <CustomForm.Item
          validateStatus={this.state.noticeError ? "error" : "success"}
          help={this.state.noticeError ? this.state.noticeError : null}
        >
          <FormText>Notice</FormText>
          <TextArea
            id="notice"
            rows={4}
            value={this.state.notice ? this.state.notice : null}
            onChange={this.handleChange}
            style={{ borderRadius: 10, width: "48%" }}
          />
        </CustomForm.Item>
      </CustomForm>
    );
  };

  render() {
    return (
      <>
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editNotice: (notice) => dispatch(editNotice(notice)),
  };
};

export default connect(null, mapDispatchToProps)(Notice);
