import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
//Components
import LoginScreen from "./pages/LoginScreen";
//Actions
import {
  getAuthListener,
  removeAuthListener,
} from "./store/actions/authActions";
import { removeDatasetListener } from "./store/actions/dataActions";

class App extends Component {
  componentDidMount() {
    this.props.getAuthListener();
  }

  componentWillUnmount() {
    this.props.removeAuthListener();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/banners" component={Dashboard} />
          <Route exact path="/notice" component={Dashboard} />
          <Route exact path="/books" component={Dashboard} />
          <Route exact path="/series" component={Dashboard} />
          <Route exact path="/categories" component={Dashboard} />
          <Route exact path="/collections" component={Dashboard} />
          <Route exact path="/tags" component={Dashboard} />
          <Route exact path="/roles" component={Dashboard} />
          <Route exact path="/users" component={Dashboard} />
          <Route exact path="/stickers" component={Dashboard} />
          <Route exact path="/faceSticker" component={Dashboard} />
          <Route exact path="/soundEffect" component={Dashboard} />
          <Route exact path="/codes" component={Dashboard} />
          <Route exact path="/generic" component={Dashboard} />
          {/* <Route exact path="/children" component={Dashboard} />
          <Route exact path="/analytics" component={Dashboard} />
          <Route exact path="/reports" component={Dashboard} /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
    removeDatasetListener: () => dispatch(removeDatasetListener()),
  };
};

export default connect(null, mapDispatchToProps)(App);
