import React, { Component } from "react";
import styled from "styled-components";
import { Button, Input, Pagination } from "antd";
import CsvDownloader from "react-csv-downloader";
import { Grey, Blue } from "../../assets/styles/Colors";
//Components
import {
  BackButton,
  NavBar,
  TableLayout,
  TableContent,
  TableContentDefault,
  ContentContainer,
  TopBarSearch,
  TopBar,
  CustomForm,
  FormText,
  TopBarBreadcrumb,
  BreadcrumbActive,
  BreadcrumbLink,
} from "./Layout";
//Utils
import axios from "axios";
import countryList from "../../../src/utils/countryList";

const genericCodesTableColumns = [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    width: 500,
  },
  {
    title: "Code Type",
    dataIndex: "type",
    key: "type",
    width: 500,
  },
  {
    title: "Users",
    dataIndex: "users",
    key: "users",
    width: 500,
  },
];

const usersTableColumns = [
  {
    title: "Number",
    dataIndex: "number",
    key: "number",
    width: 100,
  },
  {
    title: "Emails",
    dataIndex: "emails",
    key: "emails",
    width: 100,
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    width: 100,
  },
];

const csvColumns = [
  {
    id: "number",
    displayName: "Number",
  },
  {
    id: "genericCode",
    displayName: "Generic Code",
  },
  {
    id: "codeType",
    displayName: "Code Type",
  },
  {
    id: "email",
    displayName: "Email",
  },
  {
    id: "country",
    displayName: "Country",
  },
];

class GenericCodes extends Component {
  state = {
    function: "",
    search: "",
    selectedGenericCodes: [],
    genericCodes: [],
    page: 1,
    showingUser: [],
    limit: 100,
  };

  componentDidMount() {
    this.handleClearState();
    this.handleGetCode();
  }

  componentWillUnmount() {
    this.handleClearState();
  }

  handleGetCode = async () => {
    let codeRes = await axios.post(
      "https://us-central1-mebooks-plus.cloudfunctions.net/getGenericCodesDetails"
    );
    if (codeRes.data)
      this.setState({
        genericCodes: codeRes.data,
      });
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, code) => {
    this.setState({
      function: type,
      selectedGenericCodes: code,
      page: 1,
      limit: 100,
    });
  };

  handleCSV = () => {
    let Datas = [];

    if (this.state.selectedGenericCodes.email)
      for (let i = 0; i < this.state.selectedGenericCodes.email.length; i++) {
        let country = this.handleCountry(
          this.state.selectedGenericCodes.country[i]
        );
        let tempDatas = {
          number: i + 1,
          genericCode: this.state.selectedGenericCodes.code,
          codeType: this.state.selectedGenericCodes.type,
          email: this.state.selectedGenericCodes.email[i],
          country: country,
        };
        Datas = Datas.concat(tempDatas);
      }
    return Datas;
  };

  handleCountry = (country) => {
    for (let i = 0; i < countryList.length; i++) {
      if (country === countryList[i].value) return countryList[i].label;
    }
  };

  handlePagination = (page) => {
    let limit = page * 100;
    this.setState({ page: page, limit: limit }, () => {
      this.handleGetCode(this.state.limit - 100, this.state.limit);
    });
  };

  renderTopBarContent = () => {
    return (
      <>
        {this.state.function ? (
          <>
            <BackButton onClick={this.handleFunction.bind(this, "")} />
            <TopBarBreadcrumb>
              <BreadcrumbLink onClick={this.handleFunction.bind(this, "")}>
                Generic Codes
              </BreadcrumbLink>
              <BreadcrumbActive>Show Users</BreadcrumbActive>
            </TopBarBreadcrumb>

            <div style={{ flex: 1 }} />
          </>
        ) : (
          <>
            <TopBarBreadcrumb style={{ left: 20 }}>
              <BreadcrumbActive>Generic Codes</BreadcrumbActive>
            </TopBarBreadcrumb>

            <TopBarSearch
              value={this.state.search}
              onChange={this.handleSearch}
              placeholder="Search"
            />
          </>
        )}
      </>
    );
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <div style={{ width: "100%" }}>
          <CustomForm>
            <CustomForm.Item>
              <FormText>Name of Generic Code</FormText>
              <Input
                id="name"
                value={this.state.selectedGenericCodes.code}
                style={{ width: "400px", borderRadius: 10 }}
                disabled
              />
            </CustomForm.Item>
          </CustomForm>
          <CustomForm>
            <CustomForm.Item>
              <FormText>Code Type</FormText>
              <Input
                id="type"
                value={this.state.selectedGenericCodes.type}
                style={{ width: "400px", borderRadius: 10 }}
                disabled
              />
            </CustomForm.Item>
          </CustomForm>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormText
              style={{ marginTop: 40, marginLeft: 20, marginBottom: 0 }}
            >
              Generic Code Users (Total :
              {this.state.selectedGenericCodes.email
                ? " " + this.state.selectedGenericCodes.email.length
                : " " + 0}
              )
            </FormText>
            <div style={{ flexGrow: 1 }} />
            <CsvContainer>
              <CsvDownloader
                filename="Generic Codes"
                columns={csvColumns}
                datas={this.handleCSV()}
              >
                <CsvButton>Download CSV</CsvButton>
              </CsvDownloader>
            </CsvContainer>
          </div>
          <TableLayout
            columns={usersTableColumns}
            dataSource={this.renderUsers()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.state.selectedGenericCodes.email ? (
            <NavBar style={{ marginBottom: 20 }}>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={Math.ceil(
                  this.state.selectedGenericCodes.email.length / 100
                )}
                showSizeChanger={false}
              />
            </NavBar>
          ) : null}
        </div>
      );
    else
      return (
        <TableLayout
          columns={genericCodesTableColumns}
          dataSource={this.renderGenericCodes()}
          pagination={false}
        />
      );
  };

  renderGenericCodes = () => {
    let genericCodeView = [];

    this.state.genericCodes.map((eachCode, index) => {
      if (
        eachCode.code &&
        eachCode.code
          .toLowerCase()
          .trim()
          .includes(this.state.search.toLowerCase().trim())
      )
        genericCodeView.push({
          key: index,
          code: (
            <TableContent
              onClick={this.handleFunction.bind(this, "users", eachCode)}
            >
              {eachCode.code}
            </TableContent>
          ),
          type: (
            <TableContentDefault
              onClick={this.handleFunction.bind(this, "users", eachCode)}
            >
              {eachCode.type}
            </TableContentDefault>
          ),
          users: (
            <TableContentDefault
              onClick={this.handleFunction.bind(this, "users", eachCode)}
            >
              {eachCode.email ? eachCode.email.length : 0}
            </TableContentDefault>
          ),
        });
    });
    return genericCodeView;
  };

  renderUsers = () => {
    let userView = [];
    if (
      this.state.selectedGenericCodes.email &&
      this.state.selectedGenericCodes.email.length > 0
    ) {
      let min = this.state.limit - 100;
      let max = this.state.limit;

      for (let i = min; i < max; i++) {
        if (!this.state.selectedGenericCodes.email[i]) break;
        let country = this.handleCountry(
          this.state.selectedGenericCodes.country[i]
        );
        userView.push({
          key: i,
          number: <TableContentDefault>{i + 1}</TableContentDefault>,
          emails: (
            <TableContentDefault>
              {this.state.selectedGenericCodes.email[i]}
            </TableContentDefault>
          ),
          country: <TableContentDefault>{country}</TableContentDefault>,
        });
      }
    }
    return userView;
  };

  render() {
    return (
      <>
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

export default GenericCodes;

const CsvContainer = styled.div`
  margin-top: 30px;
  margin-right: 20px;
`;

const CsvButton = styled(Button)`
  border-radius: 10px;
  border: 1px solid ${Grey} !important;

  &:hover {
    cursor: pointer;
    border: 1px solid ${Blue} !important;
    color: ${Blue};
  }
`;
