import { Menu } from "antd";
import React, { Component } from "react";
import styled from "styled-components";

const { SubMenu } = Menu;

export default class LeftBar extends Component {
  rootSubmenuKeys = [
    "dashboard",
    "notification",
    "products",
    "organisation",
    "people",
    "content",
    "codes",
  ];

  state = {
    openKeys: ["dashboard"],
  };

  handleNavigate = (link) => {
    if (this.props.dataChanged) this.props.handleSwitchPage(link);
    else {
      this.props.handleSwitchPage("");
      this.props.history.push(link);
    }
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  renderNavBar = () => {
    return (
      <Menu
        style={{ width: "200px" }}
        mode="inline"
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
      >
        <Menu.Item
          key="dashboard"
          onClick={() => this.handleNavigate("/dashboard")}
          style={
            this.props.location.pathname === "/dashboard"
              ? { color: "#fff", background: "rgb(60,182,255)" }
              : null
          }
        >
          Dashboard
        </Menu.Item>
        <SubMenu key="notification" title="Notification">
          <Menu.Item
            key={"banners"}
            onClick={() => this.handleNavigate("/banners")}
            style={
              this.props.location.pathname === "/banners"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Banners
          </Menu.Item>
          <Menu.Item
            key="notice"
            onClick={() => this.handleNavigate("/notice")}
            style={
              this.props.location.pathname === "/notice"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Notice
          </Menu.Item>
        </SubMenu>
        <SubMenu key="products" title="Products">
          <Menu.Item
            key={"books"}
            onClick={() => this.handleNavigate("/books")}
            style={
              this.props.location.pathname === "/books"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Books
          </Menu.Item>
          <Menu.Item
            key={"series"}
            onClick={() => this.handleNavigate("/series")}
            style={
              this.props.location.pathname === "/series"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Series
          </Menu.Item>
        </SubMenu>
        <SubMenu key="organisation" title="Organisation">
          <Menu.Item
            key={"categories"}
            onClick={() => this.handleNavigate("/categories")}
            style={
              this.props.location.pathname === "/categories"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Categories
          </Menu.Item>
          <Menu.Item
            key={"categories"}
            onClick={() => this.handleNavigate("/collections")}
            style={
              this.props.location.pathname === "/collections"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Collections
          </Menu.Item>
          <Menu.Item
            key={"tags"}
            onClick={() => this.handleNavigate("/tags")}
            style={
              this.props.location.pathname === "/tags"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Tags
          </Menu.Item>
        </SubMenu>
        <SubMenu key="people" title="People">
          <Menu.Item
            key={"roles"}
            onClick={() => this.handleNavigate("/roles")}
            style={
              this.props.location.pathname === "/roles"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Roles
          </Menu.Item>
          <Menu.Item
            key={"users"}
            onClick={() => this.handleNavigate("/users")}
            style={
              this.props.location.pathname === "/users"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Users
          </Menu.Item>
        </SubMenu>
        <SubMenu key="content" title="Content">
          <Menu.Item
            key={"stickers"}
            onClick={() => this.handleNavigate("/stickers")}
            style={
              this.props.location.pathname === "/stickers"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Stickers
          </Menu.Item>
          <Menu.Item
            key={"faceSticker"}
            onClick={() => this.handleNavigate("/faceSticker")}
            style={
              this.props.location.pathname === "/faceSticker"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Face Stickers (Demo)
          </Menu.Item>
          <Menu.Item
            key={"soundEffect"}
            onClick={() => this.handleNavigate("/soundEffect")}
            style={
              this.props.location.pathname === "/soundEffect"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Sound Effect (Demo)
          </Menu.Item>
        </SubMenu>
        <SubMenu key="codes" title="Codes">
          <Menu.Item
            key={"codes"}
            onClick={() => this.handleNavigate("/codes")}
            style={
              this.props.location.pathname === "/codes"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Redeem Codes
          </Menu.Item>
          <Menu.Item
            key={"generic"}
            onClick={() => this.handleNavigate("/generic")}
            style={
              this.props.location.pathname === "/generic"
                ? { color: "#fff", background: "rgb(60,182,255)" }
                : null
            }
          >
            Generic Codes
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  };

  render() {
    return <Container>{this.renderNavBar()}</Container>;
  }
}

const Container = styled.div`
  top: 50px;
  position: absolute;
  width: 200px;
  height: calc(100% - 50px);
  border-right: solid 0.8px rgb(229, 229, 234);
`;
