import { combineReducers } from "redux";
//Custom Reducer
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import childReducer from "./childReducer";
import analyticsReducer from "./analyticsReducer";
import reportReducer from "./reportReducer";
import dataReducer from "./dataReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  userStore: userReducer,
  childStore: childReducer,
  analyticStore: analyticsReducer,
  reportStore: reportReducer,
  dataStore: dataReducer,
});

export default rootReducer;
