import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { DropdownButton, DropdownContent } from "../components/content/Layout";
import { Menu, Dropdown, Typography } from "antd";
import { QuestionOutlined, LogoutOutlined } from "@ant-design/icons";
import { Black } from "../assets/styles/Colors";
//Assets
import MeBooksLogo from "../assets/icon.png";
//Action
import { signOut } from "../store/actions/authActions";

const { Title } = Typography;

class TopBar extends Component {
  handleOptions = () => {
    return (
      <Menu>
        <Menu.Item key="help">
          <QuestionOutlined />
          Help
        </Menu.Item>
        <Menu.Item key="signOut" onClick={this.props.signOut}>
          <LogoutOutlined />
          Sign Out
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    return (
      <Container>
        <LogoContainer>
          <Logo src={MeBooksLogo} />
          <Title
            level={4}
            style={{
              fontWeight: "bold",
              marginLeft: "8px",
              marginTop: "10px",
              color: Black,
            }}
          >
            Me Books Dashboard
          </Title>
        </LogoContainer>
        <div style={{ flex: 1 }} />

        <DropDownContainer>
          <Dropdown overlay={this.handleOptions()} trigger={["click"]}>
            <DropdownContent
              onClick={(e) => e.preventDefault()}
              style={{
                color: Black,
                marginTop: "10px",
              }}
            >
              Account
              {/* <Title level={4} style={{fontWeight:"bold", margin}}>Account</Title> */}
              <DropdownButton style={{ marginLeft: "10px" }} />
            </DropdownContent>
          </Dropdown>
        </DropDownContainer>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(TopBar);

const DropDownContainer = styled.div`
  margin-right: 30px;
  font-weight: bold;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  top: 0;
  position: absolute;
  width: 100%;
  height: 50px;
  background: rgb(242, 242, 247);
  align-items: center;
  display: flex;
`;

const Logo = styled.img`
  width: 34px;
  height: 34px;
  margin-left: 25px;
`;
