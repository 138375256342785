import React, { Component } from "react";
import { Input, Pagination } from "antd";
//Redux Firebase
import { connect } from "react-redux";
import { compose } from "redux";
//Components
import {
  BackButton,
  NavBar,
  TableContentDefault,
  TableLayout,
  TableContent,
  TopBarBreadcrumb,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarSearch,
  TopBar,
  FormText,
  ContentContainer,
  CustomForm,
} from "./Layout";
//Actions
import {
  updateChildQuery,
  updateAnalyticQuery,
} from "../../store/actions/childActions";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 500,
  },
  {
    title: "Birthday",
    dataIndex: "birthday",
    key: "birthday",
    width: 500,
  },
];

const badgesColumns = [
  {
    title: "Badge Name",
    dataIndex: "badge",
    key: "badge",
  },
];

const favouriteBooksColumn = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Book ID",
    dataIndex: "book",
    key: "book",
  },
];

const analyticsColumn = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Book ID",
    dataIndex: "bookId",
    key: "bookId",
  },
  {
    title: "Narration Count",
    dataIndex: "narrationCount",
    key: "narrationCount",
  },
  {
    title: "Narrator",
    dataIndex: "narrator",
    key: "narrator",
  },
  {
    title: "Page View",
    dataIndex: "pageView",
    key: "pageView",
  },
  {
    title: "Recording",
    dataIndex: "recording",
    key: "recording",
  },
  {
    title: "Screen Time",
    dataIndex: "screenTime",
    key: "screenTime",
  },
];

class Children extends Component {
  state = {
    function: "",
    search: "",
    selectedChildren: [],
    limit: 100,
    page: 1,
    analyticsPage: 1,
    analyticsLimit: 20,
    favouriteBooksPage: 1,
    favouriteBooksLimit: 20,
  };

  componentWillUnmount() {
    this.props.updateChildQuery(100, "");
  }

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedChildren: [],
        limit: 100,
        page: 1,
        analyticsPage: 1,
        analyticsLimit: 20,
        favouriteBooksPage: 1,
        favouriteBooksLimit: 20,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, childrenData) => {
    if (type === "edit")
      this.setState(
        {
          function: type,
          selectedChildren: childrenData,
          analyticsPage: 1,
          analyticsLimit: 20,
          favouriteBooksPage: 1,
          favouriteBooksLimit: 20,
        },
        () => {
          this.props.updateAnalyticQuery({
            analyticsLimit: this.state.analyticsLimit,
            childrenId: this.state.selectedChildren.id,
          });
        }
      );
    else this.setState({ function: type });
  };

  handleSearch = (event) => {
    this.setState(
      {
        search: event.target.value,
        limit: 100,
        page: 1,
      },
      () => {
        clearInterval(this.timer);
        this.timer = setInterval(this.handleTimerSearch, 500);
      }
    );
  };

  handleTimerSearch = () => {
    this.props.updateChildQuery(100, this.state.search);
    clearInterval(this.timer);
  };

  handlePagination = (page) => {
    let newLimit = 100 * page;
    this.props.updateChildQuery(newLimit, this.state.search);

    this.setState({ page: page, limit: newLimit });
  };

  handleAnalyticsPagination = (page) => {
    let newLimit = 20 * page;
    this.props.updateAnalyticQuery({
      analyticsLimit: newLimit,
      childrenId: this.state.selectedChildren.id,
    });
    this.setState({ analyticsPage: page, analyticsLimit: newLimit });
  };

  handleFavouriteBooksPagination = (page) => {
    let newLimit = 20 * page;
    this.setState({ favouriteBooksPage: page, favouriteBooksLimit: newLimit });
  };

  handlePageView = (pageView) => {
    let count = 0;
    if (pageView && pageView.length > 0)
      for (let i = 0; i < pageView.length; i++) count += pageView[i];
    return count;
  };

  handleScreenTime = (screenTime) => {
    let count = 0;
    if (screenTime && screenTime.length > 0)
      for (let i = 0; i < screenTime.length; i++) count += screenTime[i];
    return this.sec2time(count);
  };

  renderChildren = () => {
    let childrenView = [];
    if (this.props.children && this.props.children.length > 0) {
      let min = (this.state.page - 1) * 100;
      let max = this.props.children.length;

      this.props.children.slice(min, max).map((eachChildren, index) => {
        childrenView.push({
          key: index,
          name: (
            <TableContent
              onClick={this.handleFunction.bind(this, "edit", eachChildren)}
            >
              {eachChildren.name}
            </TableContent>
          ),
          birthday: (
            <TableContentDefault
              onClick={this.handleFunction.bind(this, "edit", eachChildren)}
            >
              {eachChildren.birthday
                ? new Date(
                    eachChildren.birthday.seconds * 1000
                  ).toLocaleString()
                : null}
            </TableContentDefault>
          ),
        });
      });
    }

    return childrenView;
  };

  renderBadges = () => {
    let badgesView = [];
    if (this.state.selectedChildren.badges) {
      this.state.selectedChildren.badges.map((eachBadge, index) => {
        badgesView.push({
          key: index,
          badge: <TableContentDefault>{eachBadge}</TableContentDefault>,
        });
      });
    }

    return badgesView;
  };

  renderFavouriteBooks = () => {
    let booksView = [];
    let token = "";

    if (this.state.selectedChildren.favourites && this.props.books) {
      let min = (this.state.favouriteBooksPage - 1) * 20;
      let max = this.state.selectedChildren.favourites.length;
      this.state.selectedChildren.favourites
        .slice(min, max)

        .map((eachFavourite, index) => {
          this.props.books.map((eachBook) => {
            if (eachBook.id === eachFavourite) token = eachBook.image;
          });
          let url = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${eachFavourite}%2FpreviewImage.png?alt=media&token=${token}`;
          booksView.push({
            key: index,
            image: (
              <TableContentDefault>
                <img
                  src={url}
                  style={{ height: "100px" }}
                  alt={eachFavourite}
                />
              </TableContentDefault>
            ),
            book: <TableContentDefault>{eachFavourite}</TableContentDefault>,
          });
        });
    }

    return booksView;
  };

  sec2time = (timeInSeconds) => {
    let pad = function (num, size) {
        return ("000" + num).slice(size * -1);
      },
      time = parseFloat(timeInSeconds).toFixed(3),
      hours = Math.floor(time / 60 / 60),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);

    return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
  };

  renderAnalytics = () => {
    let analyticsView = [];
    let token = "";

    if (this.props.analytics && this.props.books) {
      let min = (this.state.analyticsPage - 1) * 20;
      let max = this.props.analytics.length;

      this.props.analytics.slice(min, max).map((eachAnalytic, index) => {
        this.props.books.map((eachBook) => {
          if (eachBook.id === eachAnalytic.bookId) token = eachBook.image;
        });
        let url = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${eachAnalytic.bookId}%2FpreviewImage.png?alt=media&token=${token}`;
        analyticsView.push({
          key: index,
          image: (
            <TableContentDefault>
              <img
                src={url}
                style={{ height: "100px" }}
                alt={eachAnalytic.bookId}
              />
            </TableContentDefault>
          ),
          bookId: (
            <TableContentDefault>{eachAnalytic.bookId}</TableContentDefault>
          ),
          narrationCount: (
            <TableContentDefault>
              {eachAnalytic.narrationCount
                ? eachAnalytic.narrationCount.length
                : 0}
            </TableContentDefault>
          ),
          narrator: (
            <TableContentDefault>
              {eachAnalytic.narrator ? eachAnalytic.narrator.length : 0}
            </TableContentDefault>
          ),
          pageView: (
            <TableContentDefault>
              {this.handlePageView(eachAnalytic.pageView)}
            </TableContentDefault>
          ),
          recording: (
            <TableContentDefault>
              {eachAnalytic.recording ? eachAnalytic.recording.length : 0}
            </TableContentDefault>
          ),
          screenTime: (
            <TableContentDefault>
              {this.handleScreenTime(eachAnalytic.screenTime)}
            </TableContentDefault>
          ),
        });
      });
    }

    return analyticsView;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "")} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "")}>
              Children
            </BreadcrumbLink>
            <BreadcrumbActive>Editing Children</BreadcrumbActive>
          </TopBarBreadcrumb>
          <div style={{ flex: 1 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Children</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <>
          <CustomForm>
            <FormText>Name</FormText>
            <Input
              id="name"
              value={this.state.selectedChildren.name}
              style={{ width: "400px", borderRadius: 10, marginBottom: 20 }}
              disabled
            />

            <FormText>Birthday</FormText>
            <Input
              id="birthday"
              value={
                this.state.selectedChildren.birthday
                  ? new Date(
                      this.state.selectedChildren.birthday.seconds * 1000
                    ).toLocaleString()
                  : null
              }
              style={{ width: "400px", borderRadius: 10, marginBottom: 20 }}
              disabled
            />

            <FormText style={{ marginTop: 30 }}>
              Badges (Total : {this.state.selectedChildren.badges.length})
            </FormText>
            <TableLayout
              style={{ marginTop: "0px", marginLeft: "0px", width: "100%" }}
              columns={badgesColumns}
              dataSource={this.renderBadges()}
              pagination={false}
              scroll={{ y: window.innerHeight - 250 }}
            />

            <FormText style={{ marginTop: 30 }}>
              Favourite Books (Total :{" "}
              {this.state.selectedChildren.favourites
                ? this.state.selectedChildren.favourites.length
                : 0}
              )
            </FormText>
            <TableLayout
              style={{ marginTop: "0px", marginLeft: "0px", width: "100%" }}
              columns={favouriteBooksColumn}
              dataSource={this.renderFavouriteBooks()}
              pagination={false}
              scroll={{ y: window.innerHeight - 250 }}
            />
            {this.state.selectedChildren.favourites &&
            this.state.selectedChildren.favourites.length > 0 ? (
              <NavBar>
                <Pagination
                  current={this.state.favouriteBooksPage}
                  pageSize={1}
                  onChange={this.handleFavouriteBooksPagination}
                  total={
                    this.state.selectedChildren.favourites.length <
                    this.state.favouriteBooksLimit
                      ? this.state.favouriteBooksPage
                      : this.state.favouriteBooksPage + 1
                  }
                />
              </NavBar>
            ) : null}

            <FormText style={{ marginTop: 30 }}>
              Analytics (Total :{" "}
              {this.props.analytics ? this.props.analytics.length : 0})
            </FormText>
            <TableLayout
              style={{ marginTop: "0px", marginLeft: "0px", width: "100%" }}
              columns={analyticsColumn}
              dataSource={this.renderAnalytics()}
              pagination={false}
              scroll={{ y: window.innerHeight - 250 }}
            />
            {this.props.analytics && this.props.analytics.length > 0 ? (
              <NavBar>
                <Pagination
                  current={this.state.analyticsPage}
                  pageSize={1}
                  onChange={this.handleAnalyticsPagination}
                  total={
                    this.props.analytics.length < this.state.analyticsLimit
                      ? this.state.analyticsPage
                      : this.state.analyticsPage + 1
                  }
                />
              </NavBar>
            ) : null}
          </CustomForm>
        </>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderChildren()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.children !== undefined ? (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.props.children.length < this.state.limit
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          ) : null}
        </>
      );
  };

  render() {
    return (
      <>
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    children: state.firestore.ordered.children,
    analytics: state.firestore.ordered.analytics,
    childStore: state.childStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateChildQuery: (limit, search) =>
      dispatch(updateChildQuery(limit, search)),
    updateAnalyticQuery: (data) => dispatch(updateAnalyticQuery(data)),
  };
};

export default Children;
// export default compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   firestoreConnect((props) => {
//     let firestoreList = [];

//     if (props.childStore.search) {
//       let strSearch = props.childStore.search;
//       let strlength = strSearch.length;
//       let strFrontCode = strSearch.slice(0, strlength - 1);
//       let strEndCode = strSearch.slice(strlength - 1, strSearch.length);

//       let startcode = strSearch;
//       let endcode =
//         strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

//       firestoreList.push({
//         collection: "children",
//         where: [
//           ["name", ">=", startcode],
//           ["name", "<", endcode],
//         ],
//         limit: props.childStore.limit,
//       });
//     } else {
//       firestoreList.push({
//         collection: "children",
//         limit: props.childStore.limit,
//       });
//     }
//     if (props.childStore.childrenId && props.childStore.childrenId.length > 0) {
//       firestoreList.push({
//         collection: "analytics",
//         where: ["childId", "==", props.childStore.childrenId],
//         limit: props.childStore.analyticsLimit,
//       });
//     }
//     return firestoreList;
//   })
// )(Children);
