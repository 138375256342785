import React, { Component } from "react";
import { Modal } from "antd";
//Components
import { BlueButton, RedButton } from "../components/content/Layout";

export class SaveDialog extends Component {
  handleNo = (type) => {
    if (type === "next") {
      this.props.history.push(this.props.switchPage);
      this.props.handleSwitchPage("");
    } else {
      this.props.handleSwitchPage("stay");
    }
  };

  handleYes = () => {
    this.props.handleSubmit();
  };

  renderModalContent = () => {
    return (
      <div style={{ margin: 20 }}>
        <BlueButton onClick={this.handleYes}>Yes</BlueButton>
        <RedButton
          onClick={() => this.handleNo("next")}
          style={{ marginLeft: 50 }}
        >
          No
        </RedButton>
      </div>
    );
  };

  render() {
    return (
      <Modal
        title="Do you want to save your data?"
        visible={this.props.visible}
        footer={null}
        onCancel={() => this.handleNo("stay")}
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

export class DeleteDialog extends Component {
  renderModalContent = () => {
    return (
      <div style={{ margin: 20 }}>
        <RedButton onClick={this.props.handleDelete.bind(this, null)}>
          Yes
        </RedButton>
        <BlueButton
          onClick={this.props.handleDelete.bind(this, "close")}
          style={{ marginLeft: 50 }}
        >
          No
        </BlueButton>
      </div>
    );
  };

  render() {
    return (
      <Modal
        title="Do you want to delete this data?"
        visible={this.props.deleteModal}
        footer={null}
        onCancel={this.props.handleDelete.bind(this, "close")}
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}
