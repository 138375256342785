import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
const eventBus = EventBus();

export const editNotice = (data) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyNotice = firestore.collection("notice").doc("notice");
      if (!verifyNotice.get().exists)
        await firestore
          .collection("notice")
          .doc("notice")
          .set({ notice: data.notice.trim() });
      else
        await verifyNotice.update({
          notice: data.notice.trim(),
        });

      eventBus.emit("notice-function-success");
    } catch (err) {
      eventBus.emit("notice-function-error", null, err);
    }
  };
};
