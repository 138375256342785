import styled from "styled-components";
import { Button, Breadcrumb, Input, Table, Spin, Form } from "antd";
import { Black, Blue, White, Red, Grey } from "../../assets/styles/Colors";
import {
  PlusCircleFilled,
  CaretDownOutlined,
  MinusCircleFilled,
  LeftOutlined,
} from "@ant-design/icons";

const Search = Input;

export const BackButton = styled(LeftOutlined)`
  color: ${Blue};
  font-size: 20px;
  position: absolute;
  left: 20px;
`;

export const BlueButton = styled(Button)`
  color: ${White} !important;
  border: solid 1px ${Blue} !important;
  background: ${Blue} !important;
  border-radius: 10px !important;
  width: 150px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    border: solid 1px ${Blue} !important;
    background: rgb(173, 216, 230) !important;
  }
`;

export const RedButton = styled(Button)`
  color: ${White} !important;
  border: solid 1px ${Red} !important;
  background: ${Red} !important;
  border-radius: 10px !important;
  width: 150px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background: rgb(255, 204, 203) !important;
  }
`;

export const FlexContentContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  width: calc(100vw - 240px);
`;

export const EditImageContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditImageButton = styled(Button)`
  color: ${White} !important;
  border: solid 1px ${Blue} !important;
  background: ${Blue} !important;
  border-radius: 10px !important;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background: rgb(64, 156, 255) !important;
  }
`;

export const NavBar = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropdownContent = styled.p`
  margin-left: 10px;
  color: ${Grey};

  &:hover {
    cursor: pointer;
  }
`;

export const DropdownButton = styled(CaretDownOutlined)`
  color: ${Black};
`;

export const TableLoading = styled(Spin)``;

export const TableLayout = styled(Table)`
  width: calc(100% - 40px);
  margin: 20px;
`;

export const TableContent = styled.div`
  font-size: 16px;
  color: ${Blue};
  margin: 10px 0px;

  &:hover {
    cursor: pointer;
  }
`;

export const TableContentDefault = styled.div`
  font-size: 16px;
  color: ${Black};
  margin: 10px 0px;
`;

export const BreadcrumbLink = styled(Breadcrumb.Item)`
  font-size: 16px;
  font-weight: bold;
  color: ${Blue};

  &:hover {
    cursor: pointer;
  }
`;

export const BreadcrumbActive = styled(Breadcrumb.Item)`
  font-size: 16px;
  font-weight: bold;
  color: ${Black};
`;

export const TopBarBreadcrumb = styled(Breadcrumb)`
  position: absolute;
  left: 60px;
`;

export const FormText = styled.p`
  font-size: 16px;
  color: ${Black};
`;

export const TopBar = styled.div`
  height: 50px;
  width: calc(100vw - 200px);
  border-bottom: solid 0.8px rgb(229, 229, 234);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TopBarSaveButton = styled(Button)`
  color: ${White} !important;
  border: solid 1px ${Blue} !important;
  background: ${Blue} !important;
  border-radius: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  width: 80px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background: rgb(64, 156, 255) !important;
  }
`;

export const TopBarUpdateButton = styled(Button)`
  color: ${Blue} !important;
  border: solid 1px ${Blue} !important;
  background: ${White} !important;
  border-radius: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  height: 40px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    border: solid 1px rgb(64, 156, 255) !important;
  }
`;

export const TopBarDeleteButton = styled(Button)`
  color: ${White} !important;
  border: solid 1px ${Red} !important;
  background: ${Red} !important;
  border-radius: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  width: 80px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background: rgb(255, 105, 97) !important;
  }
`;

export const TopBarIcon = styled(PlusCircleFilled)`
  color: ${Blue};
  position: absolute;
  font-size: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const AddConditionsIcon = styled(PlusCircleFilled)`
  color: ${Blue};
  font-size: 24.8px;

  &:hover {
    cursor: pointer;
  }
`;

export const MinusConditionsIcon = styled(MinusCircleFilled)`
  color: ${Red};
  font-size: 24.8px;

  &:hover {
    cursor: pointer;
  }
`;

export const TopBarSearch = styled(Search)`
  width: 207px;
  height: 40px;
  border-radius: 10px;
`;

export const ContentContainer = styled.div`
  width: calc(100vw - 200px);
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
`;

export const CustomForm = styled(Form)`
  margin: 20px;
`;
