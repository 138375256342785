import React, { Component } from "react";
import { Input, Pagination, DatePicker, Select, Spin } from "antd";
//Redux Firebase
import { connect } from "react-redux";
import moment from "moment";
//Components
import {
  BackButton,
  NavBar,
  TableContentDefault,
  TableLayout,
  TableContent,
  TopBarBreadcrumb,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarSearch,
  TopBarSaveButton,
  TopBar,
  FormText,
  ContentContainer,
  CustomForm,
  FlexContentContainer,
} from "./Layout";
//Actions
import {
  clearUserPageQuery,
  searchUsersByEmail,
  getUsersByPagination,
  updateUsers,
  updateUserAttributes,
} from "../../store/actions/userActions";
import { userQueryLimit } from "../../store/actions/dataActions";
//Utils
import country from "../../utils/countryList";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

const userColumns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Children No.",
    dataIndex: "childNumber",
    key: "childNumber",
  },
  {
    title: "Subscription End Date",
    dataIndex: "subscriptionEnd",
    key: "subscriptionEnd",
  },
];

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

class Users extends Component {
  state = {
    function: "",
    search: "",
    page: 1,
    selectedUser: null,
  };

  componentDidMount() {
    this.props.getUsersByPagination(userQueryLimit, "start");
  }

  componentWillUnmount() {
    this.props.handleLoading(false);
    this.props.clearUserPageQuery();
  }

  handleFunction = (type, data) => {
    if (type === "edit") {
      this.setState({
        function: type,
        selectedUser: data,
      });
    } else
      this.setState({
        function: type,
        selectedUser: null,
      });
  };

  handleSearch = (event) => {
    this.setState(
      {
        search: event.target.value,
      },
      () => {
        clearInterval(this.timer);
        this.timer = setInterval(this.handleTimerSearch, 500);
      }
    );
  };

  handleTimerSearch = () => {
    if (this.state.search === "")
      this.props.getUsersByPagination(userQueryLimit, "start");
    else if (this.state.search.length < 5)
      window.alert(
        "Error : Please input more than 4 characters to begin searching"
      );
    else this.props.searchUsersByEmail(this.state.search);
    clearInterval(this.timer);
  };

  handlePagination = (page) => {
    let pageDifference = page - this.renderCurrentPage();
    if (pageDifference > 0)
      this.props.getUsersByPagination(userQueryLimit, "next");
    else this.props.getUsersByPagination(userQueryLimit, "prev");
  };

  handleDropdownUserAttribute = (id, value) => {
    let tempUser = this.state.selectedUser;
    tempUser[id] = value;
    this.setState({
      selectedUser: tempUser,
    });
  };

  handleChangeUserSubscriptionDate = (date) => {
    let tempUser = this.state.selectedUser;
    tempUser["subscriptionEnd"] = {
      _seconds: date.toDate().getTime() / 1000,
      date: date.toDate(),
    };
    this.setState({
      selectedUser: tempUser,
    });
  };

  handleSubmit = async () => {
    this.props.handleLoading(true);
    if (
      this.state.selectedUser.subscriptionEnd.date ||
      this.state.selectedUser.purchased
    )
      await updateUserAttributes({
        userId: this.state.selectedUser.id,
        date: this.state.selectedUser.subscriptionEnd.date,
        purchased: this.state.selectedUser.purchased,
      });

    setTimeout(() => {
      this.props.handleLoading(false);
      let selectedIndex = -1;
      let usersList = JSON.parse(JSON.stringify(this.props.userStore.users));
      let selectedUser = this.state.selectedUser;
      usersList.map((eachUser, index) => {
        if (eachUser.id === selectedUser.id) selectedIndex = index;
      });
      if (selectedIndex >= 0)
        usersList[selectedIndex] = this.state.selectedUser;

      this.props.updateUsers(usersList);
      this.handleFunction("");
    }, 100);
  };

  renderCountry = () => {
    let countryView = [];
    country.map((eachCountry, index) => {
      countryView.push(
        <TableContent key={index + 1} value={eachCountry.value}>
          {eachCountry.label}
        </TableContent>
      );
    });
    return countryView;
  };

  renderBooks = () => {
    let actionList = [];

    if (this.props.books && this.props.books.length > 0)
      this.props.books.map((eachBook, index) => {
        actionList.push(
          <Option key={index} value={eachBook.id}>
            {eachBook.title}
          </Option>
        );
      });

    return actionList;
  };

  renderPlanType = () => {
    let planOptions = ["Trial", "Monthly", "Half-Year", "Yearly"];
    let planView = [];
    planOptions.map((eachPlan, index) => {
      planView.push(
        <TableContent key={index + 1} value={eachPlan}>
          {eachPlan}
        </TableContent>
      );
    });
    return planView;
  };

  renderUsers = () => {
    let userView = [];

    if (this.props.userStore.users && this.props.userStore.users.length > 0)
      this.props.userStore.users.map((eachUser, index) => {
        userView.push({
          key: index,
          email: (
            <TableContent
              onClick={this.handleFunction.bind(this, "edit", eachUser)}
            >
              {eachUser.email ? eachUser.email : "-"}
            </TableContent>
          ),
          country: (
            <TableContentDefault
              onClick={this.handleFunction.bind(this, "edit", eachUser)}
            >
              {eachUser.country ? eachUser.country : "-"}
            </TableContentDefault>
          ),
          childNumber: (
            <TableContentDefault
              onClick={this.handleFunction.bind(this, "edit", eachUser)}
            >
              {eachUser.children ? eachUser.children.length : 0}
            </TableContentDefault>
          ),
          subscriptionEnd: (
            <TableContentDefault
              onClick={this.handleFunction.bind(this, "edit", eachUser)}
            >
              {new Date(
                eachUser.subscriptionEnd._seconds * 1000
              ).toDateString()}
            </TableContentDefault>
          ),
        });
      });

    return userView;
  };

  // renderChildren = () => {
  //   let childrenView = [];
  //   if (
  //     this.props.children &&
  //     this.state.selectedUser.children &&
  //     this.state.selectedUser.children.length > 0
  //   ) {
  //     this.state.selectedUser.children.map((eachChild, index) => {
  //       this.props.children.map((eachChildren, index) => {
  //         if (eachChild === eachChildren.id)
  //           childrenView.push({
  //             key: index,
  //             name: (
  //               <TableContent
  //                 onClick={this.handleFunction.bind(
  //                   this,
  //                   "children",
  //                   eachChildren
  //                 )}
  //               >
  //                 {eachChildren.name}
  //               </TableContent>
  //             ),
  //             age: (
  //               <TableContentDefault
  //                 onClick={this.handleFunction.bind(
  //                   this,
  //                   "children",
  //                   eachChildren
  //                 )}
  //               >
  //                 {eachChildren.age}
  //               </TableContentDefault>
  //             ),
  //             report: (
  //               <TableContentDefault
  //                 onClick={this.handleFunction.bind(
  //                   this,
  //                   "children",
  //                   eachChildren
  //                 )}
  //               >
  //                 {eachChildren.weeklyReports
  //                   ? eachChildren.weeklyReports.length
  //                   : 0}
  //               </TableContentDefault>
  //             ),
  //           });
  //       });
  //     });
  //   }

  //   return childrenView;
  // };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "")} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "")}>
              Users
            </BreadcrumbLink>
            {this.state.function === "edit" ? (
              <BreadcrumbActive>Editing Users</BreadcrumbActive>
            ) : (
              <>
                <BreadcrumbActive
                  onClick={this.handleFunction.bind(
                    this,
                    "edit",
                    this.state.selectedUser
                  )}
                >
                  Editing Users
                </BreadcrumbActive>
                {this.state.function !== "edit" ? (
                  <BreadcrumbActive>Children</BreadcrumbActive>
                ) : null}
              </>
            )}
          </TopBarBreadcrumb>
          <div style={{ flex: 1 }} />
          <TopBarSaveButton onClick={this.handleSubmit}>Save</TopBarSaveButton>
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Users</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderCurrentPage = () => {
    if (this.props.userStore.previousPage.length === 0) return 1;
    else if (this.props.userStore.currentPageLast === "") return 3;
    else return 2;
  };

  renderContent = () => {
    if (this.state.function === "edit")
      return (
        <>
          <div style={{ width: "100%" }}>
            <CustomForm>
              <FlexContentContainer style={{ marginTop: 20 }}>
                <CustomForm.Item style={{ width: "48%" }}>
                  <FormText>User Id</FormText>
                  <Input
                    id="userId"
                    value={this.state.selectedUser.id}
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                    }}
                    disabled
                  />
                  <FormText>User Email</FormText>
                  <Input
                    id="email"
                    value={this.state.selectedUser.email}
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                    }}
                    disabled
                  />
                  <FormText>Name</FormText>
                  <Input
                    id="name"
                    value={this.state.selectedUser.name}
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                    }}
                    disabled
                  />
                  <FormText>Purchases</FormText>
                  <Select
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={this.handleDropdownUserAttribute.bind(
                      this,
                      "purchased"
                    )}
                    placeholder="Purchases"
                    style={{ width: "100%", marginBottom: 20 }}
                    value={
                      this.state.selectedUser.purchased
                        ? this.state.selectedUser.purchased
                        : undefined
                    }
                  >
                    {this.renderBooks()}
                  </Select>
                </CustomForm.Item>
                <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
                  <FormText>Plan Type</FormText>
                  <Select
                    onChange={this.handleDropdownUserAttribute.bind(
                      this,
                      "planType"
                    )}
                    placeholder="Plan Type"
                    style={{ width: "100%", marginBottom: 20 }}
                    value={
                      this.state.selectedUser.planType &&
                      capitalizeFirstLetter(this.state.selectedUser.planType)
                    }
                    disabled={true}
                  >
                    {this.renderPlanType()}
                  </Select>
                  <FormText>Country</FormText>
                  <Select
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={this.handleDropdownUserAttribute.bind(
                      this,
                      "country"
                    )}
                    placeholder="Country"
                    style={{ marginBottom: 20 }}
                    value={this.state.selectedUser.country}
                    disabled={true}
                  >
                    {this.renderCountry()}
                  </Select>
                  <FormText>Role</FormText>
                  <Input
                    id="role"
                    value={this.state.selectedUser.role}
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                    }}
                    disabled
                  />
                  <FormText>Subscription End Date</FormText>
                  <DatePicker
                    defaultValue={moment(
                      this.state.selectedUser.subscriptionEnd
                        ? moment(
                            this.state.selectedUser.subscriptionEnd._seconds *
                              1000
                          )
                        : null,
                      dateFormat
                    )}
                    onChange={this.handleChangeUserSubscriptionDate}
                    format={dateFormat}
                  />
                </CustomForm.Item>
              </FlexContentContainer>
            </CustomForm>
          </div>

          {/* <TableLayout
            style={{ marginTop: "0px" }}
            columns={childrenColumns}
            dataSource={this.renderChildren()}
            pagination={false}
          /> */}
        </>
      );
    else
      return (
        <>
          <TableLayout
            columns={userColumns}
            dataSource={this.renderUsers()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
            locale={{
              emptyText: this.props.userStore.loading && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                  }}
                >
                  <Spin style={{ marginTop: 20, marginBottom: 5 }} />
                  Loading...
                </div>
              ),
            }}
          />
          {!this.state.search && (
            <NavBar>
              <Pagination
                current={this.renderCurrentPage()}
                total={3}
                pageSize={1}
                onChange={this.handlePagination}
              />
            </NavBar>
          )}
        </>
      );
  };

  render() {
    return (
      <>
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userStore: state.userStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUserPageQuery: () => dispatch(clearUserPageQuery()),
    getUsersByPagination: (limit, type) =>
      dispatch(getUsersByPagination(limit, type)),
    searchUsersByEmail: (emailQuery) =>
      dispatch(searchUsersByEmail(emailQuery)),
    updateUsers: (users) => dispatch(updateUsers(users)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
