import { Input, Pagination, Upload } from "antd";
//Utils
import EventBus from "js-event-bus";
import React, { Component } from "react";
//Redux Firebase
import { connect } from "react-redux";
import styled from "styled-components";
//Actions
import {
  createFaceSticker,
  deleteFaceSticker,
} from "../../store/actions/faceStickerActions";
import { DeleteDialog, SaveDialog } from "../Dialog";
//Components
import {
  BackButton,
  BreadcrumbActive,
  BreadcrumbLink,
  ContentContainer,
  CustomForm,
  EditImageButton,
  FlexContentContainer,
  FormText,
  NavBar,
  TableContent,
  TableLayout,
  TopBar,
  TopBarBreadcrumb,
  TopBarDeleteButton,
  TopBarIcon,
  TopBarSaveButton,
  TopBarSearch,
} from "./Layout.js";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 500,
  },
];

const eventBus = EventBus();

class FaceStickers extends Component {
  state = {
    page: 1,
    function: "",
    search: "",
    selectedFaceSticker: "",
    deepARFile: "",
    deepARFileError: "",
    nameError: "",
    fileError: "",
    soundError: "",
    nameError: "",
    deleteModal: false,
  };

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("sticker-function-success");
    eventBus.detach("sticker-function-error");
  }

  componentDidMount() {
    eventBus.on("sticker-function-success", this.handleClearState);
    eventBus.on("sticker-function-error", this.handleError);
  }

  handleError = (err) => {
    this.setState({ fileError: err }, () => {
      this.props.handleLoading(false);
    });
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedFaceSticker: "",
        fileError: "",
        soundError: "",
        nameError: "",
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleChange = (event) => {
    let tempSticker = this.state.selectedFaceSticker;
    tempSticker[event.target.id] = event.target.value;

    this.setState(
      {
        selectedFaceSticker: tempSticker,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleFunction = (type, stickerData) => {
    let tempSticker = "";
    if (stickerData) tempSticker = JSON.parse(JSON.stringify(stickerData));
    else tempSticker = { image: "", imageName: "" };
    this.handleClearState();
    this.setState(
      {
        function: type,
        selectedFaceSticker: tempSticker,
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handleChangeFaceAR = (event) => {
    let newFile = event.file.originFileObj;
    if (newFile) {
      Object.defineProperty(newFile, "name", {
        writable: true,
        value: "deepAR",
      });

      this.getBase64(newFile, (deepARUrl) =>
        this.setState(
          {
            deepARUrl: deepARUrl,
            deepARFile: newFile,
          },
          () => {
            this.props.handleDataChanged(true);
          }
        )
      );
    }
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleSubmit = (type) => {
    this.props.handleLoading(true);

    if (!this.state.deepARFile && !this.state.selectedFaceSticker.deepARFile)
      this.setState({ fileError: "Please upload a DeepAR file" });
    else this.setState({ fileError: "" });

    if (!this.state.selectedFaceSticker.name)
      this.setState({
        nameError: "Please insert the name",
      });
    else this.setState({ nameError: "" });

    setTimeout(() => {
      if (!this.state.fileError && !this.state.nameError) {
        if (this.state.function === "create") {
          this.props.createFaceSticker({
            deepARFile: this.state.deepARFile,
            selectedSticker: this.state.selectedFaceSticker,
          });
          this.props.handleDataChanged(false);
        }
      } else this.props.handleLoading(false);
    }, 500);
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteFaceSticker({ id: this.state.selectedFaceSticker.id });
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  handleDropdown = (id, value) => {
    let tempSticker = this.state.selectedFaceSticker;
    tempSticker[id] = value;
    this.setState(
      {
        selectedFaceSticker: tempSticker,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  renderFaceStickerFileName = () => {
    let faceStickerFileName = "";
    if (this.state.selectedFaceSticker.effect)
      faceStickerFileName = "DeepAR File";
    else if (this.state.deepARFile)
      faceStickerFileName = this.state.deepARFile.name;
    else faceStickerFileName = "";

    return faceStickerFileName;
  };

  renderStickers = () => {
    let stickerView = [];
    let stickers = [];

    if (this.props.faceStickers && this.props.faceStickers.length > 0) {
      let min = (this.state.page - 1) * 50;
      let max =
        this.state.page * 50 > this.props.faceStickers.length
          ? this.props.faceStickers.length
          : this.state.page * 50;
      if (this.state.search) stickers = this.props.faceStickers;
      else stickers = this.props.faceStickers.slice(min, max);
      stickers.map((eachSticker, index) => {
        if (
          eachSticker &&
          eachSticker.name
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        ) {
          stickerView.push({
            key: index,
            name: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachSticker)}
              >
                {eachSticker.name}
              </TableContent>
            ),
          });
        }
      });
    }

    return stickerView;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "", null)} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "", null)}>
              Face Stickers
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Face Stickers"
                : "Creating Face Stickers"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>

          <div style={{ flex: 1 }} />
          {this.state.function === "edit" ? (
            <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
              Delete
            </TopBarDeleteButton>
          ) : (
            <TopBarSaveButton onClick={this.handleSubmit}>
              Save
            </TopBarSaveButton>
          )}
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Face Stickers</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleFunction.bind(this, "create", null)}
            style={{ left: 135 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <CustomForm>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.fileError ? "error" : "success"}
              help={this.state.fileError ? this.state.fileError : null}
              style={{ width: "48%" }}
            >
              <FormText>DeepAR File</FormText>
              <StickerBackground style={{ flexDirection: "column" }}>
                <FormText style={{ color: "#0A84FF", cursor: "pointer" }}>
                  {this.renderFaceStickerFileName()}
                </FormText>
                {!this.state.selectedFaceSticker.effect && (
                  <Upload
                    onChange={this.handleChangeFaceAR}
                    showUploadList={false}
                  >
                    <EditImageButton>Upload DeepAR File</EditImageButton>
                  </Upload>
                )}
              </StickerBackground>
            </CustomForm.Item>
          </FlexContentContainer>

          <CustomForm.Item
            validateStatus={this.state.nameError ? "error" : "success"}
            help={this.state.nameError ? this.state.nameError : null}
            style={{ width: "48%", marginTop: 10 }}
          >
            <FormText>Name</FormText>
            <Input
              id="name"
              value={
                this.state.selectedFaceSticker.name
                  ? this.state.selectedFaceSticker.name
                  : ""
              }
              onChange={this.handleChange}
              style={{ borderRadius: 10 }}
            />
          </CustomForm.Item>
        </CustomForm>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderStickers()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.faceStickers !== undefined && (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 50 >= this.props.faceStickers.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          )}
        </>
      );
  };

  render() {
    return (
      <>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createFaceSticker: (data) => dispatch(createFaceSticker(data)),
    deleteFaceSticker: (data) => dispatch(deleteFaceSticker(data)),
  };
};

export default connect(null, mapDispatchToProps)(FaceStickers);

const StickerImage = styled.img`
  height: 100px;
  margin: 10px;
`;

const StickerBackground = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 10px;
  background: rgb(251, 251, 255);
  display: flex;
  align-items: center;
  justify-content: center;
`;
