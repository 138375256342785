import firebase from "../../utils/firebase";
import axios from "axios";

export const clearUserPageQuery = (page) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "CLEAR_USER_PAGE_QUERY",
    });
  };
};

export const updateUsers = (users) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "UPDATE_USER",
      payload: {
        users: users,
      },
    });
  };
};

export const updateUserAttributes = async (attribute) => {
  try {
    await axios.post(
      "https://us-central1-mebooks-plus.cloudfunctions.net/updateUserAttribute",
      {
        token: firebase.auth().currentUser.uid,
        userId: attribute.userId,
        date: attribute.date,
        purchased: attribute.purchased,
      }
    );
  } catch (err) {}
};

export const getUsersByPagination = (limit, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "LOAD_USER_DATA",
        payload: {
          loading: true,
        },
      });

      let userRequest;
      let previousPage = JSON.parse(
        JSON.stringify(getState().userStore.previousPage)
      );
      if (type === "prev") {
        previousPage.splice(previousPage.length - 1, 1);
        let lastPage = "";
        if (previousPage.length !== 0)
          lastPage = previousPage[previousPage.length - 1];

        userRequest = await axios.post(
          "https://us-central1-mebooks-plus.cloudfunctions.net/getUsersByPagination",
          {
            token: firebase.auth().currentUser.uid,
            limit: limit,
            startAfter: lastPage,
          }
        );
        dispatch({
          type: "UPDATE_USER_PAGE_QUERY",
          payload: {
            previousPage: previousPage,
            currentPageLast: userRequest.data[limit - 1].email,
            users: userRequest.data,
          },
        });
      } else {
        userRequest = await axios.post(
          "https://us-central1-mebooks-plus.cloudfunctions.net/getUsersByPagination",
          {
            token: firebase.auth().currentUser.uid,
            limit: limit,
            startAfter:
              type === "next" ? getState().userStore.currentPageLast : "",
          }
        );
        if (getState().userStore.currentPageLast)
          previousPage.push(getState().userStore.currentPageLast);

        dispatch({
          type: "UPDATE_USER_PAGE_QUERY",
          payload: {
            previousPage: previousPage,
            currentPageLast:
              userRequest.data.length === limit
                ? userRequest.data[limit - 1].email
                : "",
            users: userRequest.data,
          },
        });
      }

      dispatch({
        type: "LOAD_USER_DATA",
        payload: {
          loading: false,
        },
      });
    } catch (err) {
      dispatch({
        type: "LOAD_USER_DATA",
        payload: {
          loading: false,
        },
      });
    }
  };
};

export const searchUsersByEmail = (emailQuery) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "CLEAR_USER_PAGE_QUERY",
      });
      dispatch({
        type: "LOAD_USER_DATA",
        payload: {
          loading: true,
        },
      });
      let userRequest = await axios.post(
        "https://us-central1-mebooks-plus.cloudfunctions.net/searchUsersByEmail",
        {
          token: firebase.auth().currentUser.uid,
          search: emailQuery,
        }
      );
      dispatch({
        type: "UPDATE_USER_PAGE_QUERY",
        payload: {
          previousPage: [],
          currentPageLast: "",
          users: userRequest.data,
        },
      });
      dispatch({
        type: "LOAD_USER_DATA",
        payload: {
          loading: false,
        },
      });
    } catch (err) {
      dispatch({
        type: "LOAD_USER_DATA",
        payload: {
          loading: false,
        },
      });
    }
  };
};
