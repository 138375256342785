import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
const eventBus = EventBus();
const uuid = require("uuid/v4");

export const createSticker = (data) => {
  return async (dispatch, getState) => {
    try {
      let uniqueId = `stickers_${uuid()}`;
      const firestore = firebase.firestore();
      let verifySticker = firestore.collection("stickers").doc(uniqueId);
      let verifyStickerDoc = await verifySticker.get();

      if (!verifyStickerDoc.exists) {
        await firebase
          .storage()
          .ref(`assets_stickers/${uniqueId}/${data.stickerFile.name}`)
          .put(data.stickerFile);
        let url = await firebase
          .storage()
          .ref(`assets_stickers/${uniqueId}`)
          .child(data.stickerFile.name)
          .getDownloadURL();
        await firebase
          .storage()
          .ref(`assets_stickers/${uniqueId}/${data.soundFile.name}`)
          .put(data.soundFile);
        let soundUrl = await firebase
          .storage()
          .ref(`assets_stickers/${uniqueId}`)
          .child(data.soundFile.name)
          .getDownloadURL();
        await firestore
          .collection("stickers")
          .doc(uniqueId)
          .set({
            id: uniqueId,
            image: url.split("token=")[1],
            sound: soundUrl.split("token=")[1],
            name: data.selectedSticker.name.trim(),
            points: data.selectedSticker.points.trim(),
            type: data.selectedSticker.type,
          });
        eventBus.emit("sticker-function-success");
      } else
        eventBus.emit(
          "sticker-function-error",
          null,
          "The sticker has been created"
        );
    } catch (err) {
      eventBus.emit("sticker-function-error", null, err);
    }
  };
};

export const deleteSticker = (data) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifySticker = firestore.collection("stickers").doc(data.id);
      await verifySticker.delete();
      await firebase
        .storage()
        .ref(`assets_stickers/${data.id}`)
        .child("sticker.png")
        .delete();
      await firebase
        .storage()
        .ref(`assets_stickers/${data.id}`)
        .child("sound.mp3")
        .delete();
      eventBus.emit("sticker-function-success");
    } catch (err) {
      eventBus.emit("sticker-function-error", null, err);
    }
  };
};
