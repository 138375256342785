import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
const eventBus = EventBus();
const uuid = require("uuid/v4");

//INFO : Action to create a book tag
export const createTag = (tagTitle) => {
  return async (dispatch, getState) => {
    try {
      let uniqueId = uuid();
      const firestore = firebase.firestore();
      let verifyBookTag = firestore.collection("tags").doc(uniqueId);

      let verifyBookDoc = await verifyBookTag.get();

      if (!verifyBookDoc.exists) {
        await firestore.collection("tags").doc(uniqueId).set({
          id: uniqueId,
          title: tagTitle.trim(),
        });
        eventBus.emit("tag-function-success");
      } else
        eventBus.emit("tag-function-error", null, "The tag has been created");
    } catch (err) {
      eventBus.emit("tag-function-error", null, err);
    }
  };
};

//INFO : Action to delete a tag
export const deleteTag = (id) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyBookTag = firestore.collection("tags").doc(id);
      await verifyBookTag.delete();
      eventBus.emit("tag-function-success");
    } catch (err) {
      eventBus.emit("tag-function-error", null, err);
    }
  };
};

//INFO : Action to edit a tag
export const editTag = (id, tagTitle) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyBookTag = firestore.collection("tags").doc(id);

      await verifyBookTag.update({
        title: tagTitle.trim(),
      });

      eventBus.emit("tag-function-success");
    } catch (err) {
      eventBus.emit("tag-function-error", null, err);
    }
  };
};
