import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
//Components
import TopBar from "../components/TopBar";
import LeftBar from "../components/LeftBar";
import Content from "../components/Content";
//Assets
import MeBooksLogo from "../assets/icon.png";

const antIcon = <LoadingOutlined style={{ fontSize: "40px" }} spin />;

class Dashboard extends Component {
  state = {
    loading: false,
    laptop: false,
    dataChanged: false,
    switchPage: "",
  };

  componentDidMount() {
    this.handleCheckWindowSize();
    window.addEventListener("resize", this.handleCheckWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCheckWindowSize);
  }

  handleCheckWindowSize = () => {
    if (window.innerWidth >= 1200 && !this.state.laptop)
      this.setState({
        laptop: true,
      });
    else if (window.innerWidth < 1200 && this.state.laptop)
      this.setState({
        laptop: false,
      });
  };

  handleLoading = (bool) => {
    this.setState({
      loading: bool,
    });
  };

  handleDataChanged = (bool) => {
    this.setState({
      dataChanged: bool,
      switchPage: "",
    });
  };

  handleSwitchPage = (data) => {
    if (data === "stay") this.setState({ switchPage: "", dataChanged: true });
    else this.setState({ dataChanged: false, switchPage: data });
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;
    if (!this.state.laptop)
      return (
        <ErrorContainer>
          <Logo src={MeBooksLogo} />
          <h4>Only supported in laptop</h4>
        </ErrorContainer>
      );

    return (
      <Container>
        {this.state.loading && (
          <LoadingContainer>
            <Loading>
              <Spin style={{ color: "#333" }} indicator={antIcon} />
              <p style={{ marginTop: 10 }}>Loading ...</p>
            </Loading>
          </LoadingContainer>
        )}

        <TopBar />
        <LeftBar
          location={this.props.location}
          history={this.props.history}
          dataChanged={this.state.dataChanged}
          handleSwitchPage={this.handleSwitchPage}
        />
        <Content
          style={this.state.loading ? { visibility: "hidden" } : {}}
          location={this.props.location}
          handleLoading={this.handleLoading}
          history={this.props.history}
          handleDataChanged={this.handleDataChanged}
          switchPage={this.state.switchPage}
          handleSwitchPage={this.handleSwitchPage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(Dashboard);

const ErrorContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Logo = styled.img`
  width: 90px;
  height: 90px;
`;

const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
`;

const LoadingContainer = styled.div`
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #fff;
`;
