export default [
  {
    id: 1,
    region: "Africa, Middle East, and India",
    value: "AF",
    label: "Afghanistan",
  },
  {
    id: 2,
    region: "Others",
    value: "AX",
    label: "Aland Islands",
  },
  {
    id: 3,
    region: "Europe",
    value: "AL",
    label: "Albania",
  },
  {
    id: 4,
    region: "Africa, Middle East, and India",
    value: "DZ",
    label: "Algeria",
  },
  {
    id: 5,
    region: "Others",
    value: "AS",
    label: "American Samoa",
  },
  {
    id: 6,
    region: "Others",
    value: "AD",
    label: "Andorra",
  },
  {
    id: 7,
    region: "Africa, Middle East, and India",
    value: "AO",
    label: "Angola",
  },
  {
    id: 8,
    region: "Latin America and the Caribbean",
    value: "AI",
    label: "Anguilla",
  },
  {
    id: 9,
    region: "Others",
    value: "AQ",
    label: "Antarctica",
  },
  {
    id: 10,
    region: "Latin America and the Caribbean",
    value: "AG",
    label: "Antigua And Barbuda",
  },
  {
    id: 11,
    region: "Latin America and the Caribbean",
    value: "AR",
    label: "Argentina",
  },
  {
    id: 12,
    region: "Africa, Middle East, and India",
    value: "AM",
    label: "Armenia",
  },
  {
    id: 13,
    region: "Others",
    value: "AW",
    label: "Aruba",
  },
  {
    id: 14,
    region: "Asia Pacific",
    value: "AU",
    label: "Australia",
  },
  {
    id: 15,
    region: "Europe",
    value: "AT",
    label: "Austria",
  },
  {
    id: 16,
    region: "Africa, Middle East, and India",
    value: "AZ",
    label: "Azerbaijan",
  },
  {
    id: 17,
    region: "Latin America and the Caribbean",
    value: "BS",
    label: "Bahamas",
  },
  {
    id: 18,
    region: "Africa, Middle East, and India",
    value: "BH",
    label: "Bahrain",
  },
  {
    id: 19,
    region: "Others",
    value: "BD",
    label: "Bangladesh",
  },
  {
    id: 20,
    region: "Latin America and the Caribbean",
    value: "BB",
    label: "Barbados",
  },
  {
    id: 21,
    region: "Europe",
    value: "BY",
    label: "Belarus",
  },
  {
    id: 22,
    region: "Europe",
    value: "BE",
    label: "Belgium",
  },
  {
    id: 23,
    region: "Latin America and the Caribbean",
    value: "BZ",
    label: "Belize",
  },
  {
    id: 24,
    region: "Africa, Middle East, and India",
    value: "BJ",
    label: "Benin",
  },
  {
    id: 25,
    region: "Latin America and the Caribbean",
    value: "BM",
    label: "Bermuda",
  },
  {
    id: 26,
    region: "Asia Pacific",
    value: "BT",
    label: "Bhutan",
  },
  {
    id: 27,
    region: "Latin America and the Caribbean",
    value: "BO",
    label: "Bolivia",
  },
  {
    id: 28,
    region: "Europe",
    value: "BA",
    label: "Bosnia And Herzegovina",
  },
  {
    id: 29,
    region: "Africa, Middle East, and India",
    value: "BW",
    label: "Botswana",
  },
  {
    id: 30,
    region: "Others",
    value: "BV",
    label: "Bouvet Island",
  },
  {
    id: 31,
    region: "Latin America and the Caribbean",
    value: "BR",
    label: "Brazil",
  },
  {
    id: 32,
    region: "Others",
    value: "IO",
    label: "British Indian Ocean Territory",
  },
  {
    id: 33,
    region: "Asia Pacific",
    value: "BN",
    label: "Brunei Darussalam",
  },
  {
    id: 34,
    region: "Europe",
    value: "BG",
    label: "Bulgaria",
  },
  {
    id: 35,
    region: "Africa, Middle East, and India",
    value: "BF",
    label: "Burkina Faso",
  },
  {
    id: 36,
    region: "Others",
    value: "BI",
    label: "Burundi",
  },
  {
    id: 37,
    region: "Asia Pacific",
    value: "KH",
    label: "Cambodia",
  },
  {
    id: 38,
    region: "Africa, Middle East, and India",
    value: "CM",
    label: "Cameroon",
  },
  {
    id: 39,
    region: "The United States and Canada",
    value: "CA",
    label: "Canada",
  },
  {
    id: 40,
    region: "Africa, Middle East, and India",
    value: "CV",
    label: "Cape Verde",
  },
  {
    id: 41,
    region: "Latin America and the Caribbean",
    value: "KY",
    label: "Cayman Islands",
  },
  {
    id: 42,
    region: "Others",
    value: "CF",
    label: "Central African Republic",
  },
  {
    id: 43,
    region: "Africa, Middle East, and India",
    value: "TD",
    label: "Chad",
  },
  {
    id: 44,
    region: "Latin America and the Caribbean",
    value: "CL",
    label: "Chile",
  },
  {
    id: 45,
    region: "Asia Pacific",
    value: "CN",
    label: "China",
  },
  {
    id: 46,
    region: "Others",
    value: "CX",
    label: "Christmas Island",
  },
  {
    id: 47,
    region: "Others",
    value: "CC",
    label: "Cocos (Keeling) Islands",
  },
  {
    id: 48,

    region: "Latin America and the Caribbean",
    value: "CO",
    label: "Colombia",
  },
  {
    id: 49,
    region: "Others",
    value: "KM",
    label: "Comoros",
  },
  {
    id: 50,
    region: "Africa, Middle East, and India",
    value: "CG",
    label: "Congo",
  },
  {
    id: 51,
    region: "Africa, Middle East, and India",
    value: "CD",
    label: "Congo, Democratic Republic",
  },
  {
    id: 52,
    region: "Others",
    value: "CK",
    label: "Cook Islands",
  },
  {
    id: 53,
    region: "Latin America and the Caribbean",
    value: "CR",
    label: "Costa Rica",
  },
  {
    id: 54,
    region: "Africa, Middle East, and India",
    value: "CI",
    label: "Cote D'Ivoire",
  },
  {
    id: 55,
    region: "Europe",
    value: "HR",
    label: "Croatia",
  },
  {
    id: 56,
    region: "Others",
    value: "CU",
    label: "Cuba",
  },
  {
    id: 57,
    region: "Europe",
    value: "CY",
    label: "Cyprus",
  },
  {
    id: 58,
    region: "Europe",
    value: "CZ",
    label: "Czech Republic",
  },
  {
    id: 59,
    region: "Europe",
    value: "DK",
    label: "Denmark",
  },
  {
    id: 60,
    region: "Others",
    value: "DJ",
    label: "Djibouti",
  },
  {
    id: 61,
    region: "Latin America and the Caribbean",
    value: "DM",
    label: "Dominica",
  },
  {
    id: 62,
    region: "Latin America and the Caribbean",
    value: "DO",
    label: "Dominican Republic",
  },
  {
    id: 63,
    region: "Latin America and the Caribbean",
    value: "EC",
    label: "Ecuador",
  },
  {
    id: 64,
    region: "Africa, Middle East, and India",
    value: "EG",
    label: "Egypt",
  },
  {
    id: 65,
    region: "Latin America and the Caribbean",
    value: "SV",
    label: "El Salvador",
  },
  {
    id: 66,
    region: "Others",
    value: "GQ",
    label: "Equatorial Guinea",
  },
  {
    id: 67,
    region: "Others",
    value: "ER",
    label: "Eritrea",
  },
  {
    id: 68,
    region: "Europe",
    value: "EE",
    label: "Estonia",
  },
  {
    id: 69,
    region: "Others",
    value: "ET",
    label: "Ethiopia",
  },
  {
    id: 70,
    region: "Others",
    value: "FK",
    label: "Falkland Islands (Malvinas)",
  },
  {
    id: 71,
    region: "Others",
    value: "FO",
    label: "Faroe Islands",
  },
  {
    id: 72,
    region: "Asia Pacific",
    value: "FJ",
    label: "Fiji",
  },
  {
    id: 73,
    region: "Europe",
    value: "FI",
    label: "Finland",
  },
  {
    id: 74,
    region: "Europe",
    value: "FR",
    label: "France",
  },
  {
    id: 75,
    region: "Others",
    value: "GF",
    label: "French Guiana",
  },
  {
    id: 76,
    region: "Others",
    value: "PF",
    label: "French Polynesia",
  },
  {
    id: 77,
    region: "Others",
    value: "TF",
    label: "French Southern Territories",
  },
  {
    id: 78,
    region: "Africa, Middle East, and India",
    value: "GA",
    label: "Gabon",
  },
  {
    id: 79,
    region: "Africa, Middle East, and India",
    value: "GM",
    label: "Gambia",
  },
  {
    id: 80,
    region: "Africa, Middle East, and India",
    value: "GE",
    label: "Georgia",
  },
  {
    id: 81,
    region: "Europe",
    value: "DE",
    label: "Germany",
  },
  {
    id: 82,
    region: "Africa, Middle East, and India",
    value: "GH",
    label: "Ghana",
  },
  {
    id: 83,
    region: "Others",
    value: "GI",
    label: "Gibraltar",
  },
  {
    id: 84,
    region: "Europe",
    value: "GR",
    label: "Greece",
  },
  {
    id: 85,
    region: "Others",
    value: "GL",
    label: "Greenland",
  },
  {
    id: 86,
    region: "Latin America and the Caribbean",
    value: "GD",
    label: "Grenada",
  },
  {
    id: 87,
    region: "Others",
    value: "GP",
    label: "Guadeloupe",
  },
  {
    id: 88,
    region: "Others",
    value: "GU",
    label: "Guam",
  },
  {
    id: 89,
    region: "Latin America and the Caribbean",
    value: "GT",
    label: "Guatemala",
  },
  {
    id: 90,
    region: "Others",
    value: "GG",
    label: "Guernsey",
  },
  {
    id: 91,
    region: "Africa, Middle East, and India",
    value: "GN",
    label: "Guinea",
  },
  {
    id: 92,
    region: "Africa, Middle East, and India",
    value: "GW",
    label: "Guinea-Bissau",
  },
  {
    id: 93,
    region: "Latin America and the Caribbean",
    value: "GY",
    label: "Guyana",
  },
  {
    id: 94,
    region: "Others",
    value: "HT",
    label: "Haiti",
  },
  {
    id: 95,
    region: "Others",
    value: "HM",
    label: "Heard Island & Mcdonald Islands",
  },
  {
    id: 96,
    region: "Others",
    value: "VA",
    label: "Holy See (Vatican City State)",
  },
  {
    id: 97,
    region: "Latin America and the Caribbean",
    value: "HN",
    label: "Honduras",
  },
  {
    id: 98,
    region: "Asia Pacific",
    value: "HK",
    label: "Hong Kong",
  },
  {
    id: 99,
    region: "Europe",
    value: "HU",
    label: "Hungary",
  },
  {
    id: 100,
    region: "Europe",
    value: "IS",
    label: "Iceland",
  },
  {
    id: 101,
    region: "Africa, Middle East, and India",
    value: "IN",
    label: "India",
  },
  {
    id: 102,
    region: "Asia Pacific",
    value: "ID",
    label: "Indonesia",
  },
  {
    id: 103,
    region: "Others",
    value: "IR",
    label: "Iran, Islamic Republic Of",
  },
  {
    id: 104,
    region: "Africa, Middle East, and India",
    value: "IQ",
    label: "Iraq",
  },
  {
    id: 105,
    region: "Europe",
    value: "IE",
    label: "Ireland",
  },
  {
    id: 106,
    region: "Others",
    value: "IM",
    label: "Isle Of Man",
  },
  {
    id: 107,
    region: "Africa, Middle East, and India",
    value: "IL",
    label: "Israel",
  },
  {
    id: 108,
    region: "Europe",
    value: "IT",
    label: "Italy",
  },
  {
    id: 109,
    region: "Latin America and the Caribbean",
    value: "JM",
    label: "Jamaica",
  },
  {
    id: 110,
    region: "Asia Pacific",
    value: "JP",
    label: "Japan",
  },
  {
    id: 111,
    region: "Others",
    value: "JE",
    label: "Jersey",
  },
  {
    id: 112,
    region: "Africa, Middle East, and India",
    value: "JO",
    label: "Jordan",
  },
  {
    id: 113,
    region: "Asia Pacific",
    value: "KZ",
    label: "Kazakhstan",
  },
  {
    id: 114,
    region: "Africa, Middle East, and India",
    value: "KE",
    label: "Kenya",
  },
  {
    id: 115,
    region: "Others",
    value: "KI",
    label: "Kiribati",
  },
  {
    id: 116,
    region: "Asia Pacific",
    value: "KR",
    label: "Korea",
  },
  {
    id: 117,
    region: "Africa, Middle East, and India",
    value: "KW",
    label: "Kuwait",
  },
  {
    id: 118,
    region: "Asia Pacific",
    value: "KG",
    label: "Kyrgyzstan",
  },
  {
    id: 119,
    region: "Asia Pacific",
    value: "LA",
    label: "Lao People's Democratic Republic",
  },
  {
    id: 120,
    region: "Europe",
    value: "LV",
    label: "Latvia",
  },
  {
    id: 121,
    region: "Africa, Middle East, and India",
    value: "LB",
    label: "Lebanon",
  },
  {
    id: 122,
    region: "Others",
    value: "LS",
    label: "Lesotho",
  },
  {
    id: 123,
    region: "Africa, Middle East, and India",
    value: "LR",
    label: "Liberia",
  },
  {
    id: 124,
    region: "Africa, Middle East, and India",
    value: "LY",
    label: "Libyan Arab Jamahiriya",
  },
  {
    id: 125,
    region: "Others",
    value: "LI",
    label: "Liechtenstein",
  },
  {
    id: 126,
    region: "Europe",
    value: "LT",
    label: "Lithuania",
  },
  {
    id: 127,
    region: "Others",
    value: "LU",
    label: "Luxembourg",
  },
  {
    id: 128,
    region: "Asia Pacific",
    value: "MO",
    label: "Macau",
  },
  {
    id: 129,
    region: "Others",
    value: "MK",
    label: "Macedonia",
  },
  {
    id: 130,
    region: "Africa, Middle East, and India",
    value: "MG",
    label: "Madagascar",
  },
  {
    id: 131,
    region: "Africa, Middle East, and India",
    value: "MW",
    label: "Malawi",
  },
  {
    id: 132,
    region: "Asia Pacific",
    value: "MY",
    label: "Malaysia",
  },
  {
    id: 133,
    region: "Asia Pacific",
    value: "MV",
    label: "Maldives",
  },
  {
    id: 134,
    region: "Africa, Middle East, and India",
    value: "ML",
    label: "Mali",
  },
  {
    id: 135,
    region: "Europe",
    value: "MT",
    label: "Malta",
  },
  {
    id: 136,
    region: "Others",
    value: "MH",
    label: "Marshall Islands",
  },
  {
    id: 137,
    region: "Others",
    value: "MQ",
    label: "Martinique",
  },
  {
    id: 138,
    region: "Africa, Middle East, and India",
    value: "MR",
    label: "Mauritania",
  },
  {
    id: 139,
    region: "Africa, Middle East, and India",
    value: "MU",
    label: "Mauritius",
  },
  {
    id: 140,
    region: "Others",
    value: "YT",
    label: "Mayotte",
  },
  {
    id: 141,
    region: "Latin America and the Caribbean",
    value: "MX",
    label: "Mexico",
  },
  {
    id: 142,
    region: "Asia Pacific",
    value: "FM",
    label: "Micronesia, Federated States Of",
  },
  {
    id: 143,
    region: "Europe",
    value: "MD",
    label: "Moldova",
  },
  {
    id: 144,
    region: "Others",
    value: "MC",
    label: "Monaco",
  },
  {
    id: 145,
    region: "Asia Pacific",
    value: "MN",
    label: "Mongolia",
  },
  {
    id: 146,
    region: "Europe",
    value: "ME",
    label: "Montenegro",
  },
  {
    id: 147,
    region: "Latin America and the Caribbean",
    value: "MS",
    label: "Montserrat",
  },
  {
    id: 148,
    region: "Africa, Middle East, and India",
    value: "MA",
    label: "Morocco",
  },
  {
    id: 149,
    region: "Africa, Middle East, and India",
    value: "MZ",
    label: "Mozambique",
  },
  {
    id: 150,
    region: "Asia Pacific",
    value: "MM",
    label: "Myanmar",
  },
  {
    id: 151,
    region: "Africa, Middle East, and India",
    value: "NA",
    label: "Namibia",
  },
  {
    id: 152,
    region: "Asia Pacific",
    value: "NR",
    label: "Nauru",
  },
  {
    id: 153,
    region: "Asia Pacific",
    value: "NP",
    label: "Nepal",
  },
  {
    id: 154,
    region: "Europe",
    value: "NL",
    label: "Netherlands",
  },
  {
    id: 155,
    region: "Others",
    value: "AN",
    label: "Netherlands Antilles",
  },
  {
    id: 156,
    region: "Others",
    value: "NC",
    label: "New Caledonia",
  },
  {
    id: 157,
    region: "Asia Pacific",
    value: "NZ",
    label: "New Zealand",
  },
  {
    id: 158,
    region: "Latin America and the Caribbean",
    value: "NI",
    label: "Nicaragua",
  },
  {
    id: 159,
    region: "Africa, Middle East, and India",
    value: "NE",
    label: "Niger",
  },
  {
    id: 160,
    region: "Africa, Middle East, and India",
    value: "NG",
    label: "Nigeria",
  },
  {
    id: 161,
    region: "Others",
    value: "NU",
    label: "Niue",
  },
  {
    id: 162,
    region: "Others",
    value: "NF",
    label: "Norfolk Island",
  },
  {
    id: 163,
    region: "Others",
    value: "MP",
    label: "Northern Mariana Islands",
  },
  {
    id: 164,
    region: "Europe",
    value: "NO",
    label: "Norway",
  },
  {
    id: 165,
    region: "Africa, Middle East, and India",
    value: "OM",
    label: "Oman",
  },
  {
    id: 166,
    region: "Asia Pacific",
    value: "PK",
    label: "Pakistan",
  },
  {
    id: 167,
    region: "Asia Pacific",
    value: "PW",
    label: "Palau",
  },
  {
    id: 168,
    region: "Others",
    value: "PS",
    label: "Palestinian Territory, Occupied",
  },
  {
    id: 169,
    region: "Latin America and the Caribbean",
    value: "PA",
    label: "Panama",
  },
  {
    id: 170,
    region: "Asia Pacific",
    value: "PG",
    label: "Papua New Guinea",
  },
  {
    id: 171,
    region: "Latin America and the Caribbean",
    value: "PY",
    label: "Paraguay",
  },
  {
    id: 172,
    region: "Latin America and the Caribbean",
    value: "PE",
    label: "Peru",
  },
  {
    id: 173,
    region: "Asia Pacific",
    value: "PH",
    label: "Philippines",
  },
  {
    id: 174,
    region: "Others",
    value: "PN",
    label: "Pitcairn",
  },
  {
    id: 175,
    region: "Europe",
    value: "PL",
    label: "Poland",
  },
  {
    id: 176,
    region: "Europe",
    value: "PT",
    label: "Portugal",
  },
  {
    id: 177,
    region: "Others",
    value: "PR",
    label: "Puerto Rico",
  },
  {
    id: 178,
    region: "Africa, Middle East, and India",
    value: "QA",
    label: "Qatar",
  },
  {
    id: 179,
    region: "Others",
    value: "RE",
    label: "Reunion",
  },
  {
    id: 180,
    region: "Europe",
    value: "RO",
    label: "Romania",
  },
  {
    id: 181,
    region: "Europe",
    value: "RU",
    label: "Russian Federation",
  },
  {
    id: 182,
    region: "Africa, Middle East, and India",
    value: "RW",
    label: "Rwanda",
  },
  {
    id: 183,
    region: "Others",
    value: "BL",
    label: "Saint Barthelemy",
  },
  {
    id: 184,
    region: "Others",
    value: "SH",
    label: "Saint Helena",
  },
  {
    id: 185,
    region: "Latin America and the Caribbean",
    value: "KN",
    label: "Saint Kitts And Nevis",
  },
  {
    id: 186,
    region: "Latin America and the Caribbean",
    value: "LC",
    label: "Saint Lucia",
  },
  {
    id: 187,
    region: "Others",
    value: "MF",
    label: "Saint Martin",
  },
  {
    id: 188,
    region: "Others",
    value: "PM",
    label: "Saint Pierre And Miquelon",
  },
  {
    id: 189,
    region: "Latin America and the Caribbean",
    value: "VC",
    label: "Saint Vincent And Grenadines",
  },
  {
    id: 190,
    region: "Others",
    value: "WS",
    label: "Samoa",
  },
  {
    id: 191,
    region: "Others",
    value: "SM",
    label: "San Marino",
  },
  {
    id: 192,
    region: "Africa, Middle East, and India",
    value: "ST",
    label: "Sao Tome And Principe",
  },
  {
    id: 193,
    region: "Africa, Middle East, and India",
    value: "SA",
    label: "Saudi Arabia",
  },
  {
    id: 194,
    region: "Africa, Middle East, and India",
    value: "SN",
    label: "Senegal",
  },
  {
    id: 195,
    region: "Europe",
    value: "RS",
    label: "Serbia",
  },
  {
    id: 196,
    region: "Africa, Middle East, and India",
    value: "SC",
    label: "Seychelles",
  },
  {
    id: 197,
    region: "Africa, Middle East, and India",
    value: "SL",
    label: "Sierra Leone",
  },
  {
    id: 198,
    region: "Asia Pacific",
    value: "SG",
    label: "Singapore",
  },
  {
    id: 199,
    region: "Others",
    value: "SK",
    label: "Slovakia",
  },
  {
    id: 200,
    region: "Others",
    value: "SI",
    label: "Slovenia",
  },
  {
    id: 201,
    region: "Asia Pacific",
    value: "SB",
    label: "Solomon Islands",
  },
  {
    id: 202,
    region: "Others",
    value: "SO",
    label: "Somalia",
  },
  {
    id: 203,
    region: "Africa, Middle East, and India",
    value: "ZA",
    label: "South Africa",
  },
  {
    id: 204,
    region: "Others",
    value: "GS",
    label: "South Georgia And Sandwich Isl.",
  },
  {
    id: 205,
    region: "Europe",
    value: "ES",
    label: "Spain",
  },
  {
    id: 206,
    region: "Asia Pacific",
    value: "LK",
    label: "Sri Lanka",
  },
  {
    id: 207,
    region: "Others",
    value: "SD",
    label: "Sudan",
  },
  {
    id: 208,
    region: "Others",
    value: "SR",
    label: "Surilabel",
  },
  {
    id: 209,
    region: "Others",
    value: "SJ",
    label: "Svalbard And Jan Mayen",
  },
  {
    id: 210,
    region: "Africa, Middle East, and India",
    value: "SZ",
    label: "Swaziland",
  },
  {
    id: 211,
    region: "Europe",
    value: "SE",
    label: "Sweden",
  },
  {
    id: 212,
    region: "Europe",
    value: "CH",
    label: "Switzerland",
  },
  {
    id: 213,
    region: "Others",
    value: "SY",
    label: "Syrian Arab Republic",
  },
  {
    id: 214,
    region: "Asia Pacific",
    value: "TW",
    label: "Taiwan",
  },
  {
    id: 215,
    region: "Asia Pacific",
    value: "TJ",
    label: "Tajikistan",
  },
  {
    id: 216,
    region: "Africa, Middle East, and India",
    value: "TZ",
    label: "Tanzania",
  },
  {
    id: 217,
    region: "Asia Pacific",
    value: "TH",
    label: "Thailand",
  },
  {
    id: 218,
    region: "Others",
    value: "TL",
    label: "Timor-Leste",
  },
  {
    id: 219,
    region: "Others",
    value: "TG",
    label: "Togo",
  },
  {
    id: 220,
    region: "Others",
    value: "TK",
    label: "Tokelau",
  },
  {
    id: 221,
    region: "Asia Pacific",
    value: "TO",
    label: "Tonga",
  },
  {
    id: 222,
    region: "Latin America and the Caribbean",
    value: "TT",
    label: "Trinidad And Tobago",
  },
  {
    id: 223,
    region: "Africa, Middle East, and India",
    value: "TN",
    label: "Tunisia",
  },
  {
    id: 224,
    region: "Europe",
    value: "TR",
    label: "Turkey",
  },
  {
    id: 225,
    region: "Asia Pacific",
    value: "TM",
    label: "Turkmenistan",
  },
  {
    id: 226,
    region: "Latin America and the Caribbean",
    value: "TC",
    label: "Turks And Caicos Islands",
  },
  {
    id: 227,
    region: "Others",
    value: "TV",
    label: "Tuvalu",
  },
  {
    id: 228,
    region: "Africa, Middle East, and India",
    value: "UG",
    label: "Uganda",
  },
  {
    id: 229,
    region: "Europe",
    value: "UA",
    label: "Ukraine",
  },
  {
    id: 230,
    region: "Africa, Middle East, and India",
    value: "AE",
    label: "United Arab Emirates",
  },
  {
    id: 231,
    region: "Europe",
    value: "GB",
    label: "United Kingdom",
  },
  {
    id: 232,
    region: "The United States and Canada",
    value: "US",
    label: "United States",
  },
  {
    id: 233,
    region: "The United States and Canada",
    value: "UM",
    label: "United States Outlying Islands",
  },
  {
    id: 234,
    region: "Latin America and the Caribbean",
    value: "UY",
    label: "Uruguay",
  },
  {
    id: 235,
    region: "Asia Pacific",
    value: "UZ",
    label: "Uzbekistan",
  },
  {
    id: 236,
    region: "Asia Pacific",
    value: "VU",
    label: "Vanuatu",
  },
  {
    id: 237,
    region: "Latin America and the Caribbean",
    value: "VE",
    label: "Venezuela",
  },
  {
    id: 238,
    region: "Asia Pacific",
    value: "VN",
    label: "Viet Nam",
  },
  {
    id: 239,
    region: "Latin America and the Caribbean",
    value: "VG",
    label: "Virgin Islands, British",
  },
  {
    id: 240,
    region: "Others",
    value: "VI",
    label: "Virgin Islands, U.S.",
  },
  {
    id: 241,
    region: "Others",
    value: "WF",
    label: "Wallis And Futuna",
  },
  {
    id: 242,
    region: "Others",
    value: "EH",
    label: "Western Sahara",
  },
  {
    id: 243,
    region: "Africa, Middle East, and India",
    value: "YE",
    label: "Yemen",
  },
  {
    id: 244,
    region: "Africa, Middle East, and India",
    value: "ZM",
    label: "Zambia",
  },
  {
    id: 245,
    region: "Africa, Middle East, and India",
    value: "ZW",
    label: "Zimbabwe",
  },
];
