import React, { Component } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { Button, Select, Empty, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { White, Blue } from "../../assets/styles/Colors";
import { TopBar, ContentContainer } from "./Layout";
import countryList from "../../utils/countryList";
import axios from "axios";

const { Option } = Select;

const antIcon = <LoadingOutlined style={{ fontSize: "40px" }} spin />;

class Dashboard extends Component {
  state = {
    windowWidth: 0,
    windowHeight: 0,
    function: "Pages",
    codeType: "",
    value: "All",
    genericCodes: [],
    bar: {
      options: {
        colors: ["rgb(255,255,255)"],
        labels: [""],
        legend: { show: false },
        xaxis: {
          categories: [""],
        },
      },
      series: [0, 0, 0, 0],
    },
    emptyCode: [],
  };

  componentDidMount() {
    this.handleGetGenericCode();
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.books &&
      prevProps !== this.props.books &&
      this.props.books.length > 0 &&
      this.state.books !== this.props.books
    ) {
      this.setState({
        books: this.props.books,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
      this.handleRenderAnalytics();
    }
  };

  handleFunction = (type) => {
    if (type === "Codes")
      this.setState({ function: type }, () => {
        this.handleRenderAnalytics();
      });
    else
      this.setState({ function: type, value: "All" }, () => {
        this.handleRenderAnalytics();
      });
  };

  handleGetGenericCode = async () => {
    let codeRes = await axios.post(
      "https://us-central1-mebooks-plus.cloudfunctions.net/getGenericCodesDetails"
    );
    if (codeRes.data)
      this.setState(
        {
          genericCodes: codeRes.data,
        },
        () => {
          this.handleVerifyCode();
        }
      );
  };

  handleCountryCount = () => {
    let country = {};

    countryList.map((eachCountry) => {
      country[eachCountry.value] = 0;
    });
    return country;
  };

  handleNewCountryCount = (countryCount) => {
    let series = [];
    let labels = [];
    let categories = [];
    let j = 0;

    for (let i = 0; i < countryList.length; i++)
      if (countryCount[countryList[i].value] !== 0) {
        series[j] = countryCount[countryList[i].value];
        labels[j] = countryList[i].label;
        categories[j] = countryList[i].label;
        j++;
      }

    let data = { series, labels, categories };
    return data;
  };

  handleColorRandomizer = (count) => {
    let color = [];
    for (let i = 0; i < count; i++)
      color[i] = "#" + Math.random().toString(16).substr(-6);
    return color;
  };

  handleRenderAnalytics = () => {
    let bar = this.state.bar;

    if (this.state.function === "Roles") {
      let roleCount = {
        author: 0,
        narrator: 0,
        publisher: 0,
      };

      if (this.props.roles && this.props.roles.length > 0) {
        this.props.roles.map((eachRoles) => {
          if (eachRoles.roleId === "1") roleCount["publisher"]++;
          else if (eachRoles.roleId === "2") roleCount["narrator"]++;
          else roleCount["author"]++;
        });

        bar.series = [
          roleCount["author"],
          roleCount["narrator"],
          roleCount["publisher"],
        ];
        bar.options = {
          colors: ["rgb(64,156,255)", "rgb(48,219,91)", "rgb(125,122,255)"],
          labels: ["Author(s)", "Narrators(s)", "Publisher(s)"],
          legend: { show: false },
          xaxis: { categories: ["Author(s)", "Narrators(s)", "Publisher(s)"] },
        };
      }
    } else if (
      this.state.function === "Country" ||
      this.state.function === "Codes"
    ) {
      let countryCount = this.handleCountryCount();

      if (this.state.value === "" || this.state.value === "All")
        this.state.genericCodes.map((eachCode) => {
          if (eachCode.country !== undefined)
            eachCode.country.map((eachCountry) => {
              countryCount[eachCountry]++;
            });
        });
      else {
        this.state.genericCodes.map((eachCode) => {
          if (
            this.state.value === eachCode.code &&
            eachCode.country !== undefined
          )
            eachCode.country.map((eachCountry) => {
              countryCount[eachCountry]++;
            });
        });
      }

      let data = this.handleNewCountryCount(countryCount);

      bar.series = data.series;
      bar.options = {
        colors: this.handleColorRandomizer(data.labels.length),
        labels: data.labels,
        legend: { show: false },
        xaxis: { categories: data.categories },
      };
    } else if (this.state.function === "Users") {
      let userCount = {};

      this.state.genericCodes.map((eachCode) => {
        if (eachCode.email !== undefined)
          userCount[eachCode.code] = eachCode.email.length;
        else userCount[eachCode.code] = 0;
      });

      let series = [];
      let labels = [];
      let categories = [];
      let i = 0;

      this.state.genericCodes.map((eachCode) => {
        series[i] = userCount[eachCode.code];
        labels[i] = eachCode.code;
        categories[i] = eachCode.code;
        i++;
      });

      bar.series = series;
      bar.options = {
        colors: [
          "rgb(64,156,255)",
          "rgb(48,219,91)",
          "rgb(125,122,255)",
          "rgb(255,179,64)",
          "rgb(255,100,130)",
          "rgb(218,143,255)",
          "rgb(178,80,0)",
          "rgb(112,215,255)",
          "rgb(54,52,163)",
        ],
        labels: labels,
        legend: { show: false },
        xaxis: { categories: categories },
      };
    } else if (this.state.function === "Audio") {
      let audioCount = {
        50: 0,
        100: 0,
        more: 0,
      };

      this.props.books.map((eachBook) => {
        if (eachBook.audio.length < 50) audioCount["50"]++;
        else if (eachBook.audio.length <= 100) audioCount["100"]++;
        else audioCount["more"]++;
      });

      bar.series = [audioCount["50"], audioCount["100"], audioCount["more"]];
      bar.options = {
        colors: ["rgb(64,156,255)", "rgb(48,219,91)", "rgb(125,122,255)"],
        labels: [
          "Below 50 Audios",
          "50 Audios - 100 Audios",
          "More than 100 Audios",
        ],
        legend: { show: false },
        xaxis: {
          categories: [
            "Below 50 Audios",
            "50 Audios - 100 Audios",
            "More than 100 Audios",
          ],
        },
      };
    } else if (this.state.function === "Pages") {
      let pageCount = {
        20: 0,
        40: 0,
        more: 0,
      };

      if (this.props.books && this.props.books.length > 0)
        this.props.books.map((eachBook) => {
          if (eachBook.pages.length < 20) pageCount["20"]++;
          else if (eachBook.pages.length <= 40) pageCount["40"]++;
          else pageCount["more"]++;
        });

      bar.series = [pageCount["20"], pageCount["40"], pageCount["more"]];
      bar.options = {
        colors: ["rgb(64,156,255)", "rgb(48,219,91)", "rgb(125,122,255)"],
        labels: ["Below 20 Pages", "20 Pages - 40 Pages", "More than 40 Pages"],
        legend: { show: false },
        xaxis: {
          categories: [
            "Below 20 Pages",
            "20 Pages - 40 Pages",
            "More than 40 Pages",
          ],
        },
      };
    }

    this.setState({
      bar: bar,
    });
  };

  handleOptions = () => {
    let options = [];

    this.state.genericCodes.map((eachCode, index) => {
      options.push(
        <Option key={index + 1} value={eachCode.code}>
          {eachCode.code}
        </Option>
      );
    });
    return (
      <>
        <Option key={0} value="All">
          All Codes
        </Option>
        {options}
      </>
    );
  };

  handleDropdown = (value) => {
    this.setState({
      value: value,
    });
    this.handleFunction("Codes");
  };

  handleVerifyCode = () => {
    let emptyCode = [];

    if (this.state.genericCodes)
      for (let i = 0; i < this.state.genericCodes.length; i++)
        if (this.state.genericCodes[i].country === undefined)
          emptyCode.push(this.state.genericCodes[i].code);

    this.setState({ emptyCode: emptyCode });
  };

  render() {
    return (
      <>
        <TopBar>{this.renderTopBar()}</TopBar>

        {(this.state.function === "Codes" &&
          this.state.emptyCode.includes(this.state.value)) ||
        (this.state.function === "Country" &&
          this.state.emptyCode.includes(this.state.value)) ? (
          <div style={{ marginTop: 100 }}>
            <Empty />
          </div>
        ) : (
          <ContentContainer>
            {this.state.bar.series[0] > 0 || this.state.bar.series[1] > 0 ? (
              <>
                <TopChartContainer>
                  <Chart
                    options={this.state.bar.options}
                    series={this.state.bar.series}
                    type="donut"
                    width={(this.state.windowWidth - 200) / 3}
                    height={(this.state.windowHeight - 200) / 2}
                  />
                  <LabelContainer>
                    {this.renderAnalyticsDetails()}
                  </LabelContainer>
                </TopChartContainer>
                <Chart
                  style={{ marginLeft: 50, marginTop: 20 }}
                  options={this.state.bar.options}
                  series={[{ data: this.state.bar.series }]}
                  type="bar"
                  width={this.state.windowWidth - 300}
                  height={(this.state.windowHeight - 200) / 2}
                />
              </>
            ) : (
              <Loading>
                <Spin style={{ color: "#333" }} indicator={antIcon} />
                <p style={{ marginTop: 10 }}>Loading ...</p>
              </Loading>
            )}
          </ContentContainer>
        )}
      </>
    );
  }

  renderSelectionButton = () => {
    if (this.state.function === "Pages")
      return (
        <>
          <SelectedButton
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            Pages
          </SelectedButton>
          <SelectionButton
            onClick={this.handleFunction.bind(this, "Audio")}
            style={{
              marginLeft: -5,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Audio
          </SelectionButton>
        </>
      );
    else if (this.state.function === "Audio")
      return (
        <>
          <SelectionButton
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            onClick={this.handleFunction.bind(this, "Pages")}
          >
            Pages
          </SelectionButton>
          <SelectedButton
            style={{
              marginLeft: -5,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Audio
          </SelectedButton>
        </>
      );
    else if (
      this.state.function === "Country" ||
      this.state.function === "Codes"
    )
      return (
        <>
          <Select
            style={{ width: "200px", marginRight: "10px" }}
            onChange={this.handleDropdown}
            placeholder="All Codes"
            defaultValue={
              this.state.selectedRole ? this.state.selectedRole : "All Codes"
            }
          >
            {this.handleOptions()}
          </Select>
          <SelectedButton
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            Country
          </SelectedButton>
          <SelectionButton
            onClick={this.handleFunction.bind(this, "Users")}
            style={{
              marginLeft: -5,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Users
          </SelectionButton>
        </>
      );
    else if (this.state.function === "Users")
      return (
        <>
          <SelectionButton
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            onClick={this.handleFunction.bind(this, "Country")}
          >
            Country
          </SelectionButton>
          <SelectedButton
            style={{
              marginLeft: -5,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Users
          </SelectedButton>
        </>
      );
  };

  renderTopBar = () => {
    return (
      <>
        <NavContainer onClick={this.handleFunction.bind(this, "Pages")}>
          <NavText
            style={{
              textDecoration:
                this.state.function === "Pages" ||
                this.state.function === "Audio"
                  ? "underline"
                  : "initial",
            }}
          >
            Books Analytics
          </NavText>
        </NavContainer>
        <NavContainer onClick={this.handleFunction.bind(this, "Country")}>
          <NavText
            style={{
              textDecoration:
                this.state.function === "Country" ||
                this.state.function === "Users"
                  ? "underline"
                  : "initial",
            }}
          >
            Generic Codes Analytics
          </NavText>
        </NavContainer>
        <div style={{ flexGrow: 1 }} />
        {this.renderSelectionButton()}
        <div style={{ width: 30 }} />
      </>
    );
  };

  renderAnalyticsDetails = () => {
    let analyticsView = [];

    this.state.bar.series.map((eachSeries, index) => {
      analyticsView.push(
        <AnalyticsDetailContainer key={index}>
          <AnalyticsCircle
            style={{ background: this.state.bar.options.colors[index] }}
          />
          <AnalyticsDetailTextContainer>
            {this.state.function === "Country" ||
            this.state.function === "Users" ||
            this.state.function === "Codes" ? (
              <AnalyticsDetailText>
                {eachSeries + " redeem(s)"}
              </AnalyticsDetailText>
            ) : (
              <AnalyticsDetailText>
                {eachSeries + " book(s)"}
              </AnalyticsDetailText>
            )}
            <AnalyticsDetailSmallText>
              {this.state.bar.options.labels[index]}
            </AnalyticsDetailSmallText>
          </AnalyticsDetailTextContainer>
        </AnalyticsDetailContainer>
      );
    });

    return analyticsView;
  };
}

export default Dashboard;

// CSS //
const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  margin-top: 100px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
`;

const AnalyticsDetailSmallText = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-top: -12px;
  color: rgb(99, 99, 102);
`;

const AnalyticsDetailText = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

const AnalyticsDetailTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnalyticsCircle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 10px;
  margin: 10px;
`;

const AnalyticsDetailContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin: 20px;
  width: auto;
`;

const SelectionButton = styled(Button)`
  color: ${Blue} !important;
  border: solid 1px ${Blue} !important;
  background: ${White} !important;
  border-radius: 10px;
  font-size: 16px;

  &:hover {
    background: rgb(230, 242, 255) !important;
  }
`;

const SelectedButton = styled(Button)`
  color: ${White} !important;
  border: solid 1px ${Blue} !important;
  background: ${Blue} !important;
  border-radius: 10px;
  font-size: 16px;
`;

const NavText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  text-align: center;
  margin: 0 25px;
  color: ${Blue} !important;
  &:hover {
    cursor: pointer;
  }
`;

const NavContainer = styled.div`
  height: 45px;
  width: auto;
`;

const TopChartContainer = styled.div`
  width: calc(100vw - 200px);
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 10px;
`;
