import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
import axios from "axios";

const eventBus = EventBus();

export const editBook = (data) => {
  return async (dispatch, getState) => {
    try {
      let selectedBook = data.selectedBook;
      let imageFile = data.imageFile;
      let backgroundImageFile = data.backgroundImageFile;

      const firestore = firebase.firestore();
      let verifyBook = firestore.collection("books").doc(selectedBook.id);
      if (imageFile && imageFile.name) {
        await firebase
          .storage()
          .ref(`books/${selectedBook.id}/${imageFile.name}`)
          .put(imageFile);
        let url = await firebase
          .storage()
          .ref(`books/${selectedBook.id}`)
          .child(imageFile.name)
          .getDownloadURL();

        await verifyBook.update({
          image: url.split("token=")[1],
        });
      }

      if (backgroundImageFile.name) {
        await firebase
          .storage()
          .ref(`books/${selectedBook.id}/${backgroundImageFile.name}`)
          .put(backgroundImageFile);
        let url = await firebase
          .storage()
          .ref(`books/${selectedBook.id}`)
          .child(backgroundImageFile.name)
          .getDownloadURL();
        await verifyBook.update({
          backgroundImage: url.split("token=")[1],
        });
      }

      let ignoringPoints = false;
      if (!selectedBook.points) ignoringPoints = true;
      else if (
        selectedBook.points.length === 1 &&
        !selectedBook.points[0].attribute
      )
        ignoringPoints = true;

      await verifyBook.update({
        title: selectedBook.title,
        ageId: selectedBook.ageId,
        pricingType: selectedBook.pricingType ? selectedBook.pricingType : [],
        languageId: selectedBook.languageId,
        description: selectedBook.description.trim(),
        publisher: selectedBook.publisher ? selectedBook.publisher : [],
        writer: selectedBook.writer ? selectedBook.writer : [],
        narrator: selectedBook.narrator ? selectedBook.narrator : [],
        illustrator: selectedBook.illustrator ? selectedBook.illustrator : [],
        tags: selectedBook.tags ? selectedBook.tags : [],
        averageTime: selectedBook.averageTime
          ? selectedBook.averageTime
          : "00:00:00",
        country: selectedBook.country ? selectedBook.country : [],
        subscriptionCountry: selectedBook.subscriptionCountry
          ? selectedBook.subscriptionCountry
          : [],
        perpetualCountry: selectedBook.perpetualCountry
          ? selectedBook.perpetualCountry
          : [],
        sound: selectedBook.sound ? selectedBook.sound : [],
        points: ignoringPoints ? [] : selectedBook.points,
        published: selectedBook.published ? true : false,
        updatedAt: new Date(),
      });

      eventBus.emit("editBook-function-success");
    } catch (err) {
      eventBus.emit("editBook-function-error", null, err);
    }
  };
};

export const deleteBook = (bookId) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyBookQuery = firestore.collection("books").doc(bookId);
      let verifyBookData = await verifyBookQuery.get();
      if (verifyBookData.exists) {
        let bookData = verifyBookData.data();
        await firebase
          .storage()
          .ref(`books/${bookId}`)
          .child("previewImage.png")
          .delete();
        await firebase
          .storage()
          .ref(`books/${bookId}/content`)
          .child(bookData.pListName)
          .delete();
        await firebase
          .storage()
          .ref(`books/${bookId}/content`)
          .child(bookData.pListName.split(".plist")[0] + ".png")
          .delete();
        if (bookData.backgroundImage)
          await firebase
            .storage()
            .ref(`books/${bookId}`)
            .child("backgroundImage.png")
            .delete();
        if (bookData.audioName && bookData.audioName.length > 0)
          await Promise.all(
            bookData.audioName.map(async (eachAudioName) => {
              await firebase
                .storage()
                .ref(`books/${bookId}/content`)
                .child(eachAudioName)
                .delete();
            })
          );
        if (bookData.pagesName && bookData.pagesName.length > 0)
          await Promise.all(
            bookData.pagesName.map(async (eachPageName) => {
              await firebase
                .storage()
                .ref(`books/${bookId}/content`)
                .child(eachPageName)
                .delete();
            })
          );
        await verifyBookQuery.delete();
      }
      eventBus.emit("editBook-function-success");
    } catch (err) {
      eventBus.emit("editBook-function-error", null, err);
    }
  };
};

let unzipBookContentApi =
  "https://us-central1-mebooks-plus.cloudfunctions.net/unzipBookContent";

export const uploadBook = (bookClass) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let bookId = bookClass.bookName
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        .split(" ")
        .join("_");

      let verifyBookQuery = firestore.collection("books").doc(bookId);
      let verifyBookData = await verifyBookQuery.get();

      if (!verifyBookData.exists) {
        await firebase
          .storage()
          .ref(`books_temp/${bookClass.bookFileName}`)
          .put(bookClass.bookFile);
        let zipDownloadUrl = await firebase
          .storage()
          .ref(`books_temp/${bookClass.bookFileName}`)
          .getDownloadURL();
        let error = "";
        try {
          await axios.post(unzipBookContentApi, {
            contentPath: zipDownloadUrl,
            bookFileName: bookClass.bookFileName,
            bookId: bookId,
            uploadType: "upload",
          });
        } catch (err) {
          if (err.response && err.response.data && err.response.data.error)
            error = err.response.data.error;
          else if (err.response && err.response.data) error = err.response.data;
          else error = "Unknown error !";
        }

        await firebase
          .storage()
          .ref(`books_temp/${bookClass.bookFileName}`)
          .delete();

        if (error) eventBus.emit("uploadBook-function-error", null, error);
        else {
          let storageRef = firebase.storage().ref(`books/${bookId}/content`);
          let results = await storageRef.listAll();

          let itemAttr = {
            pList: "",
            pListName: "",
            audio: [],
            audioName: [],
            pages: [],
            pagesName: [],
            image: [],
          };

          if (results && results.items.length > 0) {
            await Promise.all(
              results.items.map(async (eachResult, index) => {
                let itemPath = eachResult.location.path.split("/");
                let itemName = itemPath[itemPath.length - 1];
                if (
                  /[^.]+$/.exec(itemName)[0] === "mp3" ||
                  /[^.]+$/.exec(itemName)[0] === "wav" ||
                  /[^.]+$/.exec(itemName)[0] === "caf"
                ) {
                  itemAttr["audioName"].push(itemName);
                  itemAttr["audio"].push(
                    stripUrlToToken(await eachResult.getDownloadURL())
                  );
                } else if (/[^.]+$/.exec(itemName)[0] === "plist") {
                  itemAttr["pListName"] = itemName;
                  itemAttr["pList"] = stripUrlToToken(
                    await eachResult.getDownloadURL()
                  );
                } else if (/[^.]+$/.exec(itemName)[0] === "jpg") {
                  itemAttr["pagesName"].push(itemName);
                  itemAttr["pages"].push(
                    stripUrlToToken(await eachResult.getDownloadURL())
                  );
                }
              })
            );
          }

          let previewRef = firebase
            .storage()
            .ref(`books/${bookId}/previewImage.png`);
          itemAttr["image"] = stripUrlToToken(
            await previewRef.getDownloadURL()
          );

          await verifyBookQuery.set({
            ageId: "1",
            audio: itemAttr["audio"],
            audioName: itemAttr["audioName"],
            averageTime: "00:00:00",
            country: [],
            subscriptionCountry: [],
            perpetualCountry: [],
            description: "",
            id: bookId,
            illustrator: [],
            image: itemAttr["image"],
            languageId: "1",
            narrator: [],
            pList: itemAttr["pList"],
            pListName: itemAttr["pListName"],
            pages: itemAttr["pages"],
            pagesName: itemAttr["pagesName"],
            points: [],
            publisher: [],
            sound: [],
            tags: [],
            title: bookClass.bookName,
            pricingType: [],
            published: false,
            updatedAt: new Date(),
            writer: [],
          });

          eventBus.emit("uploadBook-function-success");
        }
      } else
        eventBus.emit(
          "uploadBook-function-error",
          null,
          "This book has been existed"
        );
    } catch (err) {
      eventBus.emit("uploadBook-function-error", null, err);
    }
  };
};

export const updateBookAssets = (bookClass, bookId) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyBookQuery = firestore.collection("books").doc(bookId);
      let verifyBookData = await verifyBookQuery.get();

      if (verifyBookData.exists) {
        await firebase
          .storage()
          .ref(`books_temp/${bookClass.bookFileName}`)
          .put(bookClass.bookFile);
        let zipDownloadUrl = await firebase
          .storage()
          .ref(`books_temp/${bookClass.bookFileName}`)
          .getDownloadURL();
        let error = "";
        try {
          await axios.post(unzipBookContentApi, {
            contentPath: zipDownloadUrl,
            bookFileName: bookClass.bookFileName,
            bookId: bookId,
            uploadType: "update",
          });
        } catch (err) {
          if (err.response && err.response.data && err.response.data.error)
            error = err.response.data.error;
          else if (err.response && err.response.data) error = err.response.data;
          else error = "Unknown error !";
        }

        await firebase
          .storage()
          .ref(`books_temp/${bookClass.bookFileName}`)
          .delete();

        if (error) eventBus.emit("uploadBook-function-error", null, error);
        else {
          let storageRef = firebase.storage().ref(`books/${bookId}/content`);
          let results = await storageRef.listAll();

          let itemAttr = {
            pList: "",
            pListName: "",
            audio: [],
            audioName: [],
            pages: [],
            pagesName: [],
            image: [],
          };

          if (results && results.items.length > 0) {
            await Promise.all(
              results.items.map(async (eachResult, index) => {
                let itemPath = eachResult.location.path.split("/");
                let itemName = itemPath[itemPath.length - 1];
                if (
                  /[^.]+$/.exec(itemName)[0] === "mp3" ||
                  /[^.]+$/.exec(itemName)[0] === "wav" ||
                  /[^.]+$/.exec(itemName)[0] === "caf"
                ) {
                  itemAttr["audioName"].push(itemName);
                  itemAttr["audio"].push(
                    stripUrlToToken(await eachResult.getDownloadURL())
                  );
                } else if (/[^.]+$/.exec(itemName)[0] === "plist") {
                  itemAttr["pListName"] = itemName;
                  itemAttr["pList"] = stripUrlToToken(
                    await eachResult.getDownloadURL()
                  );
                } else if (/[^.]+$/.exec(itemName)[0] === "jpg") {
                  itemAttr["pagesName"].push(itemName);
                  itemAttr["pages"].push(
                    stripUrlToToken(await eachResult.getDownloadURL())
                  );
                }
              })
            );
          }

          await verifyBookQuery.update({
            audio: itemAttr["audio"],
            audioName: itemAttr["audioName"],
            pList: itemAttr["pList"],
            pListName: itemAttr["pListName"],
            pages: itemAttr["pages"],
            pagesName: itemAttr["pagesName"],
            updatedAt: new Date(),
          });

          eventBus.emit("uploadBook-function-success");
        }
      } else
        eventBus.emit(
          "uploadBook-function-error",
          null,
          "This book is not found"
        );
    } catch (err) {
      eventBus.emit("uploadBook-function-error", null, err);
    }
  };
};

export const uploadBookAudio = (data, bookId) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyBookQuery = firestore.collection("books").doc(bookId);
      let verifyBookData = await verifyBookQuery.get();

      if (verifyBookData.exists) {
        await firebase
          .storage()
          .ref(`books/${bookId}/content/${data.audioFileName}`)
          .put(data.audioFile);

        let soundUrl = await firebase
          .storage()
          .ref(`books/${bookId}/content`)
          .child(data.audioFileName)
          .getDownloadURL();

        let updatedAudio = verifyBookData.data().audio;
        let updatedAudioName = verifyBookData.data().audioName;
        updatedAudio.push(soundUrl.split("token=")[1]);
        updatedAudioName.push(data.audioFileName);

        await firestore.collection("books").doc(bookId).update({
          audio: updatedAudio,
          audioName: updatedAudioName,
          updatedAt: new Date(),
        });

        eventBus.emit("uploadBook-function-success");
      } else
        eventBus.emit(
          "uploadBook-function-error",
          null,
          "This book is not found"
        );
    } catch (err) {
      eventBus.emit("uploadBook-function-error", null, err);
    }
  };
};

const stripUrlToToken = (downloadUrl) => {
  if (downloadUrl) {
    let imageToken = downloadUrl.split("token=")[1];
    return imageToken;
  }
};
