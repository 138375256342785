import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Input, Form, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
//Assets
import MeBooksLogo from "../assets/icon.png";
import FooterImage1 from "../assets/footer1.png";
import FooterImage2 from "../assets/footer2.png";
import FooterImage3 from "../assets/footer3.png";
//Actions
import { signIn } from "../store/actions/authActions";

const antIcon = <LoadingOutlined style={{ fontSize: "40px" }} spin />;
const { Title } = Typography;

class LoginScreen extends Component {
  state = {
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    isLoading: false,
    laptop: true,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.authStore !== this.props.authStore &&
      this.props.authStore.authError
    ) {
      if (this.props.authStore.authError.includes("no user record"))
        this.setState({
          isLoading: false,
          emailError: "Email is not found",
        });
      else
        this.setState({
          isLoading: false,
          passwordError: "Password is incorrect",
        });
    }
  }

  componentDidMount() {
    this.handleCheckWindowSize();
    window.addEventListener("resize", this.handleCheckWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCheckWindowSize);
  }

  handleCheckWindowSize = () => {
    if (window.innerWidth >= 1200 && !this.state.laptop)
      this.setState({
        laptop: true,
      });
    else if (window.innerWidth < 1200 && this.state.laptop)
      this.setState({
        laptop: false,
      });
  };

  handleChange = (event) => {
    let eventId = event.target.id;

    this.setState({
      [eventId]: event.target.value,
    });
  };

  handleSubmit = () => {
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(this.state.email.trim()))
      this.setState({
        emailError: "Please enter the correct email format",
      });
    else if (this.state.password.trim().replace(" ", "") < 1)
      this.setState({
        emailError: "",
        passwordError: "Please enter your password",
      });
    else
      this.setState(
        {
          emailError: "",
          passwordError: "",
          isLoading: true,
        },
        () => {
          this.props.signIn({
            email: this.state.email,
            password: this.state.password,
          });
        }
      );
  };

  renderFooterImage = () => {
    let footerImages = [];

    let imagesLength = Math.floor(window.innerWidth / 180);
    for (let i = 1; i < imagesLength + 1; i++) {
      switch (i % 3) {
        case 0:
          footerImages.push(
            <FooterImage
              style={i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage3}
            />
          );
          break;
        case 1:
          footerImages.push(
            <FooterImage
              style={i === 1 || i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage1}
            />
          );
          break;
        case 2:
          footerImages.push(
            <FooterImage
              style={i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage2}
            />
          );
          break;
        default:
          break;
      }
    }

    return footerImages;
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (this.props.authStore.userAuth) return <Redirect to="/dashboard" />;
    if (!this.state.laptop)
      return (
        <Container>
          <Logo src={MeBooksLogo} />
          <Title level={4}>Only supported in laptop</Title>
        </Container>
      );

    return (
      <Container>
        {!this.state.isLoading ? (
          <StyledForm>
            <Logo src={MeBooksLogo} />
            <Title level={2}>Me Books Dashboard</Title>
            <StyledForm.Item
              validateStatus={this.state.emailError ? "error" : "success"}
              help={this.state.emailError ? this.state.emailError : null}
            >
              <Input
                id="email"
                value={this.state.email}
                onChange={this.handleChange}
                style={{
                  width: 350,
                  height: 40,
                  margin: "10px 0px",
                  borderRadius: 10,
                }}
                placeholder="Email"
              />
            </StyledForm.Item>

            <StyledForm.Item
              validateStatus={this.state.passwordError ? "error" : "success"}
              help={this.state.passwordError ? this.state.passwordError : null}
            >
              <Input
                id="password"
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
                style={{
                  width: 350,
                  height: 40,
                  margin: "10px 0px",
                  borderRadius: 10,
                }}
                placeholder="Password"
              />
            </StyledForm.Item>

            <StyledButton onClick={this.handleSubmit}>Sign In</StyledButton>
          </StyledForm>
        ) : (
          <Loading>
            <Spin style={{ color: "#333" }} indicator={antIcon} />
            <p style={{ marginTop: 10 }}>Loading ...</p>
          </Loading>
        )}
        <Footer>{this.renderFooterImage()}</Footer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => dispatch(signIn(credentials)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;

const StyledButton = styled(Button)`
  margin: 20px !important;
  border-radius: 10px;
  background: rgb(10, 133, 255) !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  border: none !important;
  width: 350px;
  height: 40px;
  &:hover {
    background: rgb(64, 155, 255) !important;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 90px;
  height: 90px;
`;

const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0px;
  z-index: -5;
  overflow: none;
  align-items: center;
`;

const FooterImage = styled.img`
  width: 180px;
  height: 180px;
  margin-bottom: -30px;
`;
