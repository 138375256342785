import React, { Component } from "react";
import { Select, Input, Pagination } from "antd";
//Redux Firebase
import { connect } from "react-redux";
import { compose } from "redux";
//Actions
import {
  createRole,
  deleteRole,
  editRole,
} from "../../store/actions/roleActions";
// Components
import {
  NavBar,
  BackButton,
  TableLayout,
  TableContent,
  TableContentDefault,
  ContentContainer,
  TopBarSearch,
  TopBarIcon,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarBreadcrumb,
  TopBarDeleteButton,
  TopBarSaveButton,
  TopBar,
  FormText,
  CustomForm,
} from "./Layout";
import { SaveDialog, DeleteDialog } from "../Dialog";
//Utils
import EventBus from "js-event-bus";

const { Option } = Select;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Book Tagged",
    dataIndex: "bookTagged",
    key: "bookTagged",
  },
];

const eventBus = EventBus();

class Roles extends Component {
  state = {
    function: "",
    adminName: "",
    adminNameError: "",
    search: "",
    selectedRole: [],
    selectedRoleError: "",
    editId: "",
    deleteModal: false,
    page: 1,
  };

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("role-function-success");
    eventBus.detach("role-function-error");
  }

  componentDidMount() {
    eventBus.on("role-function-success", this.handleClearState);
    eventBus.on("role-function-error", this.handleError);
  }

  handleError = (err) => {
    this.setState(
      {
        adminNameError: err,
        selectedRoleError: err,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        adminName: "",
        adminNameError: "",
        selectedRole: 0,
        selectedRoleError: "",
        editId: "",
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, roleData) => {
    this.setState(
      {
        function: type,
        adminName: type === "edit" ? roleData.name : "",
        adminNameError: "",
        selectedRole: type === "edit" ? roleData.roles : "",
        selectedRoleError: "",
        editId: type === "edit" ? roleData.id : "",
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handleChange = (event) => {
    let eventId = event.target.id;

    this.setState(
      {
        [eventId]: event.target.value,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleSubmit = (type) => {
    this.props.handleLoading(true);

    if (this.state.adminName.length < 1)
      this.setState({
        adminNameError: "Please insert the name of the admin",
      });
    else if (this.state.adminName === this.props.roles.adminName)
      this.setState({ adminNameError: "Please insert a different name" });
    else
      this.setState({
        adminNameError: "",
      });

    if (this.state.selectedRole.length < 1)
      this.setState({
        selectedRoleError: "Please select one role for the admin",
      });
    else
      this.setState({
        selectedRoleError: "",
      });

    setTimeout(() => {
      if (!this.state.selectedRoleError && !this.state.adminNameError) {
        this.setState(
          {
            adminNameError: null,
            selectedRoleError: null,
          },
          () => {
            if (this.state.function === "edit")
              this.props.editRole(
                this.state.adminName,
                this.state.selectedRole,
                this.state.editId
              );
            else
              this.props.createRole(
                this.state.adminName,
                this.state.selectedRole
              );
          }
        );
        this.props.handleDataChanged(false);
      } else {
        this.props.handleLoading(false);
      }
    }, 500);
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteRole(this.state.editId);
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  handleSelectRoles = (value) => {
    this.setState(
      {
        selectedRole: value,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  handleRole = (roles) => {
    let newRole = "";
    let role = "";
    for (let i = 0; i < roles.length; i++) {
      if (i !== 0) newRole = newRole.concat(", " + roles[i]);
      else newRole = newRole.concat(roles[i]);
    }

    return newRole;
  };

  handleCount = (id) => {
    let type = ["publisher", "writer", "narrator", "illustrator"];
    let count = 0;
    if (this.props.books && this.props.books.length > 0) {
      this.props.books.map((eachBook) => {
        for (let i = 0; i < type.length; i++) {
          let tempType = type[i];
          let roles = eachBook[tempType];
          if (roles && roles.length === 1) {
            if (roles[0] === id) count++;
            else if (roles[0] === id) count++;
            else if (roles[0] === id) count++;
            else if (roles[0] === id) count++;
          } else if (roles) {
            for (let i = 0; i < roles.length; i++) {
              if (roles[i] === id) count++;
              else if (roles[i] === id) count++;
              else if (roles[i] === id) count++;
              else if (roles[i] === id) count++;
            }
          }
        }
      });
    }
    return count;
  };

  renderRoles = () => {
    let roleView = [];
    let rolesList = [];
    let roles = [];
    let filterRoles = [];

    if (this.props.roles && this.props.roles.length > 0)
      this.props.roles.map((eachRole) => {
        rolesList.push({
          id: eachRole.id,
          name: eachRole.name,
          bookTagged: this.handleCount(eachRole.id),
          roles: eachRole.roles,
        });
      });

    if (rolesList.length > 0) {
      let noBook = [];
      let hasBook = [];

      rolesList.map((eachRole) => {
        if (eachRole.bookTagged > 0) hasBook.push(eachRole);
        else noBook.push(eachRole);

        hasBook.sort(function (a, b) {
          return b.bookTagged - a.bookTagged;
        });
        filterRoles = hasBook.concat(noBook);
      });

      let min = (this.state.page - 1) * 100;
      let max =
        this.state.page * 50 > filterRoles.length
          ? filterRoles.length
          : this.state.page * 100;
      if (this.state.search) roles = filterRoles;
      else roles = filterRoles.slice(min, max);

      roles.map((eachRole, index) => {
        if (
          eachRole.name &&
          eachRole.name
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        )
          roleView.push({
            key: index,
            name: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachRole)}
              >
                {eachRole.name}
              </TableContent>
            ),
            role: (
              <TableContentDefault
                onClick={this.handleFunction.bind(this, "edit", eachRole)}
              >
                {this.handleRole(eachRole.roles)}
              </TableContentDefault>
            ),
            bookTagged: (
              <TableContentDefault
                onClick={this.handleFunction.bind(this, "edit", eachRole)}
              >
                {eachRole.bookTagged}
              </TableContentDefault>
            ),
          });
      });
    }

    return roleView;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "")} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "")}>
              Roles
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Roles"
                : "Creating Roles"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>
          <div style={{ flex: 1 }} />
          {this.state.function === "edit" && (
            <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
              Delete
            </TopBarDeleteButton>
          )}
          <TopBarSaveButton
            onClick={this.handleSubmit.bind(this, this.state.function)}
          >
            Save
          </TopBarSaveButton>
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Roles</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleFunction.bind(this, "create")}
            style={{ left: 90 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <CustomForm>
          <CustomForm.Item
            validateStatus={this.state.adminNameError ? "error" : "success"}
            help={this.state.adminNameError}
          >
            <FormText>Name of Admin</FormText>
            <Input
              id="adminName"
              value={this.state.adminName}
              onChange={this.handleChange}
              style={{ width: 400, borderRadius: 10 }}
            />
          </CustomForm.Item>

          <CustomForm.Item
            validateStatus={this.state.selectedRoleError ? "error" : "success"}
            help={this.state.selectedRoleError}
          >
            <FormText>Roles</FormText>
            <Select
              mode="multiple"
              onChange={this.handleSelectRoles}
              placeholder="Select Roles"
              style={{ width: 400 }}
              value={this.state.selectedRole ? this.state.selectedRole : []}
            >
              <Option key={1} value="Publisher">
                Publisher
              </Option>
              <Option key={2} value="Narrator">
                Narrator
              </Option>
              <Option key={3} value="Writer">
                Author
              </Option>
              <Option key={4} value="Illustrator">
                Illustrator
              </Option>
            </Select>
          </CustomForm.Item>
        </CustomForm>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderRoles()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.roles !== undefined ? (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 100 >= this.props.roles.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          ) : null}
        </>
      );
  };

  render() {
    return (
      <>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createRole: (name, roles) => dispatch(createRole(name, roles)),
    deleteRole: (uuid) => dispatch(deleteRole(uuid)),
    editRole: (name, roles, uuid) => dispatch(editRole(name, roles, uuid)),
  };
};

export default compose(connect(null, mapDispatchToProps))(Roles);
