import React, { Component } from "react";
import { Input, Pagination } from "antd";
//Redux Firebase
import { connect } from "react-redux";
//Components
import {
  BackButton,
  NavBar,
  TableContentDefault,
  TableLayout,
  TableContent,
  TopBarBreadcrumb,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarSearch,
  TopBar,
  FormText,
  ContentContainer,
  CustomForm,
} from "./Layout";
//Actions
import { updateReportQuery } from "../../store/actions/reportActions";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 500,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 500,
  },
];

const detailsColumns = [
  {
    title: "Book ID",
    dataIndex: "bookId",
    key: "bookId",
  },
  {
    title: "Points",
    dataIndex: "points",
    key: "points",
  },
  {
    title: "Page View",
    dataIndex: "pageView",
    key: "pageView",
  },
  {
    title: "Screen Time",
    dataIndex: "screenTime",
    key: "screenTime",
  },
  {
    title: "Narration",
    dataIndex: "narration",
    key: "narration",
  },
];

class Reports extends Component {
  state = {
    function: "",
    search: "",
    selectedReport: [],
    limit: 100,
    page: 1,
    booksPage: 1,
    booksLimit: 100,
  };

  componentWillUnmount() {
    this.handleClearState();
    this.props.updateReportQuery(100, "");
  }

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedReport: [],
        limit: 100,
        page: 1,
        booksPage: 1,
        booksLimit: 100,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, data) => {
    if (type === "edit")
      this.setState({
        function: type,
        selectedReport: data,
        booksPage: 1,
        booksLimit: 100,
      });
    else this.setState({ function: type });
  };

  handleSearch = (event) => {
    this.setState(
      {
        search: event.target.value,
        limit: 100,
        page: 1,
      },
      () => {
        clearInterval(this.timer);
        this.timer = setInterval(this.handleTimerSearch, 500);
      }
    );
  };

  handleTimerSearch = () => {
    this.props.updateReportQuery(100, this.state.search);
    clearInterval(this.timer);
  };

  handlePagination = (page) => {
    let newLimit = 100 * page;
    this.props.updateReportQuery(newLimit, this.state.search);

    this.setState({ page: page, limit: newLimit });
  };

  renderReport = () => {
    let reportView = [];
    if (this.props.reports && this.props.reports.length > 0) {
      let min = (this.state.page - 1) * 100;
      let max = this.props.reports.length;

      this.props.reports.slice(min, max).map((eachReport, index) => {
        reportView.push({
          key: index,
          id: (
            <TableContent
              onClick={this.handleFunction.bind(this, "edit", eachReport)}
            >
              {eachReport.id}
            </TableContent>
          ),
          date: (
            <TableContentDefault
              onClick={this.handleFunction.bind(this, "edit", eachReport)}
            >
              {eachReport.date}
            </TableContentDefault>
          ),
        });
      });
    }

    return reportView;
  };

  renderDetails = () => {
    let detailsView = [];
    if (
      this.state.selectedReport.details &&
      this.state.selectedReport.details.length > 0
    )
      this.state.selectedReport.details.map((eachDetail, index) => {
        detailsView.push({
          key: index,
          bookId: (
            <TableContentDefault>{eachDetail.bookId}</TableContentDefault>
          ),
          points: (
            <TableContentDefault>{eachDetail.points}</TableContentDefault>
          ),
          pageView: (
            <TableContentDefault>{eachDetail.pageView}</TableContentDefault>
          ),
          screenTime: (
            <TableContentDefault>
              {this.sec2time(eachDetail.screenTime)}
            </TableContentDefault>
          ),
          narration: (
            <TableContentDefault>{eachDetail.narration}</TableContentDefault>
          ),
        });
      });

    return detailsView;
  };

  sec2time = (timeInSeconds) => {
    let pad = function (num, size) {
        return ("000" + num).slice(size * -1);
      },
      time = parseFloat(timeInSeconds).toFixed(3),
      hours = Math.floor(time / 60 / 60),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);

    return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "")} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "")}>
              Reports
            </BreadcrumbLink>
            <BreadcrumbActive>Editing Reports</BreadcrumbActive>
          </TopBarBreadcrumb>
          <div style={{ flex: 1 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Reports</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <>
          <CustomForm>
            <FormText>ID</FormText>
            <Input
              id="id"
              value={this.state.selectedReport.id}
              style={{ width: "400px", borderRadius: 10, marginBottom: 20 }}
              disabled
            />
            <FormText>Date</FormText>
            <Input
              id="date"
              value={this.state.selectedReport.date}
              style={{ width: "400px", borderRadius: 10, marginBottom: 20 }}
              disabled
            />
            <FormText>Children ID</FormText>
            <Input
              id="childId"
              value={this.state.selectedReport.childId}
              style={{ width: "400px", borderRadius: 10, marginBottom: 20 }}
              disabled
            />

            <FormText style={{ marginTop: 30 }}>
              Book Details (Total : {this.state.selectedReport.details.length})
            </FormText>
            <TableLayout
              style={{ marginTop: "0px", marginLeft: "0px", width: "100%" }}
              columns={detailsColumns}
              dataSource={this.renderDetails()}
              pagination={false}
              scroll={{ y: window.innerHeight - 250 }}
            />
          </CustomForm>
        </>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderReport()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.reports !== undefined ? (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.props.reports.length < this.state.limit
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          ) : null}
        </>
      );
  };

  render() {
    return (
      <>
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reports: state.firestore.ordered.reports,
    reportStore: state.reportStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateReportQuery: (limit, search) =>
      dispatch(updateReportQuery(limit, search)),
  };
};

export default Reports;

// export default compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   firestoreConnect((props) => {
//     let firestoreList = [];

//     if (props.reportStore.search) {
//       let strSearch = props.reportStore.search;
//       let strlength = strSearch.length;
//       let strFrontCode = strSearch.slice(0, strlength - 1);
//       let strEndCode = strSearch.slice(strlength - 1, strSearch.length);

//       let startcode = strSearch;
//       let endcode =
//         strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

//       firestoreList.push({
//         collection: "reports",
//         where: [
//           ["id", ">=", startcode],
//           ["id", "<", endcode],
//         ],
//         limit: props.reportStore.limit,
//       });
//     } else
//       firestoreList.push({
//         collection: "reports",
//         limit: props.reportStore.limit,
//       });

//     return firestoreList;
//   })
// )(Reports);
