import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
const eventBus = EventBus();
const uuid = require("uuid/v4");

export const createRole = (name, roles) => {
  return async (dispatch, getState) => {
    try {
      let uniqueId = uuid();
      const firestore = firebase.firestore();
      let verifyRoles = firestore.collection("roles").doc(uniqueId);
      let verifyRolesDoc = await verifyRoles.get();

      if (!verifyRolesDoc.exists) {
        await firestore.collection("roles").doc(uniqueId).set({
          id: uniqueId,
          name: name.trim(),
          roles: roles,
        });
        eventBus.emit("role-function-success");
      } else
        eventBus.emit(
          "role-function-error",
          null,
          "The role's user has been created"
        );
    } catch (err) {
      eventBus.emit("role-function-error", null, err);
    }
  };
};

export const editRole = (name, roles, uuid) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyRole = firestore.collection("roles").doc(uuid);
      await verifyRole.update({
        name: name.trim(),
        roles: roles,
      });
      eventBus.emit("role-function-success");
    } catch (err) {
      eventBus.emit("role-function-error", null, err);
    }
  };
};

export const deleteRole = (uuid) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyRoles = firestore.collection("roles").doc(uuid);
      await verifyRoles.delete();
      eventBus.emit("role-function-success");
    } catch (err) {
      eventBus.emit("role-function-error", null, err);
    }
  };
};
