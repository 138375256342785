const initState = {
  limit: 100,
  search: "",
  analyticsLimit: 20,
  childrenId: "",
};

const childReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_CHILDREN_QUERY":
      return {
        ...state,
        limit: action.payload.limit,
        search: action.payload.search,
      };
    case "UPDATE_ANALYTIC_QUERY":
      return {
        ...state,
        analyticsLimit: action.payload.analyticsLimit,
        childrenId: action.payload.childrenId,
      };
    default:
      return state;
  }
};

export default childReducer;
