import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Analytics from "./content/Analytics";
import Banners from "./content/Banners";
import Books from "./content/Books";
import Categories from "./content/Categories";
import Children from "./content/Children";
import Collections from "./content/Collections";
//Components
import Dashboard from "./content/Dashboard";
import FaceStickers from "./content/FaceStickers";
import GenericCodes from "./content/GenericCodes";
import Notice from "./content/Notice";
import RedeemCodes from "./content/RedeemCodes";
import Reports from "./content/Reports";
import Roles from "./content/Roles";
import Series from "./content/Series";
import SoundEffects from "./content/SoundEffects";
import Stickers from "./content/Stickers";
import Tags from "./content/Tags";
import Users from "./content/Users";

class Content extends Component {
  renderContent = () => {
    switch (this.props.location.pathname) {
      case "/dashboard":
        return (
          <Dashboard
            handleLoading={this.props.handleLoading}
            books={this.props.books}
          />
        );
      case "/banners":
        return (
          <Banners
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
            books={this.props.books}
            banners={this.props.banners}
            series={this.props.series}
          />
        );
      case "/notice":
        return (
          <Notice
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            notice={this.props.notice}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
          />
        );
      case "/books":
        return (
          <Books
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
            books={this.props.books}
            tags={this.props.tags}
            roles={this.props.roles}
            stickers={this.props.stickers}
          />
        );
      case "/series":
        return (
          <Series
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
            books={this.props.books}
            tags={this.props.tags}
            series={this.props.series}
          />
        );
      case "/categories":
        return (
          <Categories
            history={this.props.history}
            handleDataChanged={this.props.handleDataChanged}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
            tags={this.props.tags}
            roles={this.props.roles}
            categories={this.props.categories}
          />
        );
      case "/collections":
        return (
          <Collections
            history={this.props.history}
            categories={this.props.categories}
            collections={this.props.collections}
            handleDataChanged={this.props.handleDataChanged}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
          />
        );
      case "/tags":
        return (
          <Tags
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
            tags={this.props.tags}
            books={this.props.books}
          />
        );
      case "/roles":
        return (
          <Roles
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
            roles={this.props.roles}
            books={this.props.books}
          />
        );
      case "/users":
        return (
          <Users
            handleLoading={this.props.handleLoading}
            books={this.props.books}
          />
        );
      case "/children":
        return (
          <Children
            handleLoading={this.props.handleLoading}
            books={this.props.books}
          />
        );
      case "/stickers":
        return (
          <Stickers
            stickers={this.props.stickers}
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
          />
        );
      case "/faceSticker":
        return (
          <FaceStickers
            faceStickers={this.props.faceStickers}
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
          />
        );
      case "/soundEffect":
        return (
          <SoundEffects
            soundEffect={this.props.soundEffects}
            handleDataChanged={this.props.handleDataChanged}
            history={this.props.history}
            switchPage={this.props.switchPage}
            handleSwitchPage={this.props.handleSwitchPage}
            handleLoading={this.props.handleLoading}
          />
        );
      case "/analytics":
        return <Analytics handleLoading={this.props.handleLoading} />;
      case "/reports":
        return <Reports handleLoading={this.props.handleLoading} />;
      case "/codes":
        return <RedeemCodes handleLoading={this.props.handleLoading} />;
      case "/generic":
        return <GenericCodes handleLoading={this.props.handleLoading} />;
      default:
        return 0;
    }
  };
  render() {
    return (
      <Container style={this.props.style}>{this.renderContent()}</Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faceStickers: state.dataStore.faceStickers,
    soundEffects: state.dataStore.soundEffects,
    books: state.dataStore.books,
    banners: state.dataStore.banners,
    series: state.dataStore.series,
    categories: state.dataStore.categories,
    collections: state.dataStore.collections,
    stickers: state.dataStore.stickers,
    tags: state.dataStore.tags,
    roles: state.dataStore.roles,
    notice: state.dataStore.notice,
  };
};

export default connect(mapStateToProps)(Content);

const Container = styled.div`
  position: absolute;
  left: 200px;
  top: 50px;
  width: calc(100vw - 200px);
  height: calc(100% - 50px);
`;
