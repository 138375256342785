//Utils
import EventBus from "js-event-bus";
import firebase from "../../utils/firebase";
const eventBus = EventBus();
const uuid = require("uuid/v4");

export const createSoundEffect = (data) => {
  return async (dispatch, getState) => {
    try {
      let uniqueId = `effect_${uuid()}`;
      const firestore = firebase.firestore();
      let verifySticker = firestore.collection("soundEffects").doc(uniqueId);
      let verifyStickerDoc = await verifySticker.get();

      if (!verifyStickerDoc.exists) {
        await firebase
          .storage()
          .ref(`assets_soundEffects/${uniqueId}/${data.stickerFile.name}`)
          .put(data.stickerFile);
        let url = await firebase
          .storage()
          .ref(`assets_soundEffects/${uniqueId}`)
          .child(data.stickerFile.name)
          .getDownloadURL();
        await firebase
          .storage()
          .ref(`assets_soundEffects/${uniqueId}/${data.soundFile.name}`)
          .put(data.soundFile);
        let soundUrl = await firebase
          .storage()
          .ref(`assets_soundEffects/${uniqueId}`)
          .child(data.soundFile.name)
          .getDownloadURL();
        await firestore.collection("soundEffects").doc(uniqueId).set({
          id: uniqueId,
          image: url,
          sound: soundUrl,
          name: data.selectedSticker.name.trim(),
        });
        eventBus.emit("sticker-function-success");
      } else
        eventBus.emit(
          "sticker-function-error",
          null,
          "The sound effect has been created"
        );
    } catch (err) {
      eventBus.emit("sticker-function-error", null, err);
    }
  };
};

export const deleteSoundEffect = (data) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifySticker = firestore.collection("soundEffects").doc(data.id);
      await verifySticker.delete();
      await firebase
        .storage()
        .ref(`assets_soundEffects/${data.id}`)
        .child("sticker.png")
        .delete();
      await firebase
        .storage()
        .ref(`assets_soundEffects/${data.id}`)
        .child("sound.mp3")
        .delete();
      eventBus.emit("sticker-function-success");
    } catch (err) {
      eventBus.emit("sticker-function-error", null, err);
    }
  };
};
