const initState = {
  limit: 100,
  search: "",
};

const analyticsReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_ANALYTICS_QUERY":
      return {
        ...state,
        limit: action.payload.limit,
        search: action.payload.search,
      };
    default:
      return state;
  }
};

export default analyticsReducer;
