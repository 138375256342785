import React, { Component } from "react";
import styled from "styled-components";
import { Upload, Select, Input, Checkbox, Pagination } from "antd";
//Redux Firebase
import { connect } from "react-redux";
//Actions
import {
  createCategories,
  deleteCategories,
  editCategories,
} from "../../store/actions/categoriesActions";
//Components
import {
  BackButton,
  NavBar,
  MinusConditionsIcon,
  // AddConditionsIcon,
  FlexContentContainer,
  EditImageContainer,
  EditImageButton,
  TableLayout,
  TableContent,
  ContentContainer,
  FormText,
  TopBar,
  TopBarSaveButton,
  TopBarDeleteButton,
  TopBarIcon,
  TopBarSearch,
  CustomForm,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarBreadcrumb,
} from "./Layout.js";
import { SaveDialog, DeleteDialog } from "../Dialog";
//Utils
import EventBus from "js-event-bus";

const { Option } = Select;

const columns = [
  {
    title: "Name",
    dataIndex: "discoverName",
    key: "discoverName",
    width: 500,
  },
  {
    title: "Avatar",
    dataIndex: "avatar",
    key: "avatar",
    width: 500,
  },
  {
    title: "Selection Icon",
    dataIndex: "selectionIcon",
    key: "selectionIcon",
    width: 500,
  },
];

const eventBus = EventBus();
let pageAudio = new Audio();

class Categories extends Component {
  state = {
    page: 1,
    function: "",
    search: "",
    selectedCategories: "",
    avatarFile: "",
    avatarError: "",
    avatarUrl: "",
    iconFile: "",
    iconError: "",
    iconUrl: "",
    soundUrl: "",
    soundFile: "",
    soundError: "",
    homeNameError: "",
    discoverNameError: "",
    descriptionError: "",
    priorityError: "",
    conditions: [],
    conditionsError: [],
    conditionCount: 1,
    discoverShow: false,
    deleteModal: false,
    editAvatar: false,
    editIcon: false,
  };

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("categories-function-success");
    eventBus.detach("categories-function-error");
  }

  componentDidMount() {
    eventBus.on("categories-function-success", this.handleClearState);
    eventBus.on("categories-function-error", this.handleError);
  }

  handleError = (err) => {
    this.setState(
      {
        avatarError: err,
        iconError: err,
        homeNameError: err,
        discoverNameError: err,
        conditionsError: err,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedCategories: "",
        avatarFile: "",
        avatarError: "",
        avatarUrl: "",
        iconFile: "",
        iconError: "",
        iconUrl: "",
        soundUrl: "",
        soundFile: "",
        soundError: "",
        homeNameError: "",
        discoverNameError: "",
        priorityError: "",
        descriptionError: "",
        conditions: [],
        conditionsError: [],
        conditionCount: 1,
        discoverShow: false,
        deleteModal: false,
        editAvatar: false,
        editIcon: false,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, data) => {
    let tempCategories = "";
    let tempConditions = [];

    if (type === "edit") {
      tempCategories = JSON.parse(JSON.stringify(data));
      tempConditions = data.conditions
        ? JSON.parse(JSON.stringify(data.conditions))
        : [{ relation: "or", attribute: "", boolean: "", query: "" }];
    } else if (type === "create") {
      tempCategories = {
        homeName: "",
        discoverName: "",
        discoverShow: false,
        description: "",
        avatar: "",
        selectionIcon: "",
        sound: "",
      };
      tempConditions = [{ attribute: "", boolean: "is", query: "" }];
    }
    this.handleClearState();
    this.setState(
      {
        function: type,
        selectedCategories: tempCategories,
        conditions: tempConditions,
        conditionCount: tempConditions ? tempConditions.length : 1,
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handleChange = (event) => {
    let tempCategories = this.state.selectedCategories;
    tempCategories[event.target.id] = event.target.value;

    this.setState(
      {
        selectedCategories: tempCategories,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleChecked = (event) => {
    let tempCategories = this.state.selectedCategories;
    tempCategories.discoverShow = event.target.checked;
    this.setState({ selectedCategories: tempCategories });
    this.props.handleDataChanged(true);
  };

  handleChangeAvatar = (event) => {
    let newFile = event.file.originFileObj;
    Object.defineProperty(newFile, "name", {
      writable: true,
      value: "avatar.png",
    });

    this.getBase64(newFile, (imageUrl) =>
      this.setState(
        {
          avatarUrl: imageUrl,
          avatarFile: newFile,
        },
        () => {
          this.props.handleDataChanged(true);
        }
      )
    );
  };

  handleChangeIcon = (event) => {
    let newFile = event.file.originFileObj;
    Object.defineProperty(newFile, "name", {
      writable: true,
      value: "selection_icon.png",
    });

    this.getBase64(newFile, (imageUrl) =>
      this.setState(
        {
          iconUrl: imageUrl,
          iconFile: newFile,
        },
        () => {
          this.props.handleDataChanged(true);
        }
      )
    );
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleConditions = (type, num, value) => {
    let tempConditions = this.state.conditions;
    let tempError = this.state.conditionsError;

    if (type === "add") {
      tempConditions.push({
        relation: "or",
        attribute: "",
        boolean: "is",
        query: "",
      });
      tempError.push("");
      this.setState({
        conditionCount: this.state.conditionCount + 1,
        conditions: tempConditions,
        conditionsError: tempError,
      });
    } else if (type === "minus") {
      tempConditions.pop();
      tempError.pop();
      this.setState({
        conditionCount: this.state.conditionCount - 1,
        conditions: tempConditions,
        conditionsError: tempError,
      });
    } else {
      tempConditions[num][type] = value;
      this.setState({ conditions: tempConditions });
    }
  };

  handleChangeSound = (event) => {
    let newFile = event.file.originFileObj;
    if (newFile) {
      Object.defineProperty(newFile, "name", {
        writable: true,
        value: "sound.mp3",
      });

      this.getBase64(newFile, (soundUrl) =>
        this.setState(
          {
            soundUrl: soundUrl,
            soundFile: newFile,
          },
          () => {
            this.props.handleDataChanged(true);
          }
        )
      );
    }
  };

  handlePlayUploadedSound = () => {
    pageAudio.pause();
    if (this.state.selectedCategories.sound)
      pageAudio = new Audio(
        `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${this.state.selectedCategories.id}%2Fsound.mp3?alt=media&token=${this.state.selectedCategories.sound}`
      );
    else pageAudio = new Audio(this.state.soundUrl);

    pageAudio.play();
  };

  handleSubmit = () => {
    this.props.handleLoading(true);

    if (!this.state.avatarFile && !this.state.selectedCategories.avatar)
      this.setState({ avatarError: "Please upload an avatar" });
    else this.setState({ avatarError: "" });

    if (!this.state.iconFile && !this.state.selectedCategories.selectionIcon)
      this.setState({ iconError: "Please upload an Icon" });
    else this.setState({ iconError: "" });

    if (!this.state.soundFile && !this.state.selectedCategories.sound)
      this.setState({ soundError: "Please upload a sound file" });
    else this.setState({ soundError: "" });

    // if (
    //   !this.state.selectedCategories.homeName &&
    //   this.state.selectedCategories.show
    // )
    //   this.setState({ homeNameError: "Please insert the name in home screen" });
    // else this.setState({ homeNameError: "" });

    if (!this.state.selectedCategories.discoverName)
      this.setState({
        discoverNameError: "Please insert the name in discover screen",
      });
    else this.setState({ discoverNameError: "" });

    if (!this.state.selectedCategories.description)
      this.setState({
        descriptionError: "Please insert the description",
      });
    else this.setState({ descriptionError: "" });

    if (!this.state.selectedCategories.priority)
      this.setState({ priorityError: "Please insert a priority" });
    else if (isNaN(this.state.selectedCategories.priority))
      this.setState({ priorityError: "Priority should be a number only" });
    else this.setState({ priorityError: null });

    for (let i = 0; i < this.state.conditions.length; i++) {
      let tempConditions = this.state.conditions;
      let tempError = this.state.conditionsError;
      if (!tempConditions[i].attribute)
        tempError[i] = "Please select an attribute";
      else if (!tempConditions[i].boolean)
        tempError[i] = "Please select a boolean";
      else if (!tempConditions[i].query) tempError[i] = "Please select a query";
      else tempError[i] = "";

      if (tempError[i]) this.setState({ conditionsError: tempError });
      else {
        let tempCategories = this.state.selectedCategories;
        tempCategories.conditions = tempConditions;
        this.setState({ selectedCategories: tempCategories });
      }
    }

    let conditionsError = true;
    for (let i = 0; i < this.state.conditionsError.length; i++)
      if (!this.state.conditionsError[i]) conditionsError = false;

    setTimeout(() => {
      if (
        !this.state.avatarError &&
        !this.state.iconError &&
        !this.state.discoverNameError &&
        !this.state.descriptionError &&
        !this.state.priorityError &&
        !this.state.soundError &&
        !conditionsError
      ) {
        if (this.state.function === "create")
          this.props.createCategories({
            avatarFile: this.state.avatarFile,
            iconFile: this.state.iconFile,
            soundFile: this.state.soundFile,
            selectedCategories: this.state.selectedCategories,
          });
        else if (this.state.function === "edit")
          this.props.editCategories({
            avatarFile: this.state.avatarFile,
            iconFile: this.state.iconFile,
            soundFile: this.state.soundFile,
            selectedCategories: this.state.selectedCategories,
          });
        this.props.handleDataChanged(false);
      } else this.props.handleLoading(false);
    }, 500);
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteCategories(this.state.selectedCategories.id);
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  renderCategories = () => {
    let categoriesView = [];
    let categories = [];

    if (this.props.categories && this.props.categories.length > 0) {
      let min = (this.state.page - 1) * 50;
      let max =
        this.state.page * 50 > this.props.categories.length
          ? this.props.categories.length
          : this.state.page * 50;
      if (this.state.search) categories = this.props.categories;
      else categories = this.props.categories.slice(min, max);
      categories.map((eachCategories, index) => {
        if (
          eachCategories &&
          eachCategories.discoverName
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        )
          categoriesView.push({
            key: index,
            discoverName: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachCategories)}
              >
                {eachCategories.discoverName}
              </TableContent>
            ),
            avatar: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachCategories)}
              >
                <CategoriesImage
                  src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategories.id}%2Favatar.png?alt=media&token=${eachCategories.avatar}`}
                />
              </TableContent>
            ),
            selectionIcon: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachCategories)}
              >
                <CategoriesImage
                  src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategories.id}%2Fselection_icon.png?alt=media&token=${eachCategories.selectionIcon}`}
                />
              </TableContent>
            ),
          });
      });
    }

    return categoriesView;
  };

  renderTags = () => {
    let tagsView = [];
    if (this.props.tags && this.props.tags.length > 0)
      this.props.tags.map((eachTag, index) => {
        tagsView.push(
          <Option key={index + 1} value={eachTag.id}>
            {eachTag.title}
          </Option>
        );
      });
    return tagsView;
  };

  renderAge = () => {
    let ageView = [];
    ageView.push(
      <Option key={1} value="1">
        Below 3 years old
      </Option>,
      <Option key={2} value="2">
        4-6 years old
      </Option>,
      <Option key={4} value="3">
        7-9 years old
      </Option>,
      <Option key={5} value="4">
        Above 9 years old
      </Option>
    );
    return ageView;
  };

  renderLanguage = () => {
    let languageView = [];
    languageView.push(
      <Option key={1} value={1}>
        English (US)
      </Option>,
      <Option key={2} value={2}>
        English (UK)
      </Option>,
      <Option key={3} value={3}>
        Arabic
      </Option>,
      <Option key={4} value={4}>
        Bahasa Malaysia
      </Option>,
      <Option key={5} value={5}>
        Chinese
      </Option>
    );
    return languageView;
  };

  renderRoles = (attribute) => {
    let rolesView = [];
    if (this.props.roles && this.props.roles.length > 0) {
      let selectedAttribute =
        attribute.charAt(0).toUpperCase() + attribute.slice(1);
      this.props.roles.map((eachRole, index) => {
        if (eachRole.roles.includes(selectedAttribute))
          rolesView.push(
            <Option key={index + 1} value={eachRole.id}>
              {eachRole.name}
            </Option>
          );
      });
    }

    return rolesView;
  };

  renderQuery = (i) => {
    let attribute = this.state.conditions[i].attribute;
    if (attribute === "tags") return this.renderTags();
    else if (attribute === "ageId") return this.renderAge();
    else if (attribute === "languageId") return this.renderLanguage();
    else return this.renderRoles(attribute);
  };

  conditionLayout = (i) => {
    return (
      <FlexContentContainer>
        {i !== 0 && (
          <CustomForm.Item
            style={{ width: "23.2%" }}
            validateStatus={this.state.conditionsError[i] ? "error" : "success"}
            help={
              this.state.conditionsError[i]
                ? this.state.conditionsError[i]
                : null
            }
          >
            <Select
              placeholder="Relation"
              value={
                this.state.conditions[i].relation
                  ? this.state.conditions[i].relation
                  : undefined
              }
              defaultValue={"or"}
              onChange={this.handleConditions.bind(this, "relation", i)}
            >
              <Option key={1} value="or">
                Or
              </Option>
            </Select>
          </CustomForm.Item>
        )}
        <CustomForm.Item
          validateStatus={this.state.conditionsError[i] ? "error" : "success"}
          help={
            this.state.conditionsError[i] && i === 0
              ? this.state.conditionsError[i]
              : null
          }
          style={
            i === 0 ? { width: "31.5%" } : { width: "23.2%", marginLeft: 20 }
          }
        >
          <Select
            placeholder="Attribute"
            value={
              this.state.conditions[i].attribute
                ? this.state.conditions[i].attribute
                : undefined
            }
            onChange={this.handleConditions.bind(this, "attribute", i)}
          >
            <Option key={1} value="writer">
              Author
            </Option>
            <Option key={2} value="narrator">
              Narrator
            </Option>
            <Option key={3} value="publisher">
              Publisher
            </Option>
            <Option key={4} value="illustrator">
              Illustrator
            </Option>
            <Option key={5} value="tags">
              Tag
            </Option>
            <Option key={6} value="ageId">
              Age ID
            </Option>
            <Option key={6} value="languageId">
              Language
            </Option>
          </Select>
        </CustomForm.Item>
        <CustomForm.Item
          validateStatus={this.state.conditionsError[i] ? "error" : "success"}
          style={
            i === 0
              ? { width: "31.5%", marginLeft: 20 }
              : { width: "23.2%", marginLeft: 20 }
          }
        >
          <Select
            placeholder="Boolean"
            onChange={this.handleConditions.bind(this, "boolean", i)}
            value={
              this.state.conditions[i].boolean
                ? this.state.conditions[i].boolean
                : undefined
            }
            disabled={true}
            defaultValue={"is"}
          >
            <Option key={1} value="is">
              {this.state.conditions[i].attribute === "tags"
                ? "Contains"
                : "Is"}
            </Option>
          </Select>
        </CustomForm.Item>
        <CustomForm.Item
          validateStatus={this.state.conditionsError[i] ? "error" : "success"}
          style={
            i === 0
              ? { width: "31.5%", marginLeft: 20 }
              : { width: "23.2%", marginLeft: 20 }
          }
        >
          <Select
            placeholder="Query"
            value={
              this.state.conditions[i].query
                ? this.state.conditions[i].query
                : undefined
            }
            disabled={this.state.conditions[i].boolean ? false : true}
            onChange={this.handleConditions.bind(this, "query", i)}
          >
            {this.renderQuery(i)}
          </Select>
        </CustomForm.Item>
      </FlexContentContainer>
    );
  };

  renderDeleteButton = () => {
    if (this.state.function === "edit") {
      let staticCategories = [
        "categories_d6b5d461-5f75-4a4f-9bd2-f544eb996017",
        "categories_35051db0-f940-423d-ab0a-023083234771",
        "categories_a9b368f1-1c9e-49dd-98e5-85aa81fbcc02",
        "categories_c10d615e-db85-4784-912e-a1376cf53cd2",
        "categories_411f6ce4-8f18-4fea-b8ab-a5f6d5eca7d7",
        "categories_46258424-48d9-4786-9e33-1be364f9e0fb",
        "categories_79c1fb29-1872-46fa-812c-1f945fd853fa",
      ];
      if (!staticCategories.includes(this.state.selectedCategories.id))
        return (
          <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
            Delete
          </TopBarDeleteButton>
        );
    }
  };

  renderConditions = () => {
    let conditionView = [];
    for (let i = 0; i < this.state.conditionCount; i++)
      conditionView.push(
        <div key={i} style={{ marginBottom: 20 }}>
          {this.conditionLayout(i)}
        </div>
      );
    return conditionView;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "", null)} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "", null)}>
              Categories
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Categories"
                : "Creating Categories"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>
          <div style={{ flex: 1 }} />
          {this.renderDeleteButton()}
          <TopBarSaveButton onClick={this.handleSubmit}>Save</TopBarSaveButton>
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Categories</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleFunction.bind(this, "create", null)}
            style={{ left: 130 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderSoundFileName = () => {
    let soundFileName = "";
    if (this.state.selectedCategories.sound) soundFileName = "sound.mp3";
    else if (this.state.soundFile) soundFileName = this.state.soundFile.name;
    else soundFileName = "";

    return soundFileName;
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <CustomForm>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.avatarError ? "error" : "success"}
              help={this.state.avatarError ? this.state.avatarError : null}
              style={{ width: "48%" }}
            >
              <FormText>Avatar - 400px(w) x 400px(h) - 200KB</FormText>
              <CategoriesBackground>
                {this.state.function === "create" ? (
                  <>
                    {this.state.avatarUrl && this.state.avatarUrl.length > 0 ? (
                      <>
                        <CategoriesImage src={this.state.avatarUrl} />
                        <EditImageContainer>
                          <Upload
                            accept=".bmp, .jpeg, .jpg, .png"
                            onChange={this.handleChangeAvatar}
                            showUploadList={false}
                          >
                            <EditImageButton>Edit Image</EditImageButton>
                          </Upload>
                        </EditImageContainer>
                      </>
                    ) : (
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeAvatar}
                        showUploadList={false}
                      >
                        <EditImageButton
                          style={{ marginTop: 50, marginBottom: 50 }}
                        >
                          Upload an Image
                        </EditImageButton>
                      </Upload>
                    )}
                  </>
                ) : (
                  <>
                    <CategoriesImage
                      src={
                        this.state.avatarUrl && this.state.avatarUrl.length > 0
                          ? this.state.avatarUrl
                          : `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${this.state.selectedCategories.id}%2Favatar.png?alt=media&token=${this.state.selectedCategories.avatar}`
                      }
                    />
                    <EditImageContainer>
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeAvatar}
                        showUploadList={false}
                      >
                        <EditImageButton>Edit Image</EditImageButton>
                      </Upload>
                    </EditImageContainer>
                  </>
                )}
              </CategoriesBackground>
            </CustomForm.Item>
            <CustomForm.Item
              validateStatus={this.state.iconError ? "error" : "success"}
              help={this.state.iconError ? this.state.iconError : null}
              style={{ width: "48%", marginLeft: 20 }}
            >
              <FormText>Selection Icon - 400px(w) x 400px(h) - 200KB</FormText>
              <CategoriesBackground>
                {this.state.function === "create" ? (
                  <>
                    {this.state.iconUrl && this.state.iconUrl.length > 0 ? (
                      <>
                        <CategoriesImage src={this.state.iconUrl} />
                        <EditImageContainer>
                          <Upload
                            accept=".bmp, .jpeg, .jpg, .png"
                            onChange={this.handleChangeIcon}
                            showUploadList={false}
                          >
                            <EditImageButton>Edit Image</EditImageButton>
                          </Upload>
                        </EditImageContainer>
                      </>
                    ) : (
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeIcon}
                        showUploadList={false}
                      >
                        <EditImageButton
                          style={{ marginTop: 50, marginBottom: 50 }}
                        >
                          Upload an Image
                        </EditImageButton>
                      </Upload>
                    )}
                  </>
                ) : (
                  <>
                    <CategoriesImage
                      src={
                        this.state.iconUrl && this.state.iconUrl.length > 0
                          ? this.state.iconUrl
                          : `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${this.state.selectedCategories.id}%2Fselection_icon.png?alt=media&token=${this.state.selectedCategories.selectionIcon}`
                      }
                    />
                    <EditImageContainer>
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeIcon}
                        showUploadList={false}
                      >
                        <EditImageButton>Edit Image</EditImageButton>
                      </Upload>
                    </EditImageContainer>
                  </>
                )}
              </CategoriesBackground>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.soundError ? "error" : "success"}
              help={this.state.soundError ? this.state.soundError : null}
              style={{ width: "48%", marginTop: 20 }}
            >
              <FormText>Sound - 100KB</FormText>
              <CategoriesBackground style={{ flexDirection: "column" }}>
                <FormText
                  onClick={this.handlePlayUploadedSound}
                  style={{ color: "#0A84FF", cursor: "pointer" }}
                >
                  {this.renderSoundFileName()}
                </FormText>
                <Upload
                  accept=".mp3"
                  onChange={this.handleChangeSound}
                  showUploadList={false}
                >
                  <EditImageButton>
                    {this.state.selectedCategories.sound
                      ? "Edit Sound"
                      : "Upload Sound"}
                  </EditImageButton>
                </Upload>
              </CategoriesBackground>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer style={{ marginTop: 20 }}>
            <CustomForm.Item
              validateStatus={
                this.state.discoverNameError ? "error" : "success"
              }
              help={
                this.state.discoverNameError
                  ? this.state.discoverNameError
                  : null
              }
              style={{ width: "48%" }}
            >
              <FormText>Name</FormText>
              <Input
                id="discoverName"
                value={
                  this.state.selectedCategories.discoverName
                    ? this.state.selectedCategories.discoverName
                    : ""
                }
                onChange={this.handleChange}
                style={{ borderRadius: 10 }}
              />
            </CustomForm.Item>
            <CustomForm.Item
              validateStatus={this.state.descriptionError ? "error" : "success"}
              help={
                this.state.descriptionError ? this.state.descriptionError : null
              }
              style={{ width: "48%", marginLeft: 20 }}
            >
              <FormText>Description</FormText>
              <Input
                id="description"
                value={
                  this.state.selectedCategories.description
                    ? this.state.selectedCategories.description
                    : ""
                }
                onChange={this.handleChange}
                style={{ borderRadius: 10 }}
              />
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer style={{ marginTop: 20 }}>
            <CustomForm.Item
              validateStatus={this.state.priorityError ? "error" : "success"}
              help={this.state.priorityError ? this.state.priorityError : null}
              style={{ width: "48%" }}
            >
              <FormText>Priority</FormText>
              <Input
                id="priority"
                value={
                  this.state.selectedCategories.priority
                    ? this.state.selectedCategories.priority
                    : ""
                }
                onChange={this.handleChange}
                style={{ borderRadius: 10 }}
              />
            </CustomForm.Item>
            <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
              <FormText>Discover Scroll Preview</FormText>
              <Checkbox
                onChange={this.handleChecked}
                checked={this.state.selectedCategories.discoverShow}
                style={{ lineHeight: "40px" }}
              >
                Show In Discover Screen
              </Checkbox>
            </CustomForm.Item>
          </FlexContentContainer>

          {/* <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%", marginTop: 20 }}>
              <FormText>Status</FormText>
              <Checkbox
                onChange={this.handleChecked}
                checked={this.state.selectedCategories.show}
                style={{ lineHeight: "40px" }}
              >
                Show this category
              </Checkbox>
            </CustomForm.Item>
          </FlexContentContainer> */}
          {/* <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.homeNameError ? "error" : "success"}
              help={this.state.homeNameError ? this.state.homeNameError : null}
              style={{ width: "48%", marginTop: 20 }}
            >
              <FormText>Name (Home)</FormText>
              <Input
                id="homeName"
                value={
                  this.state.selectedCategories.homeName
                    ? this.state.selectedCategories.homeName
                    : ""
                }
                onChange={this.handleChange}
                style={{ borderRadius: 10 }}
                disabled={this.state.selectedCategories.show ? false : true}
              />
            </CustomForm.Item>
          </FlexContentContainer> */}
          <FlexContentContainer style={{ marginTop: 20 }}>
            <FormText>Conditions</FormText>
            {/* <AddConditionsIcon
              style={{ marginLeft: 20 }}
              onClick={this.handleConditions.bind(this, "add", null)}
            /> */}
            {this.state.conditionCount > 1 && (
              <MinusConditionsIcon
                style={{ marginLeft: 20 }}
                onClick={this.handleConditions.bind(this, "minus", null)}
              />
            )}
          </FlexContentContainer>
          {this.renderConditions()}
        </CustomForm>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderCategories()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.categories !== undefined && (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 50 >= this.props.categories.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          )}
        </>
      );
  };

  render() {
    return (
      <>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createCategories: (data) => dispatch(createCategories(data)),
    deleteCategories: (data) => dispatch(deleteCategories(data)),
    editCategories: (data) => dispatch(editCategories(data)),
  };
};

export default connect(null, mapDispatchToProps)(Categories);

const CategoriesImage = styled.img`
  height: 100px;
  width: 100px;
  margin: 10px;
`;

const CategoriesBackground = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 10px;
  background: rgb(251, 251, 255);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;
