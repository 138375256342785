const initState = {
  previousPage: [],
  currentPageLast: "",
  users: [],
  loading: false,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_USER_PAGE_QUERY":
      return {
        ...state,
        previousPage: action.payload.previousPage,
        currentPageLast: action.payload.currentPageLast,
        users: action.payload.users,
        loading: false,
      };
    case "UPDATE_USER":
      return {
        ...state,
        users: action.payload.users,
      };
    case "CLEAR_USER_PAGE_QUERY":
      return {
        ...state,
        previousPage: [],
        currentPageLast: "",
        users: [],
        loading: false,
      };
    case "LOAD_USER_DATA":
      return {
        ...state,
        loading: action.payload.loading,
        users: action.payload.loading ? [] : state.users,
      };
    default:
      return state;
  }
};

export default userReducer;
