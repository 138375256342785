import {
  Button,
  Checkbox,
  Input,
  Modal,
  Pagination,
  Select,
  TimePicker,
  Upload,
} from "antd";
//Utils
import EventBus from "js-event-bus";
import moment from "moment";
import React, { Component } from "react";
//Redux Firebase
import { connect } from "react-redux";
import styled from "styled-components";
//Action
import {
  deleteBook,
  editBook,
  updateBookAssets,
  uploadBook,
  uploadBookAudio,
} from "../../store/actions/bookAction";
import country from "../../utils/countryList";
import CountryModal from "../content/CountryModal";
import { DeleteDialog, SaveDialog } from "../Dialog";
//Components
import {
  AddConditionsIcon,
  BackButton,
  BreadcrumbActive,
  BreadcrumbLink,
  ContentContainer,
  CustomForm,
  EditImageButton,
  EditImageContainer,
  FlexContentContainer,
  FormText,
  MinusConditionsIcon,
  NavBar,
  TableContent,
  TableContentDefault,
  TableLayout,
  TopBar,
  TopBarBreadcrumb,
  TopBarDeleteButton,
  TopBarIcon,
  TopBarSaveButton,
  TopBarSearch,
  TopBarUpdateButton,
} from "./Layout";

const { Option } = Select;
const { TextArea } = Input;

const booksColumns = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Books",
    dataIndex: "books",
    key: "books",
  },
  {
    title: "Audio",
    dataIndex: "audio",
    key: "audio",
  },
  {
    title: "Pages",
    dataIndex: "pages",
    key: "pages",
  },
];

const audioColumns = [
  {
    title: "Audio Name",
    dataIndex: "name",
    key: "name",
  },
];

const eventBus = EventBus();

let pageAudio = new Audio();

class Books extends Component {
  state = {
    count: 1,
    function: "",
    search: "",
    selectedBook: null,
    descriptionError: "",
    pricingTypeError: "",
    titleError: "",
    tagSelection: [],
    page: 1,
    imageFile: "",
    imageUrl: "",
    backgroundImageFile: [],
    backgroundImageUrl: "",
    backgroundImageFileError: "",
    pointsError: [],
    points: [],
    editImage: false,
    editBackground: false,
    deleteModal: false,
    uploadModal: false,
    countryModal: false,
    audioModal: false,
    uploadClass: {
      bookName: "",
      bookFile: "",
      bookFileName: "",
    },
    uploadClassError: {
      bookName: "",
      bookFile: "",
    },
    uploadAudio: {
      audioFile: "",
      audioFileName: "",
    },
    uploadAudioError: {
      audioFile: "",
    },
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.tags !== this.props.tags &&
      this.props.tags &&
      Object.keys(this.state.tagSelection).length === 0
    ) {
      let tagList = [];
      this.props.tags.map((eachTag, index) => {
        tagList.push(
          <Option key={index} value={eachTag.id}>
            {eachTag.title}
          </Option>
        );
      });

      this.setState({ tagSelection: tagList });
    }
  }

  componentDidMount() {
    eventBus.on("editBook-function-success", this.handleClearState);
    eventBus.on("editBook-function-error", this.handleError);
    eventBus.on("uploadBook-function-success", this.handleUploadSuccess);
    eventBus.on("uploadBook-function-error", this.handleUploadError);
  }

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("editBook-function-success");
    eventBus.detach("editBook-function-error");
    eventBus.detach("uploadBook-function-success");
    eventBus.detach("uploadBook-function-error");
  }

  handleError = (err) => {
    this.setState(
      {
        descriptionError: err,
        titleError: err,
        backgroundImageFileError: err,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleUploadSuccess = () => {
    this.setState(
      {
        loading: false,
      },
      () => {
        this.handleBookDialog(false);
        this.handleAudioDialog(false);
        this.handleClearState();
      }
    );
  };

  handleUploadError = (err) => {
    window.alert(err);
    this.setState(
      {
        loading: false,
      },
      () => {
        this.handleBookDialog(false);
        this.handleAudioDialog(false);
      }
    );
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteBook(this.state.selectedBook.id);
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        selectedBook: "",
        descriptionError: "",
        pricingTypeError: "",
        titleError: "",
        imageFile: "",
        imageUrl: "",
        backgroundImageFile: [],
        backgroundImageUrl: "",
        backgroundImageFileError: "",
        pointsError: [],
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, bookData) => {
    let tempData = "";
    let tempPoints = [];

    if (type === "edit") {
      tempData = JSON.parse(JSON.stringify(bookData));
      //INFO : Check Points
      if (
        bookData.points &&
        bookData.points.length > 0 &&
        !bookData.points[0].conditions
      )
        tempPoints = JSON.parse(JSON.stringify(bookData.points));
      else
        tempPoints = [
          {
            attribute: "",
            boolean: "moreThan",
            query: "",
            reward: "",
          },
        ];
    }
    this.handleClearState();
    this.setState(
      {
        function: type,
        selectedBook: tempData,
        points: tempPoints,
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handleChange = (event) => {
    let eventId = event.target.id;
    let tempBook = this.state.selectedBook;
    tempBook[eventId] = event.target.value;

    this.setState(
      {
        selectedBook: tempBook,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleChangeUploadName = (event) => {
    let tempUploadClass = this.state.uploadClass;
    tempUploadClass["bookName"] = event.target.value;
    this.setState({
      uploadClass: tempUploadClass,
    });
  };

  handleChangeZip = (event) => {
    let newFile = event.file.originFileObj;
    let tempUploadClass = this.state.uploadClass;
    tempUploadClass["bookFileName"] = event.file.name;
    tempUploadClass["bookFile"] = newFile;

    this.setState({
      uploadClass: tempUploadClass,
    });
  };

  handleUploadMp3 = () => {
    let tempUploadAudioError = this.state.uploadAudioError;
    if (!this.state.uploadAudio.audioFile)
      tempUploadAudioError["audioFile"] = "Please upload a .mp3 file";
    else tempUploadAudioError["audioFile"] = "";

    this.setState(
      {
        uploadAudioError: tempUploadAudioError,
      },
      () => {
        if (!this.state.uploadAudioError["audioFile"]) {
          this.setState({
            loading: true,
          });
          this.props.uploadBookAudio(
            this.state.uploadAudio,
            this.state.selectedBook.id
          );
        }
      }
    );
  };

  handleAddMp3 = (event) => {
    let newFile = event.file.originFileObj;
    let tempUploadClass = this.state.uploadAudio;
    tempUploadClass["audioFileName"] = event.file.name;
    tempUploadClass["audioFile"] = newFile;

    this.setState({
      uploadAudio: tempUploadClass,
    });
  };

  handleDropdown = (id, value) => {
    let tempBook = this.state.selectedBook;
    tempBook[id] = value;
    this.setState(
      {
        selectedBook: tempBook,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleConditions = (type, num, value) => {
    let tempPoints = this.state.points;
    let tempError = this.state.pointsError;

    if (type === "add") {
      tempPoints.push({
        attribute: "",
        boolean: "moreThan",
        query: "",
        reward: "",
      });
      tempError.push("");
      this.setState({
        points: tempPoints,
        tempError: tempError,
      });
    } else if (type === "minus") {
      tempPoints.pop();
      tempError.pop();
      this.setState({
        points: tempPoints,
        tempError: tempError,
      });
    } else {
      tempPoints[num][type] = value;
      if (type === "attribute" && value === "reading")
        tempPoints[num]["query"] = "Ave.Time";
      this.setState({ points: tempPoints });
    }
  };

  handleSubmit = () => {
    this.props.handleLoading(true);
    let hasBackground = false;
    if (this.state.selectedBook.backgroundImage) hasBackground = true;
    else hasBackground = false;

    if (this.state.selectedBook.title < 1)
      this.setState({ titleError: "Please insert a title" });
    else this.setState({ titleError: "" });

    if (this.state.selectedBook.description.length < 1)
      this.setState({ descriptionError: "Please insert a description" });
    else this.setState({ descriptionError: "" });

    if (this.state.selectedBook.pricingType.length < 1)
      this.setState({ pricingTypeError: "Please insert a pricing type" });
    else this.setState({ pricingTypeError: "" });

    let insertPoint = true;
    let ignoringPoints = false;
    let pointsLength = this.state.points.length;

    if (pointsLength === 1 && !this.state.points[0].attribute)
      ignoringPoints = true;

    if (!ignoringPoints) {
      for (let i = 0; i < pointsLength; i++) {
        let tempPoints = this.state.points[i];
        let tempError = this.state.pointsError;
        if (!tempPoints.attribute) tempError[i] = "Please select an attribute";
        else if (tempPoints.attribute === "narration") {
          if (!tempPoints.query) tempError[i] = "Please insert a query";
          else if (isNaN(tempPoints.query))
            tempError[i] = "Please insert digit only in query";
        } else if (!tempPoints.reward) tempError[i] = "Please insert a reward";
        else tempError[i] = "";
        this.setState({ pointsError: tempError });
      }

      for (let i = 0; i < this.state.pointsError.length; i++)
        if (this.state.pointsError[i]) insertPoint = false;

      if (insertPoint) {
        let tempBook = this.state.selectedBook;
        tempBook.points = this.state.points;
        this.setState({ selectedBook: tempBook });
      }
    } else {
      let tempBook = this.state.selectedBook;
      tempBook.points = [
        {
          attribute: "",
          boolean: "moreThan",
          query: "",
          reward: "",
        },
      ];
      this.setState({ selectedBook: tempBook });
    }

    setTimeout(() => {
      if (
        (ignoringPoints || insertPoint) &&
        !this.state.descriptionError &&
        !this.state.titleError &&
        !this.state.backgroundImageFileError &&
        !this.state.pricingTypeError
      ) {
        if (this.state.function === "edit") {
          this.props.editBook({
            hasBackground: hasBackground,
            selectedBook: this.state.selectedBook,
            imageFile: this.state.imageFile,
            backgroundImageFile: this.state.backgroundImageFile,
          });
        }
        this.props.handleDataChanged(false);
      } else this.props.handleLoading(false);
    }, 500);
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  handlePlayAudio = (audioUrl) => {
    pageAudio.pause();
    pageAudio = new Audio(audioUrl);
    pageAudio.play();
  };

  handleChangeImage = (event) => {
    let newFile = event.file.originFileObj;
    Object.defineProperty(newFile, "name", {
      writable: true,
      value: "previewImage.png",
    });

    this.getBase64(newFile, (imageUrl) =>
      this.setState(
        {
          imageUrl,
          imageFile: newFile,
        },
        () => {
          this.props.handleDataChanged(true);
        }
      )
    );
  };

  handleChangeBackgroundImage = (event) => {
    let newFile = event.file.originFileObj;
    Object.defineProperty(newFile, "name", {
      writable: true,
      value: "backgroundImage.png",
    });

    this.getBase64(newFile, (imageUrl) =>
      this.setState(
        {
          backgroundImageUrl: imageUrl,
          backgroundImageFile: newFile,
        },
        () => {
          this.props.handleDataChanged(true);
        }
      )
    );
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleReadingTime = (time, timeString) => {
    let tempBook = this.state.selectedBook;
    tempBook["averageTime"] = timeString;
    this.setState({ selectedBook: tempBook }, () => {
      this.props.handleDataChanged(true);
    });
  };

  handleRoles = (type) => {
    let authorList = [];
    let publisherList = [];
    let narratorList = [];
    let illustratorList = [];

    if (this.props.roles && this.props.roles.length > 0) {
      this.props.roles.map((eachRole, index) => {
        eachRole.roles.map((eachIndividualRole) => {
          switch (eachIndividualRole) {
            case "Writer":
              authorList.push(
                <Option key={index} value={eachRole.id}>
                  {eachRole.name}
                </Option>
              );
              break;
            case "Publisher":
              publisherList.push(
                <Option key={index} value={eachRole.id}>
                  {eachRole.name}
                </Option>
              );
              break;
            case "Illustrator":
              illustratorList.push(
                <Option key={index} value={eachRole.id}>
                  {eachRole.name}
                </Option>
              );
              break;
            case "Narrator":
              narratorList.push(
                <Option key={index} value={eachRole.id}>
                  {eachRole.name}
                </Option>
              );
              break;
          }
        });
      });
    }

    if (type === "author") return authorList;
    else if (type === "publisher") return publisherList;
    else if (type === "narrator") return narratorList;
    else if (type === "illustrator") return illustratorList;
  };

  handleStickers = (attribute) => {
    let stickersList = [];

    if (this.props.stickers && this.props.stickers.length > 0)
      this.props.stickers.map((eachSticker, index) => {
        if (attribute === eachSticker.type)
          stickersList.push(
            <Option key={index + 1} value={eachSticker.id}>
              {eachSticker.name}
            </Option>
          );
      });
    return stickersList;
  };

  handleUpdateCountry = (selectedBook) => {
    this.setState({
      selectedBook: selectedBook,
    });
  };

  handleBookDialog = (type) => {
    if (!this.state.loading)
      this.setState({
        loading: false,
        uploadModal: type,
        uploadClass: {
          bookName: "",
          bookFile: "",
          bookFileName: "",
        },
        uploadClassError: {
          bookName: "",
          bookFile: "",
        },
      });
  };

  handleAudioDialog = (bool) => {
    if (!this.state.loading)
      this.setState({
        loading: false,
        audioModal: bool,
        uploadAudio: {
          audioFile: "",
          audioFileName: "",
        },
        uploadAudioError: {
          audioFile: "",
        },
      });
  };

  handleUploadBook = () => {
    let tempUploadClassError = this.state.uploadClassError;
    //INFO : Check prefix condition
    if (this.state.uploadClass.bookName.length <= 0)
      tempUploadClassError["bookName"] = "Please insert the book name";
    else tempUploadClassError["bookName"] = "";

    if (!this.state.uploadClass.bookFile)
      tempUploadClassError["bookFile"] = "Please upload a zip file of the book";
    else tempUploadClassError["bookFile"] = "";

    this.setState(
      {
        uploadClassError: tempUploadClassError,
      },
      () => {
        if (
          !this.state.uploadClassError["bookName"] &&
          !this.state.uploadClassError["bookFile"]
        ) {
          this.setState({
            loading: true,
          });
          this.props.uploadBook(this.state.uploadClass);
        }
      }
    );
  };

  handleUpdateAssets = () => {
    let tempUploadClassError = this.state.uploadClassError;

    if (!this.state.uploadClass.bookFile)
      tempUploadClassError["bookFile"] = "Please upload a zip file of the book";
    else tempUploadClassError["bookFile"] = "";

    this.setState(
      {
        uploadClassError: tempUploadClassError,
      },
      () => {
        if (!this.state.uploadClassError["bookFile"]) {
          this.setState({
            loading: true,
          });
          this.props.updateBookAssets(
            this.state.uploadClass,
            this.state.selectedBook.id
          );
        }
      }
    );
  };

  handlePublishStatus = (event) => {
    let tempBook = this.state.selectedBook;
    tempBook.published = event.target.checked;
    this.setState({ selectedBook: tempBook });
    this.props.handleDataChanged(true);
  };

  handleCountryDialog = (type) => {
    this.setState({
      countryModal: type,
    });
  };

  renderCountry = () => {
    let countryView = [];
    country.map((eachCountry, index) => {
      countryView.push(
        <TableContent key={index + 1} value={eachCountry.value}>
          {eachCountry.label}
        </TableContent>
      );
    });
    return countryView;
  };

  renderPricingType = () => {
    let pricingType = ["Free", "Subscription", "Perpetual"];
    let methodList = [];
    pricingType.map((eachType, index) => {
      methodList.push(
        <Option key={index + 1} value={eachType}>
          {eachType}
        </Option>
      );
    });
    return methodList;
  };

  renderBlockedCountries = (type) => {
    if (type) {
      if (
        this.state.selectedBook.pricingType &&
        (this.state.selectedBook.pricingType.includes("Perpetual") ||
          this.state.selectedBook.pricingType.includes("Subscription"))
      ) {
        let renderBlockedCountriesComponents = [];
        let addMargin = false;

        if (this.state.selectedBook.pricingType.includes("Subscription")) {
          addMargin = true;
          renderBlockedCountriesComponents.push(
            <CustomForm.Item style={{ width: "48%" }} key={1}>
              <RowContainer>
                <FormText>Allowed Countries - Subscription</FormText>
                <EditText
                  onClick={this.handleCountryDialog.bind(this, "subscription")}
                >
                  Edit
                </EditText>
              </RowContainer>
              <Select
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "subscriptionCountry")}
                placeholder="Subscription Allowed Country"
                style={{ width: "100%", marginBottom: 20 }}
                value={
                  this.state.selectedBook.subscriptionCountry
                    ? this.state.selectedBook.subscriptionCountry
                    : undefined
                }
              >
                {this.renderCountry()}
              </Select>
            </CustomForm.Item>
          );
        }
        if (this.state.selectedBook.pricingType.includes("Perpetual"))
          renderBlockedCountriesComponents.push(
            <CustomForm.Item
              style={{ width: "48%", marginLeft: addMargin ? 20 : 0 }}
            >
              <RowContainer>
                <FormText>Allowed Countries - Perpetual</FormText>
                <EditText
                  onClick={this.handleCountryDialog.bind(this, "perpetual")}
                >
                  Edit
                </EditText>
              </RowContainer>
              <Select
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "perpetualCountry")}
                placeholder="Perpetual Allowed Country"
                style={{ width: "100%", marginBottom: 20 }}
                value={
                  this.state.selectedBook.perpetualCountry
                    ? this.state.selectedBook.perpetualCountry
                    : undefined
                }
              >
                {this.renderCountry()}
              </Select>
            </CustomForm.Item>
          );

        return (
          <FlexContentContainer>
            {renderBlockedCountriesComponents}
          </FlexContentContainer>
        );
      }
    } else {
      if (
        this.state.selectedBook.pricingType &&
        this.state.selectedBook.pricingType.includes("Free")
      )
        return (
          <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
            <RowContainer>
              <FormText>Allowed Countries - Free</FormText>
              <EditText onClick={this.handleCountryDialog.bind(this, "free")}>
                Edit
              </EditText>
            </RowContainer>
            <Select
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
              onChange={this.handleDropdown.bind(this, "country")}
              placeholder="Allowed Countries"
              style={{ width: "100%", marginBottom: 20 }}
              value={
                this.state.selectedBook.country
                  ? this.state.selectedBook.country
                  : undefined
              }
            >
              {this.renderCountry()}
            </Select>
          </CustomForm.Item>
        );
    }
  };

  renderAudio = (type) => {
    let audioView = [];
    let soundList = [];
    if (
      this.state.selectedBook &&
      this.state.selectedBook.audioName.length > 0 &&
      this.state.selectedBook.audio.length > 0
    ) {
      for (let i = 0; i < this.state.selectedBook.audio.length; i++) {
        let audioName = this.state.selectedBook.audioName[i]
          .split(" ")
          .join("%20");
        let audioUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.state.selectedBook.id}%2Fcontent%2F${audioName}?alt=media&token=${this.state.selectedBook.audio[i]}`;

        audioView.push({
          key: i,
          name: (
            <TableContent onClick={this.handlePlayAudio.bind(this, audioUrl)}>
              {this.state.selectedBook.audioName[i]}
            </TableContent>
          ),
        });
        soundList.push(
          <Option key={i} value={this.state.selectedBook.audioName[i]}>
            {this.state.selectedBook.audioName[i]}
          </Option>
        );
      }
    }
    if (type === "table") return audioView;
    else if (type === "dropdown") return soundList;
  };

  renderPages = () => {
    let pagesView = [];
    if (this.state.selectedBook && this.state.selectedBook.pages.length > 0) {
      for (let i = 0; i < this.state.selectedBook.pages.length; i++) {
        if (i >= 10) continue;

        let pageName = this.state.selectedBook.pagesName[i]
          .split(" ")
          .join("%20");
        let imageUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.state.selectedBook.id}%2Fcontent%2F${pageName}?alt=media&token=${this.state.selectedBook.pages[i]}`;

        pagesView.push(<PageImage key={i} src={imageUrl} />);
      }
    }
    return pagesView;
  };

  renderLanguageID = () => {
    if (Number(this.state.selectedBook.languageId) === 1) return "1";
    else if (Number(this.state.selectedBook.languageId) === 2) return "2";
    else if (Number(this.state.selectedBook.languageId) === 3) return "3";
    else if (Number(this.state.selectedBook.languageId) === 4) return "4";
    else if (Number(this.state.selectedBook.languageId) === 5) return "5";
  };

  renderConditionLayout = (i) => {
    return (
      <div
        style={{
          marginTop: 10,
          flexDirection: "row",
          display: "flex",
          width: "100%",
        }}
      >
        <CustomForm.Item
          validateStatus={this.state.pointsError[i] ? "error" : "success"}
          help={this.state.pointsError[i] ? this.state.pointsError[i] : null}
          style={{ width: "100%", marginRight: 10 }}
        >
          <Select
            placeholder="Attribute"
            value={
              this.state.points[i].attribute
                ? this.state.points[i].attribute
                : undefined
            }
            onChange={this.handleConditions.bind(this, "attribute", i)}
          >
            <Option key={1} value="narration">
              Narrate
            </Option>
            <Option key={2} value="reading">
              Read
            </Option>
            <Option key={3} value="">
              No Action
            </Option>
          </Select>
        </CustomForm.Item>
        <CustomForm.Item
          validateStatus={this.state.pointsError[i] ? "error" : "success"}
          style={{ width: "100%", marginRight: 10 }}
        >
          <Select
            placeholder="Boolean"
            disabled={true}
            value={
              this.state.points[i].boolean
                ? this.state.points[i].boolean
                : undefined
            }
          >
            <Option key={1} value={"moreThan"}>
              More Than
            </Option>
          </Select>
        </CustomForm.Item>
        <CustomForm.Item
          validateStatus={this.state.pointsError[i] ? "error" : "success"}
          style={{ width: "100%", marginRight: 10 }}
        >
          <Input
            placeholder="Query"
            id="query"
            style={{ borderRadius: 10 }}
            value={this.state.points[i].query && this.state.points[i].query}
            onChange={(e) =>
              this.handleConditions(e.target.id, i, e.target.value)
            }
            disabled={
              this.state.points[i].attribute &&
              this.state.points[i].attribute !== "reading"
                ? false
                : true
            }
          />
        </CustomForm.Item>
        <CustomForm.Item
          validateStatus={this.state.pointsError[i] ? "error" : "success"}
          style={{ width: "100%" }}
        >
          <Select
            disabled={
              !this.state.points[i].query || !this.state.points[i].attribute
            }
            placeholder="Rewards"
            value={
              this.state.points[i].reward
                ? this.state.points[i].reward
                : undefined
            }
            onChange={this.handleConditions.bind(this, "reward", i)}
          >
            {this.handleStickers(this.state.points[i].attribute)}
          </Select>
        </CustomForm.Item>
      </div>
    );
  };

  renderConditions = () => {
    let conditionView = [];
    for (let i = 0; i < this.state.points.length; i++)
      conditionView.push(
        <FlexContentContainer key={i} style={{ width: "98%" }}>
          {this.renderConditionLayout(i)}
        </FlexContentContainer>
      );
    return conditionView;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "", null)} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "", null)}>
              Books
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Books"
                : "Creating Books"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>
          <div style={{ flex: 1 }} />
          <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
            Delete
          </TopBarDeleteButton>
          <TopBarUpdateButton
            onClick={this.handleBookDialog.bind(this, "update")}
          >
            Update Assets
          </TopBarUpdateButton>
          <TopBarSaveButton onClick={this.handleSubmit}>Save</TopBarSaveButton>
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Books</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleBookDialog.bind(this, "upload")}
            style={{ left: 85 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <CustomForm>
          <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Book Image - Auto(w) x 400px(h) - 200KB</FormText>
              <BookImageBorder>
                <BookImage
                  id="image"
                  src={
                    this.state.imageUrl && this.state.imageUrl.length > 0
                      ? this.state.imageUrl
                      : `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.state.selectedBook.id}%2FpreviewImage.png?alt=media&token=${this.state.selectedBook.image}`
                  }
                />
                <EditImageContainer>
                  <Upload
                    accept=".bmp, .jpeg, .jpg, .png"
                    onChange={this.handleChangeImage}
                    showUploadList={false}
                  >
                    <EditImageButton>Edit Image</EditImageButton>
                  </Upload>
                </EditImageContainer>
              </BookImageBorder>
            </CustomForm.Item>
            <CustomForm.Item style={{ marginLeft: 20, width: "48%" }}>
              <FormText>Book Background - Auto(w) x 700px(h) - 400KB</FormText>
              <BookImageBorder>
                {this.state.selectedBook.backgroundImage ? (
                  <>
                    <BookImage
                      id="backgroundImage"
                      src={
                        this.state.backgroundImageUrl &&
                        this.state.backgroundImageUrl.length > 0
                          ? this.state.backgroundImageUrl
                          : `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.state.selectedBook.id}%2FbackgroundImage.png?alt=media&token=${this.state.selectedBook.backgroundImage}`
                      }
                    />
                    <EditImageContainer>
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeBackgroundImage}
                        showUploadList={false}
                      >
                        <EditImageButton>Edit Image</EditImageButton>
                      </Upload>
                    </EditImageContainer>
                  </>
                ) : (
                  <>
                    {this.state.backgroundImageUrl &&
                    this.state.backgroundImageUrl.length > 0 ? (
                      <>
                        <BookImage
                          id="backgroundImage"
                          src={this.state.backgroundImageUrl}
                        />
                        <EditImageContainer>
                          <Upload
                            accept=".bmp, .jpeg, .jpg, .png"
                            onChange={this.handleChangeBackgroundImage}
                            showUploadList={false}
                          >
                            <EditImageButton>Edit Image</EditImageButton>
                          </Upload>
                        </EditImageContainer>
                      </>
                    ) : (
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeBackgroundImage}
                        showUploadList={false}
                      >
                        <EditImageButton
                          style={{ marginTop: 80, marginBottom: 80 }}
                        >
                          Upload an Image
                        </EditImageButton>
                      </Upload>
                    )}
                  </>
                )}
              </BookImageBorder>
            </CustomForm.Item>
          </FlexContentContainer>
          <DividerTitleContainer>
            <DividerTitle>Book Attributes</DividerTitle>
          </DividerTitleContainer>

          <FlexContentContainer style={{ marginTop: 20 }}>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Book Id</FormText>
              <Input
                id="id"
                value={
                  this.state.selectedBook ? this.state.selectedBook.id : null
                }
                disabled
                style={{ marginBottom: 20, borderRadius: 10 }}
              />
            </CustomForm.Item>
            <CustomForm.Item
              style={{ width: "48%", marginLeft: 20 }}
              validateStatus={this.state.titleError ? "error" : "success"}
              help={this.state.titleError ? this.state.titleError : null}
            >
              <FormText>Book Name</FormText>
              <Input
                id="title"
                value={
                  this.state.selectedBook ? this.state.selectedBook.title : null
                }
                onChange={this.handleChange}
                style={{ marginBottom: 20, borderRadius: 10 }}
              />
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Book Product Id</FormText>
              <Input
                id="id"
                value={
                  this.state.selectedBook
                    ? "co.mebooks.plus.book." +
                      this.state.selectedBook.id.toLowerCase()
                    : null
                }
                disabled
                style={{ marginBottom: 20, borderRadius: 10 }}
              />
            </CustomForm.Item>
            <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
              <FormText>Recommended Age</FormText>
              <Select
                onChange={this.handleDropdown.bind(this, "ageId")}
                placeholder="Recommended Age"
                style={{ width: "100%", marginBottom: 20 }}
                value={this.state.selectedBook && this.state.selectedBook.ageId}
              >
                <Option key={1} value="1">
                  Below 3 years old
                </Option>
                <Option key={2} value="2">
                  4-6 years old
                </Option>
                <Option key={4} value="3">
                  7-9 years old
                </Option>
                <Option key={5} value="4">
                  Above 9 years old
                </Option>
              </Select>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Book Language</FormText>
              <Select
                onChange={this.handleDropdown.bind(this, "languageId")}
                placeholder="Book Language"
                style={{ width: "100%", marginBottom: 20 }}
                value={this.renderLanguageID()}
              >
                <Option key={1} value="1">
                  English (US)
                </Option>
                <Option key={2} value="2">
                  English (UK)
                </Option>
                <Option key={3} value="3">
                  Arabic
                </Option>
                <Option key={4} value="4">
                  Bahasa Malaysia
                </Option>
                <Option key={5} value="5">
                  Chinese
                </Option>
              </Select>
            </CustomForm.Item>
            <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
              <FormText>Tap Sound</FormText>
              <Select
                mode="multiple"
                onChange={this.handleDropdown.bind(this, "sound")}
                placeholder="Tap Sound"
                style={{ width: "100%" }}
                value={
                  this.state.selectedBook.sound
                    ? this.state.selectedBook.sound
                    : undefined
                }
              >
                {this.renderAudio("dropdown")}
              </Select>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Average Reading Time</FormText>
              <TimePicker
                style={{ borderRadius: 10, height: 40, width: "100%" }}
                onChange={this.handleReadingTime}
                value={
                  this.state.selectedBook.averageTime
                    ? moment(this.state.selectedBook.averageTime, "HH:mm:ss")
                    : moment("00:00:00", "HH:mm:ss")
                }
              />
            </CustomForm.Item>
          </FlexContentContainer>
          <CustomForm.Item
            validateStatus={this.state.descriptionError ? "error" : "success"}
            help={
              this.state.descriptionError ? this.state.descriptionError : null
            }
            style={{ width: "98%", marginTop: 20 }}
          >
            <FormText>Book Description</FormText>
            <TextArea
              id="description"
              rows={4}
              value={
                this.state.selectedBook
                  ? this.state.selectedBook.description
                  : null
              }
              onChange={this.handleChange}
              style={{ borderRadius: 10 }}
            />
          </CustomForm.Item>
          <DividerTitleContainer>
            <DividerTitle>Book Organisations</DividerTitle>
          </DividerTitleContainer>
          <FlexContentContainer style={{ marginTop: 20 }}>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Book Author</FormText>
              <Select
                mode="multiple"
                placeholder="Book Author"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "writer")}
                style={{ width: "100%", borderRadius: 10, marginBottom: 20 }}
                value={
                  this.state.selectedBook.writer
                    ? this.state.selectedBook.writer
                    : undefined
                }
              >
                {this.handleRoles("author")}
              </Select>
            </CustomForm.Item>
            <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
              <FormText>Book Publisher</FormText>
              <Select
                mode="multiple"
                placeholder="Book Publisher"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "publisher")}
                style={{ width: "100%", borderRadius: 10, marginBottom: 20 }}
                value={
                  this.state.selectedBook.publisher
                    ? this.state.selectedBook.publisher
                    : undefined
                }
              >
                {this.handleRoles("publisher")}
              </Select>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Book Narrator</FormText>
              <Select
                mode="multiple"
                placeholder="Book Narrator"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "narrator")}
                style={{ width: "100%", borderRadius: 10, marginBottom: 20 }}
                value={
                  this.state.selectedBook.narrator
                    ? this.state.selectedBook.narrator
                    : undefined
                }
              >
                {this.handleRoles("narrator")}
              </Select>
            </CustomForm.Item>
            <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
              <FormText>Book Illustrator</FormText>
              <Select
                mode="multiple"
                placeholder="Book Illustrator"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "illustrator")}
                style={{ width: "100%", borderRadius: 10, marginBottom: 20 }}
                value={
                  this.state.selectedBook.illustrator
                    ? this.state.selectedBook.illustrator
                    : undefined
                }
              >
                {this.handleRoles("illustrator")}
              </Select>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Book Tag</FormText>
              <Select
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "tags")}
                placeholder="Book Tags"
                style={{ width: "100%", borderRadius: 10 }}
                value={
                  this.state.selectedBook
                    ? this.state.selectedBook.tags
                    : undefined
                }
              >
                {this.state.tagSelection}
              </Select>
            </CustomForm.Item>
          </FlexContentContainer>

          <DividerTitleContainer>
            <DividerTitle>Book Pricing</DividerTitle>
          </DividerTitleContainer>

          <FlexContentContainer style={{ marginTop: 20 }}>
            <CustomForm.Item
              style={{ width: "48%" }}
              validateStatus={this.state.pricingTypeError ? "error" : "success"}
              help={
                this.state.pricingTypeError ? this.state.pricingTypeError : null
              }
            >
              <FormText>Pricing Type</FormText>
              <Select
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "pricingType")}
                placeholder="Pricing Type"
                style={{ width: "100%", marginBottom: 20 }}
                value={
                  this.state.selectedBook.pricingType
                    ? this.state.selectedBook.pricingType
                    : undefined
                }
              >
                {this.renderPricingType()}
              </Select>
            </CustomForm.Item>
            {this.renderBlockedCountries()}
          </FlexContentContainer>

          {this.renderBlockedCountries("type")}
          <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Publishing Status</FormText>
              <Checkbox
                onChange={this.handlePublishStatus}
                checked={this.state.selectedBook.published}
              >
                Published
              </Checkbox>
            </CustomForm.Item>
          </FlexContentContainer>

          <DividerTitleContainer>
            <DividerTitle>Book Rewards</DividerTitle>
          </DividerTitleContainer>

          <FlexContentContainer style={{ marginTop: 20 }}>
            <FormText>Points</FormText>
            {this.state.points.length < 2 && (
              <AddConditionsIcon
                style={{ marginLeft: 15 }}
                onClick={this.handleConditions.bind(this, "add", null)}
              />
            )}

            {this.state.points.length > 1 && (
              <MinusConditionsIcon
                style={{ marginLeft: 15 }}
                onClick={this.handleConditions.bind(this, "minus", null)}
              />
            )}
          </FlexContentContainer>
          {this.renderConditions()}

          <DividerTitleContainer>
            <DividerTitle>Book Preview</DividerTitle>
          </DividerTitleContainer>

          <FlexContentContainer style={{ width: "100%", marginTop: 20 }}>
            <FormText style={{ marginTop: 20 }}>
              Book Pages (Total : {this.state.selectedBook.pages.length}) -
              Showing 10 pages
            </FormText>
          </FlexContentContainer>
          <ImageContainer style={{ marginLeft: 0 }}>
            {this.renderPages()}
          </ImageContainer>
          <FlexContentContainer style={{ width: "100%" }}>
            <RowContainer style={{ marginTop: 20 }}>
              <FormText>
                Book Audio (Total : {this.state.selectedBook.audio.length})
              </FormText>
              <EditText onClick={this.handleAudioDialog.bind(this, true)}>
                Add Audio
              </EditText>
            </RowContainer>
          </FlexContentContainer>
          <TableLayout
            style={{ marginTop: "0px", marginLeft: 0 }}
            columns={audioColumns}
            dataSource={this.renderAudio("table")}
            pagination={false}
            scroll={{ y: window.innerHeight - 300 }}
          />
        </CustomForm>
      );
    else
      return (
        <>
          <TableLayout
            columns={booksColumns}
            dataSource={this.renderBooks()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.books !== undefined && (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 50 >= this.props.books.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          )}
        </>
      );
  };

  renderBooks = () => {
    let bookView = [];
    let books = [];

    if (this.props.books && this.props.books.length > 0) {
      let min = (this.state.page - 1) * 50;
      let max =
        this.state.page * 50 > this.props.books.length
          ? this.props.books.length
          : this.state.page * 50;
      if (this.state.search) books = this.props.books;
      else books = this.props.books.slice(min, max);

      books.map((eachBook, index) => {
        if (
          eachBook.title &&
          eachBook.title
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        )
          bookView.push({
            key: index,
            image: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachBook)}
              >
                <SmallImage
                  src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${eachBook.id}%2FpreviewImage.png?alt=media&token=${eachBook.image}`}
                />
              </TableContent>
            ),
            books: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachBook)}
              >
                {eachBook.title}
              </TableContent>
            ),
            audio: (
              <TableContentDefault
                onClick={this.handleFunction.bind(this, "edit", eachBook)}
              >
                {eachBook.audio.length}
              </TableContentDefault>
            ),
            pages: (
              <TableContentDefault
                onClick={this.handleFunction.bind(this, "edit", eachBook)}
              >
                {eachBook.pages.length}
              </TableContentDefault>
            ),
          });
      });
    }

    return bookView;
  };

  renderAudioModal = () => {
    if (this.state.loading)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: 20,
          }}
        >
          <FormText>Uploading... </FormText>
          <FormText>Please do not close the browser</FormText>
        </div>
      );
    else
      return (
        <>
          <CustomForm style={{ marginTop: 0, marginBottom: 0 }}>
            <CustomForm.Item
              style={{ marginTop: 20 }}
              validateStatus={
                this.state.uploadAudioError["audioFile"] ? "error" : "success"
              }
              help={
                this.state.uploadAudioError["audioFile"]
                  ? this.state.uploadAudioError["audioFile"]
                  : null
              }
            >
              <FormText>Upload .mp3 File</FormText>
              <ZipBackground style={{ flexDirection: "column" }}>
                {this.state.uploadAudio.audioFile ? (
                  <FormText>{this.state.uploadAudio.audioFileName}</FormText>
                ) : (
                  <Upload
                    accept=".mp3"
                    onChange={this.handleAddMp3}
                    showUploadList={false}
                  >
                    <EditImageButton>Upload mp3 File</EditImageButton>
                  </Upload>
                )}
              </ZipBackground>
            </CustomForm.Item>
            <StyledButtonContainer style={{ marginTop: 30 }}>
              <StyledButton onClick={this.handleUploadMp3.bind(this)}>
                Upload Audio
              </StyledButton>
            </StyledButtonContainer>
          </CustomForm>
        </>
      );
  };

  renderModalContent = () => {
    if (this.state.loading)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: 20,
          }}
        >
          <FormText>Uploading... </FormText>
          <FormText>Please do not close the browser</FormText>
        </div>
      );
    else
      return (
        <>
          <CustomForm style={{ marginTop: 0, marginBottom: 0 }}>
            {this.state.uploadModal === "upload" && (
              <CustomForm.Item
                validateStatus={
                  this.state.uploadClassError["bookName"] ? "error" : "success"
                }
                help={
                  this.state.uploadClassError["bookName"]
                    ? this.state.uploadClassError["bookName"]
                    : null
                }
              >
                <FormText>Book Name</FormText>
                <Input
                  id="prefix"
                  value={this.state.uploadClass.bookName}
                  onChange={this.handleChangeUploadName}
                  placeholder="Book Name"
                  style={{ borderRadius: 10, marginBottom: 10 }}
                />
              </CustomForm.Item>
            )}

            <CustomForm.Item
              style={{ marginTop: 20 }}
              validateStatus={
                this.state.uploadClassError["bookFile"] ? "error" : "success"
              }
              help={
                this.state.uploadClassError["bookFile"]
                  ? this.state.uploadClassError["bookFile"]
                  : null
              }
            >
              <FormText>Upload Zip File</FormText>
              <ZipBackground style={{ flexDirection: "column" }}>
                <FormText
                  onClick={this.handlePlayUploadedSound}
                  style={{ color: "#0A84FF", cursor: "pointer" }}
                ></FormText>
                {this.state.uploadClass.bookFile ? (
                  <FormText>{this.state.uploadClass.bookFileName}</FormText>
                ) : (
                  <Upload
                    accept=".zip"
                    onChange={this.handleChangeZip}
                    showUploadList={false}
                  >
                    <EditImageButton>Upload Zip File</EditImageButton>
                  </Upload>
                )}
              </ZipBackground>
            </CustomForm.Item>
            <StyledButtonContainer style={{ marginTop: 30 }}>
              <StyledButton
                onClick={
                  this.state.uploadModal === "upload"
                    ? this.handleUploadBook
                    : this.handleUpdateAssets
                }
              >
                {this.state.uploadModal === "upload"
                  ? "Upload Book"
                  : "Update Assets"}
              </StyledButton>
            </StyledButtonContainer>
          </CustomForm>
        </>
      );
  };

  render() {
    return (
      <>
        <Modal
          title="Upload A Book"
          visible={this.state.uploadModal}
          onCancel={this.handleBookDialog.bind(this, false)}
          footer={null}
        >
          {this.renderModalContent()}
        </Modal>
        <CountryModal
          selectedBook={this.state.selectedBook}
          visible={this.state.countryModal}
          onCancel={this.handleCountryDialog.bind(this, false)}
          handleUpdateCountry={this.handleUpdateCountry}
        />
        <Modal
          title="Add An Audio"
          visible={this.state.audioModal}
          onCancel={this.handleAudioDialog.bind(this, false)}
          footer={null}
        >
          {this.renderAudioModal()}
        </Modal>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editBook: (data) => dispatch(editBook(data)),
    deleteBook: (bookId) => dispatch(deleteBook(bookId)),
    uploadBook: (bookClass) => dispatch(uploadBook(bookClass)),
    updateBookAssets: (bookClass, bookId) =>
      dispatch(updateBookAssets(bookClass, bookId)),
    uploadBookAudio: (data, bookId) => dispatch(uploadBookAudio(data, bookId)),
  };
};

export default connect(null, mapDispatchToProps)(Books);

const BookImageBorder = styled.div`
  display: flex;
  height: 220px;
  align-items: center;
  justify-content: center;
  border: solid 1px rgb(229, 229, 234);
  border-radius: 10px;
  background: rgb(251, 251, 255);

  &:hover {
    cursor: pointer;
  }
`;

const PageImage = styled.img`
  height: 200px;
  margin: 10px;
`;

const BookImage = styled.img`
  position: relative;
  height: 200px;
  margin: 10px;
`;

const SmallImage = styled.img`
  height: 100px;
  margin: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: calc(100vw - 240px);
  margin: 20px;
  margin-top: 0px;
  min-height: 250px;
`;

const StyledButton = styled(Button)`
  border-radius: 10px;
  background: rgb(10, 133, 255) !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  border: none !important;
  width: auto;
  height: 40px;
  &:hover {
    background: rgb(64, 155, 255) !important;
  }
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`;

const ZipBackground = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 10px;
  background: rgb(251, 251, 255);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DividerTitle = styled.h4`
  color: #333;
  margin-bottom: 0px;
`;

const EditText = styled.p`
  font-size: 16px;
  color: #0a84ff;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const DividerTitleContainer = styled(FlexContentContainer)`
  border-bottom: solid 1px #ddd;
  margin-top: 40px;
  width: 98%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
