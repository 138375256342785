import firebase from "../../utils/firebase";
//Actions
import { getDatasetListener } from "./dataActions";

//================================================================
//INFO : Auth Listener
let unsubscribeAuthListener = null;
export const getAuthListener = () => {
  return async (dispatch, getState) => {
    if (!unsubscribeAuthListener)
      unsubscribeAuthListener = firebase
        .auth()
        .onAuthStateChanged(function (user) {
          if (user) {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: user,
              },
            });
            getDatasetListener(dispatch);
          } else
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: null,
              },
            });
        });
  };
};

export const removeAuthListener = () => {
  return async (dispatch, getState) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
  };
};

//================================================================
//INFO : Auth Actions
//INFO : Action to sign in MeBooks Dashboard
export const signIn = (credentials) => {
  return async (dispatch, getState) => {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password);
      dispatch({ type: "LOGIN_SUCCESS" });
    } catch (err) {
      dispatch({ type: "LOGIN_ERROR", err });
    }
  };
};

//INFO : Action to sign out MeBooks Dashboard
export const signOut = () => {
  return async (dispatch, getState) => {
    try {
      await firebase.auth().signOut();
    } catch (err) {}
  };
};
