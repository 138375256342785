import React, { Component } from "react";
import styled from "styled-components";
import { Upload, Select, Pagination } from "antd";
//Redux Firebase
import { connect } from "react-redux";
//Actions
import {
  createBanner,
  deleteBanner,
  editBanner,
} from "../../store/actions/bannerActions";
//Components
import {
  BackButton,
  EditImageContainer,
  EditImageButton,
  TableLayout,
  TableContent,
  ContentContainer,
  FormText,
  TopBar,
  TopBarSaveButton,
  TopBarDeleteButton,
  TopBarIcon,
  TopBarSearch,
  CustomForm,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarBreadcrumb,
  NavBar,
} from "./Layout.js";
import { SaveDialog, DeleteDialog } from "../Dialog";
//Utils
import EventBus from "js-event-bus";

const { Option } = Select;

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 500,
  },
  {
    title: "Banner",
    dataIndex: "banner",
    key: "banner",
    width: 500,
  },
];

const eventBus = EventBus();

class Banners extends Component {
  state = {
    function: "",
    search: "",
    selectedBanner: null,
    imageUrl: "",
    bannerFile: "",
    fileError: "",
    seriesList: [],
    bookList: [],
    deleteModal: false,
    editBanner: false,
    page: 1,
  };

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("banner-function-success");
    eventBus.detach("banner-function-error");
  }

  componentDidMount() {
    eventBus.on("banner-function-success", this.handleClearState);
    eventBus.on("banner-function-error", this.handleError);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.series &&
      this.props.series.length > 0 &&
      prevProps !== this.props.series &&
      this.state.seriesList.length === 0
    ) {
      this.setState({ seriesList: this.props.series });
    }

    if (
      this.props.books &&
      this.props.books.length > 0 &&
      prevProps !== this.props.books &&
      this.state.bookList.length === 0
    ) {
      this.setState({ bookList: this.props.books });
    }
  }

  handleError = (err) => {
    this.setState(
      {
        fileError: err,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedBanner: "",
        imageUrl: "",
        bannerFile: "",
        fileError: "",
        seriesList: [],
        bookList: [],
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, bannerData) => {
    let tempBanner = "";
    if (bannerData) tempBanner = JSON.parse(JSON.stringify(bannerData));
    else
      tempBanner = {
        image: "",
        imageName: "",
        action: "",
      };
    this.setState(
      {
        function: type,
        selectedBanner: tempBanner,
        bannerFile: "",
        imageUrl: "",
        fileError: "",
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handleDropdown = (id, value) => {
    let tempBanner = this.state.selectedBanner;
    tempBanner[id] = value;

    this.setState(
      {
        selectedBanner: tempBanner,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleChangeImage = (event) => {
    let newFile = event.file.originFileObj;
    this.getBase64(newFile, (imageUrl) =>
      this.setState(
        {
          imageUrl: imageUrl,
          bannerFile: newFile,
        },
        () => {
          this.props.handleDataChanged(true);
        }
      )
    );
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleSubmit = () => {
    this.props.handleLoading(true);

    if (!this.state.bannerFile && !this.state.selectedBanner.image)
      this.setState({ fileError: "Please upload an image file" });
    else this.setState({ fileError: "" });

    setTimeout(() => {
      if (!this.state.fileError) {
        if (this.state.function === "create")
          this.props.createBanner({
            bannerFile: this.state.bannerFile,
            selectedBanner: this.state.selectedBanner,
          });
        else if (this.state.function === "edit")
          this.props.editBanner({
            bannerFile: this.state.bannerFile,
            selectedBanner: this.state.selectedBanner,
          });
        this.props.handleDataChanged(false);
      } else this.props.handleLoading(false);
    }, 500);
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteBanner(this.state.selectedBanner.imageName);
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  renderBanners = () => {
    let bannerView = [];
    let banners = [];

    if (this.props.banners && this.props.banners.length > 0) {
      let min = (this.state.page - 1) * 10;
      let max =
        this.state.page * 10 > this.props.banners.length
          ? this.props.banners.length
          : this.state.page * 10;
      if (this.state.search) banners = this.props.banners;
      else banners = this.props.banners.slice(min, max);

      banners.map((eachBanner, index) => {
        let imageName = eachBanner.imageName.split(" ").join("%20");
        if (
          eachBanner &&
          eachBanner.imageName
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        )
          bannerView.push({
            key: index,
            title: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachBanner)}
              >
                {eachBanner.imageName}
              </TableContent>
            ),
            banner: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachBanner)}
              >
                <BannerImage
                  src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_banner%2F${imageName}?alt=media&token=${eachBanner.image}`}
                />
              </TableContent>
            ),
          });
      });
    }

    return bannerView;
  };

  renderAction = () => {
    let count = 1;
    let actionList = [
      <Option key={0} value="">
        No Action
      </Option>,
    ];

    if (this.state.seriesList && this.state.seriesList.length > 0)
      this.state.seriesList.map((eachSeries) => {
        actionList.push(
          <Option key={count} value={eachSeries.id}>
            {eachSeries.name}
          </Option>
        );
        count++;
      });

    if (this.state.bookList && this.state.bookList.length > 0)
      this.state.bookList.map((eachBook) => {
        actionList.push(
          <Option key={count} value={eachBook.id}>
            {eachBook.title}
          </Option>
        );
        count++;
      });

    return actionList;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "", null)} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "", null)}>
              Banners
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Banners"
                : "Creating banners"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>

          <div style={{ flex: 1 }} />
          {this.state.function === "edit" && (
            <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
              Delete
            </TopBarDeleteButton>
          )}
          <TopBarSaveButton onClick={this.handleSubmit}>Save</TopBarSaveButton>
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Banners</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleFunction.bind(this, "create", null)}
            style={{ left: 110 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderFileName = () => {
    if (this.state.imageError) return this.state.imageName;
    else if (this.state.selectedBanner.imageName)
      return "File Name : " + this.state.selectedBanner.imageName;
  };

  renderBannerImageEdit = () => {
    if (this.state.function === "edit") {
      if (this.state.imageUrl) return this.state.imageUrl;
      else
        return `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_banner%2F${this.state.selectedBanner.imageName
          .split(" ")
          .join("%20")}?alt=media&token=${this.state.selectedBanner.image}`;
    } else return this.state.imageUrl;
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <CustomForm>
          <CustomForm.Item
            validateStatus={this.state.fileError ? "error" : "success"}
            help={this.state.fileError ? this.state.fileError : null}
          >
            <FormText>Banner Image - 1400px(w) x 500px(h) - 800KB</FormText>
            <BannerImageBackground>
              {this.state.imageUrl || this.state.function === "edit" ? (
                <>
                  <CreateBannerImage
                    id="bannerImage"
                    src={this.renderBannerImageEdit()}
                  />
                  <EditImageContainer>
                    <Upload
                      accept=".bmp, .jpeg, .jpg, .png"
                      onChange={this.handleChangeImage}
                      showUploadList={false}
                    >
                      <EditImageButton>Edit Image</EditImageButton>
                    </Upload>
                  </EditImageContainer>
                </>
              ) : (
                <Upload
                  accept=".bmp, .jpeg, .jpg, .png"
                  onChange={this.handleChangeImage}
                  showUploadList={false}
                >
                  <EditImageButton style={{ marginTop: 80, marginBottom: 80 }}>
                    Upload an Image
                  </EditImageButton>
                </Upload>
              )}
            </BannerImageBackground>
            <FormText>
              {this.state.selectedBanner.imageName && !this.state.fileError
                ? this.renderFileName()
                : null}
            </FormText>
          </CustomForm.Item>
          <CustomForm.Item style={{ marginTop: 10 }}>
            <FormText>Action</FormText>
            <Select
              onChange={this.handleDropdown.bind(this, "action")}
              placeholder="Action"
              style={{ width: "48%" }}
              value={
                this.state.selectedBanner.action
                  ? this.state.selectedBanner.action
                  : undefined
              }
            >
              {this.renderAction()}
            </Select>
          </CustomForm.Item>
        </CustomForm>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderBanners()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.banners !== undefined && (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 10 >= this.props.banners.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          )}
        </>
      );
  };

  render() {
    return (
      <>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createBanner: (data) => dispatch(createBanner(data)),
    deleteBanner: (id) => dispatch(deleteBanner(id)),
    editBanner: (data) => dispatch(editBanner(data)),
  };
};

export default connect(null, mapDispatchToProps)(Banners);

const BannerImageBackground = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgb(251, 251, 255);

  &:hover {
    cursor: pointer;
  }
`;

const BannerImage = styled.img`
  height: 100px;
  width: 200px;
  margin: 10px;
`;

const CreateBannerImage = styled.img`
  height: 200px;
  width: 400px;
  margin: 10px;
`;
