import React, { Component } from "react";
import { Input, Pagination } from "antd";
//Actions
import { createTag, deleteTag, editTag } from "../../store/actions/tagAction";
// Components
import {
  TableContentDefault,
  BackButton,
  NavBar,
  TableLayout,
  TableContent,
  ContentContainer,
  TopBarBreadcrumb,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarSearch,
  TopBarIcon,
  TopBarDeleteButton,
  TopBarSaveButton,
  TopBar,
  FormText,
  CustomForm,
} from "./Layout";
import { SaveDialog, DeleteDialog } from "../Dialog";
//Redux Firebase
import { connect } from "react-redux";
import { compose } from "redux";
//Utils
import EventBus from "js-event-bus";

const columns = [
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
  },
  {
    title: "Book Tagged",
    dataIndex: "bookTagged",
    key: "bookTagged",
  },
];

const eventBus = EventBus();

class BookTags extends Component {
  state = {
    function: "",
    search: "",
    selectedTag: "",
    tagTitleError: "",
    page: 1,
    deleteModal: false,
  };

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("tag-function-success");
    eventBus.detach("tag-function-error");
  }

  componentDidMount() {
    eventBus.on("tag-function-success", this.handleClearState);
    eventBus.on("tag-function-error", this.handleError);
  }

  handleError = (err) => {
    this.setState(
      {
        tagTitleError: err,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        selectedTag: "",
        tagTitleError: "",
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, data) => {
    this.handleClearState();

    this.setState(
      {
        function: type,
        selectedTag: data,
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handleChange = (event) => {
    let eventId = event.target.id;
    let tempTag = this.state.selectedTag;
    tempTag[eventId] = event.target.value;

    this.setState(
      {
        tempTag: event.target.value,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleSubmit = (type) => {
    if (!this.state.selectedTag.title)
      this.setState({
        tagTitleError: "Please insert the name of book tag",
      });
    else
      this.setState(
        {
          tagTitleError: "",
        },
        () => {
          this.props.handleLoading(true);
          if (type === "edit")
            this.props.editTag(
              this.state.selectedTag.id,
              this.state.selectedTag.title
            );
          else this.props.createTag(this.state.selectedTag.title);
          this.props.handleDataChanged(false);
        }
      );
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteTag(this.state.selectedTag.id);
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  handleCount = (tag) => {
    let count = 0;
    if (this.props.books && this.props.books.length > 0) {
      this.props.books.map((eachBook) => {
        if (eachBook.tags.includes(tag)) count++;
      });
    }
    return count;
  };

  renderDeleteButton = () => {
    if (this.state.function === "edit") {
      let staticTag = [
        "ed160ed2-3820-46d4-8099-f7df46f54f78",
        "8715e220-fc82-4ffb-b22a-d9be73c83e82",
        "09bb9ee6-eb85-4336-89f7-6ce57f699e39",
        "a116455e-deb5-46b7-adaf-37035892c7fc",
        "b4c56868-fe9d-4672-b005-6898e463dde0",
        "0acde8de-2885-4b43-bb41-83b488b1e493",
        "6716801c-0bfa-47dd-aed7-cfab9600d889",
      ];
      if (!staticTag.includes(this.state.selectedTag.id))
        return (
          <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
            Delete
          </TopBarDeleteButton>
        );
    }
  };

  renderBookTags = () => {
    let tagView = [];
    let tags = [];
    let tagsList = [];
    let filterTags = [];

    if (this.props.tags && this.props.tags.length > 0)
      this.props.tags.map((eachTag) => {
        tagsList.push({
          id: eachTag.id,
          title: eachTag.title,
          bookTagged: this.handleCount(eachTag.id),
        });
      });

    if (tagsList.length > 0) {
      let noBook = [];
      let hasBook = [];

      tagsList.map((eachTag) => {
        if (eachTag.bookTagged > 0) hasBook.push(eachTag);
        else noBook.push(eachTag);

        hasBook.sort(function (a, b) {
          return b.bookTagged - a.bookTagged;
        });
        filterTags = hasBook.concat(noBook);
      });

      let min = (this.state.page - 1) * 100;
      let max =
        this.state.page * 50 > filterTags.length
          ? filterTags.length
          : this.state.page * 100;
      if (this.state.search) tags = filterTags;
      else tags = filterTags.slice(min, max);

      tags.map((eachTag, index) => {
        if (
          eachTag.title &&
          eachTag.title
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        )
          tagView.push({
            key: index,
            tags: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachTag)}
              >
                {eachTag.title}
              </TableContent>
            ),
            bookTagged: (
              <TableContentDefault
                onClick={this.handleFunction.bind(this, "edit", eachTag)}
              >
                {eachTag.bookTagged}
              </TableContentDefault>
            ),
          });
      });
    }

    return tagView;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "")} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "")}>
              Tags
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Tags"
                : "Creating Tags"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>

          <div style={{ flex: 1 }} />
          {this.renderDeleteButton()}
          <TopBarSaveButton
            onClick={this.handleSubmit.bind(this, this.state.function)}
          >
            Save
          </TopBarSaveButton>
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Tags</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleFunction.bind(this, "create", {})}
            style={{ left: 85 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <CustomForm>
          <CustomForm.Item
            validateStatus={this.state.tagTitleError ? "error" : "success"}
            help={this.state.tagTitleError ? this.state.tagTitleError : null}
          >
            <FormText>Name of Book Tag</FormText>
            <Input
              id="title"
              value={
                this.state.selectedTag.title
                  ? this.state.selectedTag.title
                  : null
              }
              onChange={this.handleChange}
              style={{ width: 400, borderRadius: 10 }}
            />
          </CustomForm.Item>
        </CustomForm>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderBookTags()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.tags !== undefined && (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 100 >= this.props.tags.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          )}
        </>
      );
  };

  render() {
    return (
      <>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createTag: (tagTitle) => dispatch(createTag(tagTitle)),
    editTag: (id, tagTitle) => dispatch(editTag(id, tagTitle)),
    deleteTag: (tagTitle) => dispatch(deleteTag(tagTitle)),
  };
};

export default compose(connect(null, mapDispatchToProps))(BookTags);
