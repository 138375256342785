import React, { Component } from "react";
import styled from "styled-components";
import { Select, Input, Upload, Checkbox, Pagination } from "antd";
//Redux Firebase
import { connect } from "react-redux";
//Actions
import {
  createSeries,
  deleteSeries,
  editSeries,
} from "../../store/actions/seriesActions";
// Components
import {
  BackButton,
  NavBar,
  EditImageContainer,
  EditImageButton,
  FlexContentContainer,
  TableLayout,
  TableContent,
  ContentContainer,
  TopBarBreadcrumb,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarSearch,
  TopBarIcon,
  TopBarDeleteButton,
  TopBarSaveButton,
  TopBar,
  FormText,
  CustomForm,
} from "./Layout";
import { SaveDialog, DeleteDialog } from "../Dialog";
//Utils
import EventBus from "js-event-bus";

const { Option } = Select;
const { TextArea } = Input;

const columns = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    width: 500,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 500,
  },
];

const eventBus = EventBus();
let pageAudio = new Audio();

class Series extends Component {
  state = {
    function: "",
    search: "",
    selectedSeries: "",
    imageFile: "",
    imageUrl: "",
    backgroundImageFile: "",
    backgroundImageUrl: "",
    soundUrl: "",
    soundFile: "",
    soundError: "",
    fileError: "",
    nameError: "",
    booksError: "",
    descriptionError: "",
    priorityError: "",
    deleteModal: false,
    editImage: false,
    editBackground: false,
    page: 1,
  };

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("series-function-success");
    eventBus.detach("series-function-error");
  }

  componentDidMount() {
    eventBus.on("series-function-success", this.handleClearState);
    eventBus.on("series-function-error", this.handleError);
  }

  handleError = (err) => {
    this.setState(
      {
        fileError: err,
        nameError: err,
        booksError: err,
        descriptionError: err,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedSeries: "",
        imageFile: "",
        imageUrl: "",
        backgroundImageFile: "",
        backgroundImageUrl: "",
        soundUrl: "",
        soundFile: "",
        soundError: "",
        fileError: "",
        nameError: "",
        booksError: "",
        descriptionError: "",
        priorityError: "",
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, seriesData) => {
    let tempSeries = "";
    if (seriesData) tempSeries = JSON.parse(JSON.stringify(seriesData));
    else
      tempSeries = {
        books: [],
        tags: [],
        description: "",
        image: "",
        name: "",
        backgroundImage: "",
        character: false,
      };

    this.handleClearState();
    this.setState(
      {
        function: type,
        selectedSeries: tempSeries,
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handleChange = (event) => {
    let tempSeries = this.state.selectedSeries;
    tempSeries[event.target.id] = event.target.value;

    this.setState(
      {
        selectedSeries: tempSeries,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleDropdown = (id, value) => {
    let tempSeries = this.state.selectedSeries;
    tempSeries[id] = value;
    this.setState(
      {
        selectedSeries: tempSeries,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleChecked = (event) => {
    let tempSeries = this.state.selectedSeries;
    tempSeries.character = event.target.checked;
    this.setState({ selectedSeries: tempSeries });
    this.props.handleDataChanged(true);
  };

  handleChangeSound = (event) => {
    let newFile = event.file.originFileObj;
    if (newFile) {
      Object.defineProperty(newFile, "name", {
        writable: true,
        value: "sound.mp3",
      });

      this.getBase64(newFile, (soundUrl) =>
        this.setState(
          {
            soundUrl: soundUrl,
            soundFile: newFile,
          },
          () => {
            this.props.handleDataChanged(true);
          }
        )
      );
    }
  };

  handleChangeImage = (event) => {
    let newFile = event.file.originFileObj;
    Object.defineProperty(newFile, "name", {
      writable: true,
      value: "image.png",
    });

    this.getBase64(newFile, (imageUrl) =>
      this.setState(
        {
          imageUrl: imageUrl,
          imageFile: newFile,
        },
        () => {
          this.props.handleDataChanged(true);
        }
      )
    );
  };

  handleChangeBackgroundImage = (event) => {
    let newFile = event.file.originFileObj;
    Object.defineProperty(newFile, "name", {
      writable: true,
      value: "backgroundImage.png",
    });

    this.getBase64(newFile, (imageUrl) =>
      this.setState(
        {
          backgroundImageUrl: imageUrl,
          backgroundImageFile: newFile,
        },
        () => {
          this.props.handleDataChanged(true);
        }
      )
    );
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  handlePlayUploadedSound = () => {
    pageAudio.pause();
    if (this.state.selectedSeries.sound)
      pageAudio = new Audio(
        `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${this.state.selectedSeries.id}%2Fsound.mp3?alt=media&token=${this.state.selectedSeries.sound}`
      );
    else pageAudio = new Audio(this.state.soundUrl);

    pageAudio.play();
  };

  handleSubmit = (type) => {
    this.props.handleLoading(true);
    let hasBackground = false;
    if (this.state.selectedSeries.backgroundImage) hasBackground = true;
    else hasBackground = false;

    if (!this.state.selectedSeries.name)
      this.setState({ nameError: "Please insert a name" });
    else this.setState({ nameError: null });

    if (
      !this.state.selectedSeries.books ||
      this.state.selectedSeries.books.length < 1
    )
      this.setState({ booksError: "Please select some books" });
    else this.setState({ booksError: null });

    if (!this.state.selectedSeries.description)
      this.setState({ descriptionError: "Please insert a description" });
    else this.setState({ descriptionError: null });

    if (!this.state.selectedSeries.priority)
      this.setState({ priorityError: "Please insert a priority" });
    else if (isNaN(this.state.selectedSeries.priority))
      this.setState({ priorityError: "Priority should be a number only" });
    else this.setState({ priorityError: null });

    if (!this.state.imageFile && !this.state.selectedSeries.image.length > 0)
      this.setState({ fileError: "Please upload an image" });
    else this.setState({ fileError: null });

    if (!this.state.imageFile && !this.state.selectedSeries.image.length > 0)
      this.setState({ fileError: "Please upload an image" });
    else this.setState({ fileError: null });

    if (!this.state.soundFile && !this.state.selectedSeries.sound)
      this.setState({ soundError: "Please upload a sound file" });
    else this.setState({ soundError: "" });

    setTimeout(() => {
      if (
        !this.state.nameError &&
        !this.state.booksError &&
        !this.state.descriptionError &&
        !this.state.priorityError &&
        !this.state.fileError &&
        !this.state.soundError
      ) {
        if (this.state.function === "create")
          this.props.createSeries({
            name: this.state.selectedSeries.name,
            books: this.state.selectedSeries.books,
            tags: this.state.selectedSeries.tags,
            description: this.state.selectedSeries.description,
            imageFile: this.state.imageFile,
            soundFile: this.state.soundFile,
            backgroundImageFile: this.state.backgroundImageFile,
            character: this.state.selectedSeries.character,
            priority: Number(this.state.selectedSeries.priority),
          });
        else
          this.props.editSeries({
            id: this.state.selectedSeries.id,
            hasBackground: hasBackground,
            name: this.state.selectedSeries.name,
            books: this.state.selectedSeries.books,
            tags: this.state.selectedSeries.tags,
            description: this.state.selectedSeries.description,
            imageFile: this.state.imageFile,
            soundFile: this.state.soundFile,
            backgroundImageFile: this.state.backgroundImageFile,
            character: this.state.selectedSeries.character,
            priority: Number(this.state.selectedSeries.priority),
          });
        this.props.handleDataChanged(false);
      } else this.props.handleLoading(false);
    }, 500);
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteSeries({
        id: this.state.selectedSeries.id,
        backgroundImage: this.state.selectedSeries.backgroundImage
          ? true
          : false,
      });
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  renderSeries = () => {
    let seriesView = [];
    let series = [];

    if (this.props.series && this.props.series.length > 0) {
      let min = (this.state.page - 1) * 50;
      let max =
        this.state.page * 50 > this.props.series.length
          ? this.props.series.length
          : this.state.page * 50;
      if (this.state.search) series = this.props.series;
      else series = this.props.series.slice(min, max);

      series.map((eachSeries, index) => {
        let url = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${eachSeries.id}%2Fimage.png?alt=media&token=${eachSeries.image}`;
        if (
          eachSeries &&
          eachSeries.name
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        )
          seriesView.push({
            key: index,
            image: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachSeries)}
              >
                <SeriesImage src={url} />
              </TableContent>
            ),
            name: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachSeries)}
              >
                {eachSeries.name}
              </TableContent>
            ),
          });
      });
    }

    return seriesView;
  };

  renderDeleteButton = () => {
    if (this.state.function === "edit") {
      let staticSeries = ["series_9c86a007-88a9-4534-9263-c24827a7eea5"];
      if (!staticSeries.includes(this.state.selectedSeries.id))
        return (
          <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
            Delete
          </TopBarDeleteButton>
        );
    }
  };

  renderBookSelection = () => {
    let bookList = [];
    if (this.props.books && this.props.books.length > 0)
      this.props.books.map((eachBook, index) => {
        bookList.push(
          <Option key={index} value={eachBook.id}>
            {eachBook.title}
          </Option>
        );
      });
    return bookList;
  };

  renderTagSelection = () => {
    let tagList = [];
    if (this.props.tags && this.props.tags.length > 0)
      this.props.tags.map((eachTag, index) => {
        tagList.push(
          <Option key={index} value={eachTag.id}>
            {eachTag.title}
          </Option>
        );
      });
    return tagList;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "", null)} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "", null)}>
              Series
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Series"
                : "Creating Series"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>

          <div style={{ flex: 1 }} />
          {this.renderDeleteButton()}
          <TopBarSaveButton
            onClick={this.handleSubmit.bind(this, this.state.function)}
          >
            Save
          </TopBarSaveButton>
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Series</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleFunction.bind(this, "create", null)}
            style={{ left: 95 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderSoundFileName = () => {
    let soundFileName = "";
    if (this.state.selectedSeries.sound) soundFileName = "sound.mp3";
    else if (this.state.soundFile) soundFileName = this.state.soundFile.name;
    else soundFileName = "";

    return soundFileName;
  };

  renderContent = () => {
    if (this.state.function) {
      return (
        <CustomForm>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.fileError ? "error" : "success"}
              help={this.state.fileError ? this.state.fileError : null}
              style={{ width: "48%" }}
            >
              <FormText>Image - Auto(w) x 400px(h) - 200KB</FormText>
              <SeriesBackground>
                {this.state.function === "create" ? (
                  <>
                    {this.state.imageUrl && this.state.imageUrl.length > 0 ? (
                      <>
                        <SeriesImage src={this.state.imageUrl} />
                        <EditImageContainer>
                          <Upload
                            accept=".bmp, .jpeg, .jpg, .png"
                            onChange={this.handleChangeImage}
                            showUploadList={false}
                          >
                            <EditImageButton>Edit Image</EditImageButton>
                          </Upload>
                        </EditImageContainer>
                      </>
                    ) : (
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeImage}
                        showUploadList={false}
                      >
                        <EditImageButton
                          style={{ marginTop: 50, marginBottom: 50 }}
                        >
                          Upload an Image
                        </EditImageButton>
                      </Upload>
                    )}
                  </>
                ) : (
                  <>
                    <SeriesImage
                      src={
                        this.state.imageUrl && this.state.imageUrl.length > 0
                          ? this.state.imageUrl
                          : `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${this.state.selectedSeries.id}%2Fimage.png?alt=media&token=${this.state.selectedSeries.image}`
                      }
                    />
                    <EditImageContainer>
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeImage}
                        showUploadList={false}
                      >
                        <EditImageButton>Edit Image</EditImageButton>
                      </Upload>
                    </EditImageContainer>
                  </>
                )}
              </SeriesBackground>
            </CustomForm.Item>
            <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
              <FormText>Background - Auto(w) x 700px(h) - 400KB</FormText>
              <SeriesBackground>
                {this.state.selectedSeries.backgroundImage ? (
                  <>
                    <SeriesImage
                      src={
                        this.state.backgroundImageUrl &&
                        this.state.backgroundImageUrl.length > 0
                          ? this.state.backgroundImageUrl
                          : `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${this.state.selectedSeries.id}%2FbackgroundImage.png?alt=media&token=${this.state.selectedSeries.backgroundImage}`
                      }
                    />
                    <EditImageContainer>
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeBackgroundImage}
                        showUploadList={false}
                      >
                        <EditImageButton>Edit Image</EditImageButton>
                      </Upload>
                    </EditImageContainer>
                  </>
                ) : (
                  <>
                    {this.state.backgroundImageUrl &&
                    this.state.backgroundImageUrl.length > 0 ? (
                      <>
                        <SeriesImage
                          src={this.state.backgroundImageUrl}
                          style={{ borderRadius: 0 }}
                        />
                        <EditImageContainer>
                          <Upload
                            accept=".bmp, .jpeg, .jpg, .png"
                            onChange={this.handleChangeBackgroundImage}
                            showUploadList={false}
                          >
                            <EditImageButton>Edit Image</EditImageButton>
                          </Upload>
                        </EditImageContainer>
                      </>
                    ) : (
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeBackgroundImage}
                        showUploadList={false}
                      >
                        <EditImageButton
                          style={{ marginTop: 50, marginBottom: 50 }}
                        >
                          Upload an Image
                        </EditImageButton>
                      </Upload>
                    )}
                  </>
                )}
              </SeriesBackground>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.soundError ? "error" : "success"}
              help={this.state.soundError ? this.state.soundError : null}
              style={{ width: "48%", marginTop: 20 }}
            >
              <FormText>Sound - 100KB</FormText>
              <SeriesBackground style={{ flexDirection: "column" }}>
                <FormText
                  onClick={this.handlePlayUploadedSound}
                  style={{ color: "#0A84FF", cursor: "pointer" }}
                >
                  {this.renderSoundFileName()}
                </FormText>
                <Upload
                  accept=".mp3"
                  onChange={this.handleChangeSound}
                  showUploadList={false}
                >
                  <EditImageButton>
                    {this.state.selectedSeries.sound
                      ? "Edit Sound"
                      : "Upload Sound"}
                  </EditImageButton>
                </Upload>
              </SeriesBackground>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.nameError ? "error" : "success"}
              help={this.state.nameError ? this.state.nameError : null}
              style={{ width: "48%", marginTop: 20 }}
            >
              <FormText>Name</FormText>
              <Input
                id="name"
                value={
                  this.state.selectedSeries.name
                    ? this.state.selectedSeries.name
                    : ""
                }
                onChange={this.handleChange}
                style={{ borderRadius: 10 }}
              />
            </CustomForm.Item>
            <CustomForm.Item
              style={{ width: "48%", marginLeft: 20, marginTop: 20 }}
            >
              <FormText>Character</FormText>
              <Checkbox
                onChange={this.handleChecked}
                checked={this.state.selectedSeries.character}
                style={{ lineHeight: "40px" }}
              >
                This is a Character
              </Checkbox>
            </CustomForm.Item>
          </FlexContentContainer>

          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.booksError ? "error" : "success"}
              help={this.state.booksError ? this.state.booksError : null}
              style={{ marginTop: 20, width: "48%" }}
            >
              <FormText>Books</FormText>
              <Select
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "books")}
                placeholder="Add Books"
                value={
                  this.state.selectedSeries.books
                    ? this.state.selectedSeries.books
                    : []
                }
              >
                {this.renderBookSelection()}
              </Select>
            </CustomForm.Item>
            <CustomForm.Item
              style={{ marginTop: 20, width: "48%", marginLeft: 20 }}
            >
              <FormText>Tags</FormText>
              <Select
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "tags")}
                placeholder="Add Tags"
                value={
                  this.state.selectedSeries.tags
                    ? this.state.selectedSeries.tags
                    : []
                }
              >
                {this.renderTagSelection()}
              </Select>
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.priorityError ? "error" : "success"}
              help={this.state.priorityError ? this.state.priorityError : null}
              style={{ width: "48%", marginTop: 20 }}
            >
              <FormText>Priority</FormText>
              <Input
                id="priority"
                value={
                  this.state.selectedSeries.priority
                    ? this.state.selectedSeries.priority
                    : ""
                }
                onChange={this.handleChange}
                style={{ borderRadius: 10 }}
              />
            </CustomForm.Item>
          </FlexContentContainer>
          <CustomForm.Item
            validateStatus={this.state.descriptionError ? "error" : "success"}
            help={
              this.state.descriptionError ? this.state.descriptionError : null
            }
            style={{ marginTop: 20 }}
          >
            <FormText>Description</FormText>
            <TextArea
              id="description"
              rows={4}
              value={
                this.state.selectedSeries.description
                  ? this.state.selectedSeries.description
                  : ""
              }
              style={{ borderRadius: 10, width: "98%" }}
              onChange={this.handleChange}
            />
          </CustomForm.Item>
        </CustomForm>
      );
    } else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderSeries()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.series !== undefined && (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 50 >= this.props.series.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          )}
        </>
      );
  };

  render() {
    return (
      <>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createSeries: (data) => dispatch(createSeries(data)),
    editSeries: (data) => dispatch(editSeries(data)),
    deleteSeries: (data) => dispatch(deleteSeries(data)),
  };
};

export default connect(null, mapDispatchToProps)(Series);

const SeriesImage = styled.img`
  height: 100px;
  width: auto;
  margin: 10px;
`;

const SeriesBackground = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 10px;
  background: rgb(251, 251, 255);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;
