import { Input, Pagination, Select, Upload } from "antd";
//Utils
import EventBus from "js-event-bus";
import React, { Component } from "react";
//Redux Firebase
import { connect } from "react-redux";
import styled from "styled-components";
//Actions
import {
  createSticker,
  deleteSticker,
} from "../../store/actions/stickerActions";
import { DeleteDialog, SaveDialog } from "../Dialog";
//Components
import {
  BackButton,
  BreadcrumbActive,
  BreadcrumbLink,
  ContentContainer,
  CustomForm,
  EditImageButton,
  FlexContentContainer,
  FormText,
  NavBar,
  TableContent,
  TableLayout,
  TopBar,
  TopBarBreadcrumb,
  TopBarDeleteButton,
  TopBarIcon,
  TopBarSaveButton,
  TopBarSearch,
} from "./Layout.js";

const { Option } = Select;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 500,
  },
  {
    title: "Sticker",
    dataIndex: "sticker",
    key: "sticker",
    width: 500,
  },
];

const eventBus = EventBus();
let pageAudio = new Audio();

class Stickers extends Component {
  state = {
    page: 1,
    function: "",
    search: "",
    selectedSticker: "",
    nameError: "",
    imageUrl: "",
    soundUrl: "",
    soundFile: "",
    stickerFile: "",
    fileError: "",
    soundError: "",
    nameError: "",
    pointsError: "",
    typeError: "",
    deleteModal: false,
  };

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("sticker-function-success");
    eventBus.detach("sticker-function-error");
  }

  componentDidMount() {
    eventBus.on("sticker-function-success", this.handleClearState);
    eventBus.on("sticker-function-error", this.handleError);
  }

  handleError = (err) => {
    this.setState({ fileError: err }, () => {
      this.props.handleLoading(false);
    });
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedSticker: "",
        imageUrl: "",
        soundUrl: "",
        soundFile: "",
        stickerFile: "",
        fileError: "",
        soundError: "",
        nameError: "",
        pointsError: "",
        typeError: "",
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleChange = (event) => {
    let tempSticker = this.state.selectedSticker;
    tempSticker[event.target.id] = event.target.value;

    this.setState(
      {
        selectedSticker: tempSticker,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleFunction = (type, stickerData) => {
    let tempSticker = "";
    if (stickerData) tempSticker = JSON.parse(JSON.stringify(stickerData));
    else tempSticker = { image: "", imageName: "" };
    this.handleClearState();
    this.setState(
      {
        function: type,
        selectedSticker: tempSticker,
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handlePlayUploadedSound = () => {
    pageAudio.pause();
    if (this.state.selectedSticker.sound)
      pageAudio = new Audio(
        `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_stickers%2F${this.state.selectedSticker.id}%2Fsound.mp3?alt=media&token=${this.state.selectedSticker.sound}`
      );
    else pageAudio = new Audio(this.state.soundUrl);

    pageAudio.play();
  };

  handleChangeImage = (event) => {
    let newFile = event.file.originFileObj;
    Object.defineProperty(newFile, "name", {
      writable: true,
      value: "sticker.png",
    });

    this.getBase64(newFile, (imageUrl) =>
      this.setState(
        {
          imageUrl: imageUrl,
          stickerFile: newFile,
        },
        () => {
          this.props.handleDataChanged(true);
        }
      )
    );
  };

  handleChangeSound = (event) => {
    let newFile = event.file.originFileObj;
    if (newFile) {
      Object.defineProperty(newFile, "name", {
        writable: true,
        value: "sound.mp3",
      });

      this.getBase64(newFile, (soundUrl) =>
        this.setState(
          {
            soundUrl: soundUrl,
            soundFile: newFile,
          },
          () => {
            this.props.handleDataChanged(true);
          }
        )
      );
    }
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleSubmit = (type) => {
    this.props.handleLoading(true);

    if (!this.state.stickerFile && !this.state.selectedSticker.image)
      this.setState({ fileError: "Please upload an image file" });
    else this.setState({ fileError: "" });

    if (!this.state.soundFile && !this.state.selectedSticker.sound)
      this.setState({ soundError: "Please upload a sound file" });
    else this.setState({ soundError: "" });

    if (!this.state.selectedSticker.name)
      this.setState({
        nameError: "Please insert the name",
      });
    else this.setState({ nameError: "" });

    if (
      this.state.selectedSticker.points !== null &&
      this.state.selectedSticker.points !== undefined
    ) {
      if (
        this.state.selectedSticker.points.trim().length === 0 ||
        isNaN(this.state.selectedSticker.points)
      )
        this.setState({
          pointsError: "Please insert number of points",
        });
      else this.setState({ pointsError: "" });
    } else
      this.setState({
        pointsError: "Please insert number of points",
      });

    if (!this.state.selectedSticker.type)
      this.setState({
        typeError: "Please select type of the sticker",
      });
    else
      this.setState({
        typeError: "",
      });

    setTimeout(() => {
      if (
        !this.state.fileError &&
        !this.state.soundError &&
        !this.state.typeError &&
        !this.state.nameError &&
        !this.state.pointsError
      ) {
        if (this.state.function === "create")
          this.props.createSticker({
            stickerFile: this.state.stickerFile,
            soundFile: this.state.soundFile,
            selectedSticker: this.state.selectedSticker,
          });
        this.props.handleDataChanged(false);
      } else this.props.handleLoading(false);
    }, 500);
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteSticker({ id: this.state.selectedSticker.id });
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  handleDropdown = (id, value) => {
    let tempSticker = this.state.selectedSticker;
    tempSticker[id] = value;
    this.setState(
      {
        selectedSticker: tempSticker,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  renderSoundFileName = () => {
    let soundFileName = "";
    if (this.state.selectedSticker.sound) soundFileName = "sound.mp3";
    else if (this.state.soundFile) soundFileName = this.state.soundFile.name;
    else soundFileName = "";

    return soundFileName;
  };

  renderStickers = () => {
    let stickerView = [];
    let stickers = [];

    if (this.props.stickers && this.props.stickers.length > 0) {
      let min = (this.state.page - 1) * 50;
      let max =
        this.state.page * 50 > this.props.stickers.length
          ? this.props.stickers.length
          : this.state.page * 50;
      if (this.state.search) stickers = this.props.stickers;
      else stickers = this.props.stickers.slice(min, max);
      stickers.map((eachSticker, index) => {
        if (
          eachSticker &&
          eachSticker.name
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        ) {
          let url = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_stickers%2F${eachSticker.id}%2Fsticker.png?alt=media&token=${eachSticker.image}`;
          stickerView.push({
            key: index,
            name: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachSticker)}
              >
                {eachSticker.name}
              </TableContent>
            ),
            sticker: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachSticker)}
              >
                <StickerImage src={url} />
              </TableContent>
            ),
          });
        }
      });
    }

    return stickerView;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "", null)} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "", null)}>
              Stickers
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Stickers"
                : "Creating Stickers"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>

          <div style={{ flex: 1 }} />
          {this.state.function === "edit" ? (
            <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
              Delete
            </TopBarDeleteButton>
          ) : (
            <TopBarSaveButton onClick={this.handleSubmit}>
              Save
            </TopBarSaveButton>
          )}

          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Stickers</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleFunction.bind(this, "create", null)}
            style={{ left: 105 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <CustomForm>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.fileError ? "error" : "success"}
              help={this.state.fileError ? this.state.fileError : null}
              style={{ width: "48%" }}
            >
              <FormText>Sticker Image - 400px(w) x 400px(h) - 200KB</FormText>
              <StickerBackground>
                {this.state.function === "create" ? (
                  <>
                    {this.state.imageUrl && this.state.imageUrl.length > 0 ? (
                      <StickerImage src={this.state.imageUrl} />
                    ) : (
                      <Upload
                        accept=".bmp, .jpeg, .jpg, .png"
                        onChange={this.handleChangeImage}
                        showUploadList={false}
                      >
                        <EditImageButton
                          style={{ marginTop: 80, marginBottom: 80 }}
                        >
                          Upload an Image
                        </EditImageButton>
                      </Upload>
                    )}
                  </>
                ) : (
                  <StickerImage
                    src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_stickers%2F${this.state.selectedSticker.id}%2Fsticker.png?alt=media&token=${this.state.selectedSticker.image}`}
                  />
                )}
              </StickerBackground>
            </CustomForm.Item>
            <CustomForm.Item
              validateStatus={this.state.soundError ? "error" : "success"}
              help={this.state.soundError ? this.state.soundError : null}
              style={{ width: "48%", marginLeft: 20 }}
            >
              <FormText>Sound - 100KB</FormText>
              <StickerBackground style={{ flexDirection: "column" }}>
                <FormText
                  onClick={this.handlePlayUploadedSound}
                  style={{ color: "#0A84FF", cursor: "pointer" }}
                >
                  {this.renderSoundFileName()}
                </FormText>
                {!this.state.selectedSticker.sound && (
                  <Upload
                    accept=".mp3"
                    onChange={this.handleChangeSound}
                    showUploadList={false}
                  >
                    <EditImageButton>Upload Sound</EditImageButton>
                  </Upload>
                )}
              </StickerBackground>
            </CustomForm.Item>
          </FlexContentContainer>

          <CustomForm.Item
            validateStatus={this.state.nameError ? "error" : "success"}
            help={this.state.nameError ? this.state.nameError : null}
            style={{ width: "48%", marginTop: 10 }}
          >
            <FormText>Name</FormText>
            <Input
              id="name"
              value={
                this.state.selectedSticker.name
                  ? this.state.selectedSticker.name
                  : ""
              }
              onChange={this.handleChange}
              style={{ borderRadius: 10 }}
            />
          </CustomForm.Item>
          <CustomForm.Item
            validateStatus={this.state.pointsError ? "error" : "success"}
            help={this.state.pointsError ? this.state.pointsError : null}
            style={{ width: "48%", marginTop: 20 }}
          >
            <FormText>Points</FormText>
            <Input
              id="points"
              value={
                this.state.selectedSticker.points
                  ? this.state.selectedSticker.points
                  : ""
              }
              onChange={this.handleChange}
              style={{ borderRadius: 10 }}
            />
          </CustomForm.Item>
          <CustomForm.Item
            style={{ width: "48%", marginTop: 20 }}
            validateStatus={this.state.typeError ? "error" : "success"}
            help={this.state.typeError ? this.state.typeError : null}
          >
            <FormText>Sticker Type</FormText>
            <Select
              onChange={this.handleDropdown.bind(this, "type")}
              placeholder="Sticker Type"
              value={
                this.state.selectedSticker.type
                  ? this.state.selectedSticker.type
                  : undefined
              }
            >
              <Option value="narration">{"Speech & Drama"}</Option>
              <Option value="reading">Reading</Option>
            </Select>
          </CustomForm.Item>
        </CustomForm>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderStickers()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.stickers !== undefined && (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 50 >= this.props.stickers.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          )}
        </>
      );
  };

  render() {
    return (
      <>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createSticker: (data) => dispatch(createSticker(data)),
    deleteSticker: (data) => dispatch(deleteSticker(data)),
  };
};

export default connect(null, mapDispatchToProps)(Stickers);

const StickerImage = styled.img`
  height: 100px;
  margin: 10px;
`;

const StickerBackground = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 10px;
  background: rgb(251, 251, 255);
  display: flex;
  align-items: center;
  justify-content: center;
`;
