import React, { Component } from "react";
import { Input, Pagination } from "antd";
//Redux Firebase
import { connect } from "react-redux";
// Components
import {
  BackButton,
  NavBar,
  FlexContentContainer,
  TableLayout,
  TableContent,
  TableContentDefault,
  ContentContainer,
  TopBarSearch,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarBreadcrumb,
  TopBar,
  FormText,
  CustomForm,
} from "./Layout";
//Actions
import { updateAnalyticsQuery } from "../../store/actions/analyticsActions";

const columns = [
  { title: "Analytics", dataIndex: "analytics", key: "analytics" },
];

const recordingColumn = [{ title: "Name", dataIndex: "name", key: "name" }];

const narrationColumn = [
  {
    title: "Children ID",
    dataIndex: "childrenId",
    key: "childrenId",
    width: 500,
  },
  {
    title: "Number",
    dataIndex: "number",
    key: "number",
    width: 500,
  },
];

class Analytics extends Component {
  state = {
    function: "",
    search: "",
    selectedAnalytics: [],
    limit: 100,
    page: 1,
  };

  componentWillUnmount() {
    this.handleClearState();
    this.props.updateAnalyticsQuery(100, "");
  }

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedAnalytics: [],
        limit: 100,
        page: 1,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, data) => {
    this.setState({
      function: type,
      selectedAnalytics: data,
    });
  };

  handleSearch = (event) => {
    this.setState(
      {
        search: event.target.value,
        limit: 100,
        page: 1,
      },
      () => {
        clearInterval(this.timer);
        this.timer = setInterval(this.handleTimerSearch, 500);
      }
    );
  };

  handleTimerSearch = () => {
    this.props.updateAnalyticsQuery(100, this.state.search);
    clearInterval(this.timer);
  };

  handlePagination = (page) => {
    let newLimit = 100 * page;
    this.props.updateAnalyticsQuery(newLimit, this.state.search);

    this.setState({ page: page, limit: newLimit });
  };

  renderAnalytics = () => {
    let analyticsView = [];
    if (this.props.analytics && this.props.analytics.length > 0) {
      let min = (this.state.page - 1) * 100;
      let max = this.props.analytics.length;

      this.props.analytics.slice(min, max).map((eachAnalytics, index) => {
        analyticsView.push({
          key: index,
          analytics: (
            <TableContent
              onClick={this.handleFunction.bind(this, "edit", eachAnalytics)}
            >
              {eachAnalytics.id ? eachAnalytics.id : ""}
            </TableContent>
          ),
        });
      });
    }

    return analyticsView;
  };

  renderRecording = () => {
    let recordingView = [];
    let recording = this.state.selectedAnalytics.recording;
    if (recording && recording.length > 0) {
      recording.map((eachRecording, index) => {
        recordingView.push({
          key: index,
          name: <TableContentDefault>{eachRecording}</TableContentDefault>,
        });
      });
    }

    return recordingView;
  };

  renderNarration = () => {
    let narrationView = [];
    let narration = this.state.selectedAnalytics.narration;
    if (narration && narration.length > 0) {
      narration.map((eachNarration, index) => {
        narrationView.push({
          key: index,
          childrenId: (
            <TableContentDefault>{eachNarration.kidId}</TableContentDefault>
          ),
          number: (
            <TableContentDefault>{eachNarration.number}</TableContentDefault>
          ),
        });
      });
    }

    return narrationView;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "")} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "")}>
              Analytics
            </BreadcrumbLink>
            <BreadcrumbActive>Editing Analytics</BreadcrumbActive>
          </TopBarBreadcrumb>
          <div style={{ flex: 1 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Analytics</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  sec2time = (timeInSeconds) => {
    let pad = function (num, size) {
        return ("000" + num).slice(size * -1);
      },
      time = parseFloat(timeInSeconds).toFixed(3),
      hours = Math.floor(time / 60 / 60),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);

    return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
  };

  renderContent = () => {
    if (this.state.function)
      return (
        <CustomForm>
          <FlexContentContainer>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Analytics ID</FormText>
              <Input
                placeholder="Analytics ID"
                value={
                  this.state.selectedAnalytics.id
                    ? this.state.selectedAnalytics.id
                    : undefined
                }
                style={{ borderRadius: 10 }}
                disabled={true}
              />
            </CustomForm.Item>
            <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
              <FormText>Book ID</FormText>
              <Input
                placeholder="Book ID"
                value={
                  this.state.selectedAnalytics.bookId
                    ? this.state.selectedAnalytics.bookId
                    : undefined
                }
                style={{ borderRadius: 10 }}
                disabled={true}
              />
            </CustomForm.Item>
          </FlexContentContainer>
          <FlexContentContainer style={{ marginTop: 20 }}>
            <CustomForm.Item style={{ width: "48%" }}>
              <FormText>Children ID</FormText>
              <Input
                placeholder="Children ID"
                value={
                  this.state.selectedAnalytics.kidId
                    ? this.state.selectedAnalytics.kidId
                    : undefined
                }
                style={{ borderRadius: 10 }}
                disabled={true}
              />
            </CustomForm.Item>
            <CustomForm.Item style={{ width: "48%", marginLeft: 20 }}>
              <FormText>Screen Time</FormText>
              <Input
                placeholder="Screen Time"
                value={
                  this.state.selectedAnalytics.screenTime
                    ? this.sec2time(this.state.selectedAnalytics.screenTime)
                    : undefined
                }
                style={{ borderRadius: 10 }}
                disabled={true}
              />
            </CustomForm.Item>
          </FlexContentContainer>
          <FormText style={{ marginTop: 30 }}>
            Recording (Total :{" "}
            {this.state.selectedAnalytics.recording
              ? this.state.selectedAnalytics.recording.length
              : 0}
            )
          </FormText>
          <TableLayout
            style={{ marginTop: "0px", marginLeft: "0px", width: "100%" }}
            columns={recordingColumn}
            dataSource={this.renderRecording()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          <FormText style={{ marginTop: 30 }}>
            Narration (Total :{" "}
            {this.state.selectedAnalytics.narrationCount
              ? this.state.selectedAnalytics.narrationCount.length
              : 0}
            )
          </FormText>
          <TableLayout
            style={{ marginTop: "0px", marginLeft: "0px", width: "100%" }}
            columns={narrationColumn}
            dataSource={this.renderNarration()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
        </CustomForm>
      );
    else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderAnalytics()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.analytics !== undefined ? (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.props.analytics.length < this.state.limit
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          ) : null}
        </>
      );
  };

  render() {
    return (
      <>
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analytics: state.firestore.ordered.analytics,
    analyticStore: state.analyticStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAnalyticsQuery: (limit, search) =>
      dispatch(updateAnalyticsQuery(limit, search)),
  };
};

export default Analytics;
// export default compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   firestoreConnect((props) => {
//     let firestoreList = [];

//     if (props.analyticStore.search) {
//       let strSearch = props.analyticStore.search;
//       let strlength = strSearch.length;
//       let strFrontCode = strSearch.slice(0, strlength - 1);
//       let strEndCode = strSearch.slice(strlength - 1, strSearch.length);

//       let startcode = strSearch;
//       let endcode =
//         strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

//       firestoreList.push({
//         collection: "analytics",
//         where: [
//           ["id", ">=", startcode],
//           ["id", "<", endcode],
//         ],
//         limit: props.analyticStore.limit,
//       });
//     } else
//       firestoreList.push({
//         collection: "analytics",
//         limit: props.analyticStore.limit,
//       });

//     return firestoreList;
//   })
// )(Analytics);
