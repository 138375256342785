import React, { Component } from "react";
import styled from "styled-components";
import { Modal, Checkbox, Col } from "antd";
import { FormText, CustomForm } from "./Layout";
//Utils
import country from "../../utils/countryList";

export default class CountryModal extends Component {
  state = {
    indeterminate: {
      latinAmerica: false,
      europe: false,
      us: false,
      middleEast: false,
      asiaPacific: false,
    },
    checkAll: {
      latinAmerica: false,
      europe: false,
      us: false,
      middleEast: false,
      asiaPacific: false,
    },
    countryList: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible)
      this.handleCheckIndeterminateRegions();
  }

  handleCheckIndeterminateRegions = () => {
    this.setState({
      indeterminate: {
        latinAmerica: this.renderRegionValue("latinAmerica").length > 0,
        europe: this.renderRegionValue("europe").length > 0,
        us: this.renderRegionValue("us").length > 0,
        middleEast: this.renderRegionValue("middleEast").length > 0,
        asiaPacific: this.renderRegionValue("asiaPacific").length > 0,
      },
    });

    this.setState({
      checkAll: {
        latinAmerica:
          this.renderRegionValue("latinAmerica").length ===
          this.renderRegionAllCountries("latinAmerica").length,
        europe:
          this.renderRegionValue("europe").length ===
          this.renderRegionAllCountries("europe").length,
        us:
          this.renderRegionValue("us").length ===
          this.renderRegionAllCountries("us").length,
        middleEast:
          this.renderRegionValue("middleEast").length ===
          this.renderRegionAllCountries("middleEast").length,
        asiaPacific:
          this.renderRegionValue("asiaPacific").length ===
          this.renderRegionAllCountries("asiaPacific").length,
      },
    });
  };

  handleChangeRegionCountries = (region, checkedList) => {
    let tempBook = JSON.parse(JSON.stringify(this.props.selectedBook));

    let allCountriesValue = [];
    switch (region) {
      case "latinAmerica":
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("europe")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("us")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("middleEast")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("asiaPacific")
        );
        allCountriesValue = allCountriesValue.concat(checkedList);
        break;
      case "europe":
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("latinAmerica")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("us")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("middleEast")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("asiaPacific")
        );
        allCountriesValue = allCountriesValue.concat(checkedList);
        break;
      case "us":
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("latinAmerica")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("europe")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("middleEast")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("asiaPacific")
        );
        allCountriesValue = allCountriesValue.concat(checkedList);
        break;
      case "middleEast":
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("latinAmerica")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("europe")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("us")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("asiaPacific")
        );
        allCountriesValue = allCountriesValue.concat(checkedList);
        break;
      case "asiaPacific":
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("latinAmerica")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("europe")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("us")
        );
        allCountriesValue = allCountriesValue.concat(
          this.renderRegionValue("middleEast")
        );
        allCountriesValue = allCountriesValue.concat(checkedList);
        break;
    }

    if (this.props.visible === "free") tempBook["country"] = allCountriesValue;
    else if (this.props.visible === "subscription")
      tempBook["subscriptionCountry"] = allCountriesValue;
    else if (this.props.visible === "perpetual")
      tempBook["perpetualCountry"] = allCountriesValue;

    this.props.handleUpdateCountry(tempBook);

    //INFO : Check indeterminate
    let indeterminateClass = JSON.parse(
      JSON.stringify(this.state.indeterminate)
    );
    if (checkedList.length === 0) indeterminateClass[region] = false;
    else indeterminateClass[region] = true;

    this.setState({
      indeterminate: indeterminateClass,
    });

    //INFO : Check All
    let checkAllClass = JSON.parse(JSON.stringify(this.state.checkAll));
    if (checkedList.length === this.renderRegionAllCountries(region).length)
      checkAllClass[region] = true;
    else checkAllClass[region] = false;

    this.setState({
      checkAll: checkAllClass,
    });
  };

  handleCheckAllRegionCountries = (region, event) => {
    this.handleChangeRegionCountries(
      region,
      this.renderRegionAllCountries(region, true)
    );

    let checkAllClass = JSON.parse(JSON.stringify(this.state.checkAll));
    checkAllClass[region] = event.target.checked;
    this.setState(
      {
        checkAll: checkAllClass,
      },
      () => {
        if (!event.target.checked) {
          this.handleChangeRegionCountries(region, []);
          let indeterminateClass = JSON.parse(
            JSON.stringify(this.state.indeterminate)
          );
          indeterminateClass[region] = false;
          this.setState({
            indeterminate: indeterminateClass,
          });
        }
      }
    );
  };

  handleCheckCountryAvailability = (countryCode) => {
    let countryAvailable = false;

    if (
      this.props.visible === "free" &&
      (this.props.selectedBook.subscriptionCountry.includes(countryCode) ||
        this.props.selectedBook.perpetualCountry.includes(countryCode))
    )
      countryAvailable = false;
    else if (
      this.props.visible === "subscription" &&
      (this.props.selectedBook.country.includes(countryCode) ||
        this.props.selectedBook.perpetualCountry.includes(countryCode))
    )
      countryAvailable = false;
    else if (
      this.props.visible === "perpetual" &&
      (this.props.selectedBook.country.includes(countryCode) ||
        this.props.selectedBook.subscriptionCountry.includes(countryCode))
    )
      countryAvailable = false;
    else countryAvailable = true;

    return countryAvailable;
  };

  renderRegionAllCountries = (type, valueOnly) => {
    let latinAmerica = [];
    let latinAmericaValue = [];
    let europe = [];
    let europeValue = [];
    let us = [];
    let usValue = [];
    let middleEast = [];
    let middleEastValue = [];
    let asiaPacific = [];
    let asiaPacificValue = [];

    country.map((eachCountry) => {
      if (eachCountry.region === "Latin America and the Caribbean") {
        if (this.handleCheckCountryAvailability(eachCountry.value)) {
          latinAmerica.push(eachCountry);
          latinAmericaValue.push(eachCountry.value);
        }
      } else if (eachCountry.region === "Europe") {
        if (this.handleCheckCountryAvailability(eachCountry.value)) {
          europe.push(eachCountry);
          europeValue.push(eachCountry.value);
        }
      } else if (eachCountry.region === "The United States and Canada") {
        if (this.handleCheckCountryAvailability(eachCountry.value)) {
          us.push(eachCountry);
          usValue.push(eachCountry.value);
        }
      } else if (eachCountry.region === "Africa, Middle East, and India") {
        if (this.handleCheckCountryAvailability(eachCountry.value)) {
          middleEast.push(eachCountry);
          middleEastValue.push(eachCountry.value);
        }
      } else if (eachCountry.region === "Asia Pacific") {
        if (this.handleCheckCountryAvailability(eachCountry.value)) {
          asiaPacific.push(eachCountry);
          asiaPacificValue.push(eachCountry.value);
        }
      }
    });

    if (valueOnly) {
      if (type === "latinAmerica") return latinAmericaValue;
      else if (type === "europe") return europeValue;
      else if (type === "us") return usValue;
      else if (type === "middleEast") return middleEastValue;
      else if (type === "asiaPacific") return asiaPacificValue;
      else return [];
    } else {
      if (type === "latinAmerica") return latinAmerica;
      else if (type === "europe") return europe;
      else if (type === "us") return us;
      else if (type === "middleEast") return middleEast;
      else if (type === "asiaPacific") return asiaPacific;
      else return [];
    }
  };

  renderRegionValue = (region) => {
    if (this.props.visible) {
      let selectedBookCountryValue = [];
      if (this.props.visible === "free")
        selectedBookCountryValue = this.props.selectedBook.country;
      else if (this.props.visible === "subscription")
        selectedBookCountryValue = this.props.selectedBook.subscriptionCountry;
      else if (this.props.visible === "perpetual")
        selectedBookCountryValue = this.props.selectedBook.perpetualCountry;

      if (selectedBookCountryValue.length > 0) {
        let regionCountry = [];
        selectedBookCountryValue.map((eachCountryId) => {
          let countryRegion;
          country.map((eachCountry) => {
            if (eachCountry.value === eachCountryId)
              countryRegion = eachCountry.region;
          });

          if (
            region === "latinAmerica" &&
            countryRegion === "Latin America and the Caribbean"
          )
            regionCountry.push(eachCountryId);
          else if (region === "europe" && countryRegion === "Europe")
            regionCountry.push(eachCountryId);
          else if (
            region === "us" &&
            countryRegion === "The United States and Canada"
          )
            regionCountry.push(eachCountryId);
          else if (
            region === "middleEast" &&
            countryRegion === "Africa, Middle East, and India"
          )
            regionCountry.push(eachCountryId);
          else if (region === "asiaPacific" && countryRegion === "Asia Pacific")
            regionCountry.push(eachCountryId);
        });
        return regionCountry;
      } else return [];
    } else return [];
  };

  renderCountryModalContent = () => {
    return (
      <CustomForm style={{ marginTop: 0, marginBottom: 20 }}>
        <DividerContainer>
          <CheckboxContainer
            indeterminate={this.state.indeterminate.latinAmerica}
            onChange={this.handleCheckAllRegionCountries.bind(
              this,
              "latinAmerica"
            )}
            checked={this.state.checkAll.latinAmerica}
          >
            <Text> Latin America and the Caribbean</Text>
          </CheckboxContainer>
        </DividerContainer>
        <br />

        <Checkbox.Group
          name="latinAmerica"
          options={this.renderRegionAllCountries("latinAmerica")}
          value={this.renderRegionValue("latinAmerica")}
          onChange={this.handleChangeRegionCountries.bind(this, "latinAmerica")}
        />
        <DividerContainer>
          <CheckboxContainer
            indeterminate={this.state.indeterminate.europe}
            onChange={this.handleCheckAllRegionCountries.bind(this, "europe")}
            checked={this.state.checkAll.europe}
          >
            <Text> Europe</Text>
          </CheckboxContainer>
        </DividerContainer>
        <br />
        <Checkbox.Group
          name="europe"
          options={this.renderRegionAllCountries("europe")}
          value={this.renderRegionValue("europe")}
          onChange={this.handleChangeRegionCountries.bind(this, "europe")}
        />
        <DividerContainer>
          <CheckboxContainer
            indeterminate={this.state.indeterminate.us}
            onChange={this.handleCheckAllRegionCountries.bind(this, "us")}
            checked={this.state.checkAll.us}
          >
            <Text>The United States and Canada</Text>
          </CheckboxContainer>
        </DividerContainer>
        <br />
        <Checkbox.Group
          name="us"
          options={this.renderRegionAllCountries("us")}
          value={this.renderRegionValue("us")}
          onChange={this.handleChangeRegionCountries.bind(this, "us")}
        />
        <DividerContainer>
          <CheckboxContainer
            indeterminate={this.state.indeterminate.middleEast}
            onChange={this.handleCheckAllRegionCountries.bind(
              this,
              "middleEast"
            )}
            checked={this.state.checkAll.middleEast}
          >
            <Text> Africa, Middle East, and India</Text>
          </CheckboxContainer>
        </DividerContainer>
        <br />
        <Checkbox.Group
          name="middleEast"
          options={this.renderRegionAllCountries("middleEast")}
          value={this.renderRegionValue("middleEast")}
          onChange={this.handleChangeRegionCountries.bind(this, "middleEast")}
        />
        <DividerContainer>
          <CheckboxContainer
            indeterminate={this.state.indeterminate.asiaPacific}
            onChange={this.handleCheckAllRegionCountries.bind(
              this,
              "asiaPacific"
            )}
            checked={this.state.checkAll.asiaPacific}
          >
            <Text>Asia Pacific</Text>
          </CheckboxContainer>
        </DividerContainer>
        <br />
        <Checkbox.Group
          name="asiaPacific"
          options={this.renderRegionAllCountries("asiaPacific")}
          value={this.renderRegionValue("asiaPacific")}
          onChange={this.handleChangeRegionCountries.bind(this, "asiaPacific")}
        />
      </CustomForm>
    );
  };

  renderTitle = () => {
    let title = "";
    if (this.props.visible === "free") title = "Allowed Countries - Free";
    else if (this.props.visible === "subscription")
      title = "Allowed Countries - Subscription";
    else if (this.props.visible === "perpetual")
      title = "Allowed Countries - Perpetual";

    return title;
  };

  render() {
    return (
      <Modal
        title={this.renderTitle()}
        className="countryModal"
        footer={null}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
      >
        {this.renderCountryModalContent()}
      </Modal>
    );
  }
}

const DividerContainer = styled.div`
  border-bottom: 1px solid #e9e9e9;
  margin-top: 30px;
`;

const CheckboxContainer = styled(Checkbox)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px;
  margin-left: 0px;
`;

export const Text = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #000;
`;
