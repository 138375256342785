import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
const eventBus = EventBus();
const uuid = require("uuid/v4");

export const createCategories = (data) => {
  return async (dispatch, getState) => {
    try {
      let uniqueId = `categories_${uuid()}`;
      const firestore = firebase.firestore();
      let verifyCategories = firestore.collection("categories").doc(uniqueId);
      let verifyCategoriesDoc = await verifyCategories.get();

      if (!verifyCategoriesDoc.exists) {
        await firebase
          .storage()
          .ref(`assets_categories/${uniqueId}/${data.avatarFile.name}`)
          .put(data.avatarFile);
        let avatarUrl = await firebase
          .storage()
          .ref(`assets_categories/${uniqueId}`)
          .child(data.avatarFile.name)
          .getDownloadURL();
        await firebase
          .storage()
          .ref(`assets_categories/${uniqueId}/${data.iconFile.name}`)
          .put(data.iconFile);
        let iconUrl = await firebase
          .storage()
          .ref(`assets_categories/${uniqueId}`)
          .child(data.iconFile.name)
          .getDownloadURL();
        await firebase
          .storage()
          .ref(`assets_categories/${uniqueId}/${data.soundFile.name}`)
          .put(data.soundFile);
        let soundUrl = await firebase
          .storage()
          .ref(`assets_categories/${uniqueId}`)
          .child(data.soundFile.name)
          .getDownloadURL();
        await firestore
          .collection("categories")
          .doc(uniqueId)
          .set({
            id: uniqueId,
            discoverName: data.selectedCategories.discoverName.trim(),
            discoverShow: data.selectedCategories.discoverShow,
            description: data.selectedCategories.description.trim(),
            conditions: data.selectedCategories.conditions,
            priority: Number(data.selectedCategories.priority),
            avatar: avatarUrl.split("token=")[1],
            selectionIcon: iconUrl.split("token=")[1],
            sound: soundUrl.split("token=")[1],
            updatedAt: new Date(),
          });

        eventBus.emit("categories-function-success");
      } else
        eventBus.emit(
          "categories-function-error",
          null,
          "The categories has been created"
        );
    } catch (err) {
      eventBus.emit("categories-function-error", null, err);
    }
  };
};

export const editCategories = (data) => {
  return async (dispatch, getState) => {
    try {
      let id = data.selectedCategories.id;
      const firestore = firebase.firestore();
      let verifyCategories = firestore.collection("categories").doc(id);

      if (data.avatarFile) {
        await firebase
          .storage()
          .ref(`assets_categories/${id}`)
          .child("avatar.png")
          .delete();
        await firebase
          .storage()
          .ref(`assets_categories/${id}/${data.avatarFile.name}`)
          .put(data.avatarFile);
        let avatarUrl = await firebase
          .storage()
          .ref(`assets_categories/${id}`)
          .child(data.avatarFile.name)
          .getDownloadURL();
        await verifyCategories.update({
          avatar: avatarUrl.split("token=")[1],
        });
      }

      if (data.iconFile) {
        await firebase
          .storage()
          .ref(`assets_categories/${id}`)
          .child("selection_icon.png")
          .delete();
        await firebase
          .storage()
          .ref(`assets_categories/${id}/${data.iconFile.name}`)
          .put(data.iconFile);
        let iconUrl = await firebase
          .storage()
          .ref(`assets_categories/${id}`)
          .child(data.iconFile.name)
          .getDownloadURL();
        await verifyCategories.update({
          selectionIcon: iconUrl.split("token=")[1],
        });
      }

      if (data.soundFile) {
        await firebase
          .storage()
          .ref(`assets_categories/${id}/${data.soundFile.name}`)
          .put(data.soundFile);
        let soundUrl = await firebase
          .storage()
          .ref(`assets_categories/${id}`)
          .child(data.soundFile.name)
          .getDownloadURL();
        await verifyCategories.update({
          sound: soundUrl.split("token=")[1],
        });
      }

      await verifyCategories.update({
        // homeName: data.selectedCategories.homeName.trim(),
        discoverName: data.selectedCategories.discoverName.trim(),
        discoverShow: data.selectedCategories.discoverShow,
        description: data.selectedCategories.description.trim(),
        conditions: data.selectedCategories.conditions,
        priority: Number(data.selectedCategories.priority),
        updatedAt: new Date(),
        // show: data.selectedCategories.show,
      });
      eventBus.emit("categories-function-success");
    } catch (err) {
      eventBus.emit("categories-function-error", null, err);
    }
  };
};

export const deleteCategories = (data) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifyCategories = firestore.collection("categories").doc(data);
      await verifyCategories.delete();
      await firebase
        .storage()
        .ref(`assets_categories/${data}`)
        .child("avatar.png")
        .delete();
      await firebase
        .storage()
        .ref(`assets_categories/${data}`)
        .child("selection_icon.png")
        .delete();
      await firebase
        .storage()
        .ref(`assets_categories/${data}`)
        .child("sound.mp3")
        .delete();
      eventBus.emit("categories-function-success");
    } catch (err) {
      eventBus.emit("categories-function-error", null, err);
    }
  };
};
