import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
const eventBus = EventBus();
const uuid = require("uuid/v4");

export const createSeries = (data) => {
  return async (dispatch, getState) => {
    try {
      let uniqueId = `series_${uuid()}`;
      const firestore = firebase.firestore();
      let verifySeries = firestore.collection("series").doc(uniqueId);
      let verifySeriesDoc = await verifySeries.get();

      let tempBackgroundImage = "";

      if (!verifySeriesDoc.exists) {
        if (data.backgroundImageFile) {
          await firebase
            .storage()
            .ref(`assets_series/${uniqueId}/${data.backgroundImageFile.name}`)
            .put(data.backgroundImageFile);
          let backgroundUrl = await firebase
            .storage()
            .ref(`assets_series/${uniqueId}`)
            .child(data.backgroundImageFile.name)
            .getDownloadURL();
          tempBackgroundImage = backgroundUrl.split("token=")[1];
        }

        await firebase
          .storage()
          .ref(`assets_series/${uniqueId}/${data.imageFile.name}`)
          .put(data.imageFile);
        let url = await firebase
          .storage()
          .ref(`assets_series/${uniqueId}`)
          .child(data.imageFile.name)
          .getDownloadURL();

        await firebase
          .storage()
          .ref(`assets_series/${uniqueId}/${data.soundFile.name}`)
          .put(data.soundFile);
        let soundUrl = await firebase
          .storage()
          .ref(`assets_series/${uniqueId}`)
          .child(data.soundFile.name)
          .getDownloadURL();

        await firestore
          .collection("series")
          .doc(uniqueId)
          .set({
            id: uniqueId,
            name: data.name.trim(),
            books: data.books,
            description: data.description.trim(),
            image: url.split("token=")[1],
            backgroundImage: tempBackgroundImage,
            sound: soundUrl.split("token=")[1],
            tags: data.tags,
            character: data.character,
            priority: data.priority,
          });

        eventBus.emit("series-function-success");
      } else
        eventBus.emit(
          "series-function-error",
          null,
          "The series has been created"
        );
    } catch (err) {
      eventBus.emit("series-function-error", null, err);
    }
  };
};

export const editSeries = (data) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifySeries = firestore.collection("series").doc(data.id);

      if (data.imageFile) {
        await firebase
          .storage()
          .ref(`assets_series/${data.id}/${data.imageFile.name}`)
          .put(data.imageFile);
        let url = await firebase
          .storage()
          .ref(`assets_series/${data.id}`)
          .child(data.imageFile.name)
          .getDownloadURL();
        await verifySeries.update({
          image: url.split("token=")[1],
        });
      }

      if (data.backgroundImageFile) {
        await firebase
          .storage()
          .ref(`assets_series/${data.id}/${data.backgroundImageFile.name}`)
          .put(data.backgroundImageFile);
        let backgroundUrl = await firebase
          .storage()
          .ref(`assets_series/${data.id}`)
          .child(data.backgroundImageFile.name)
          .getDownloadURL();
        await verifySeries.update({
          backgroundImage: backgroundUrl.split("token=")[1],
        });
      }

      if (data.soundFile) {
        await firebase
          .storage()
          .ref(`assets_series/${data.id}/${data.soundFile.name}`)
          .put(data.soundFile);
        let soundUrl = await firebase
          .storage()
          .ref(`assets_series/${data.id}`)
          .child(data.soundFile.name)
          .getDownloadURL();
        await verifySeries.update({
          sound: soundUrl.split("token=")[1],
        });
      }

      await verifySeries.update({
        name: data.name,
        tags: data.tags,
        books: data.books,
        description: data.description.trim(),
        character: data.character,
        priority: data.priority,
      });

      eventBus.emit("series-function-success");
    } catch (err) {
      eventBus.emit("series-function-error", null, err);
    }
  };
};

export const deleteSeries = (data) => {
  return async (dispatch, getState) => {
    try {
      const firestore = firebase.firestore();
      let verifySeries = firestore.collection("series").doc(data.id);
      await verifySeries.delete();
      await firebase
        .storage()
        .ref(`assets_series/${data.id}`)
        .child("image.png")
        .delete();
      await firebase
        .storage()
        .ref(`assets_series/${data.id}`)
        .child("sound.mp3")
        .delete();

      if (data.backgroundImage)
        await firebase
          .storage()
          .ref(`assets_series/${data.id}`)
          .child("backgroundImage.png")
          .delete();
      eventBus.emit("series-function-success");
    } catch (err) {
      eventBus.emit("series-function-error", null, err);
    }
  };
};
