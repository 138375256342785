const initState = {
  userAuth: null,
  userLoading: true,
  authError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        authError: action.err.message,
      };
    case "UPDATE_USER_AUTH":
      return {
        ...state,
        userAuth: action.payload.userAuth,
        userLoading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
