import React, { Component } from "react";
import { Select, Input, Pagination } from "antd";
//Redux Firebase
import { connect } from "react-redux";
//Actions
import {
  createCollection,
  deleteCollection,
  editCollection,
} from "../../store/actions/collectionActions";
// Components
import {
  NavBar,
  BackButton,
  FlexContentContainer,
  TableLayout,
  TableContent,
  ContentContainer,
  TopBarBreadcrumb,
  BreadcrumbActive,
  BreadcrumbLink,
  TopBarSearch,
  TopBarIcon,
  TopBarDeleteButton,
  TopBarSaveButton,
  TopBar,
  FormText,
  CustomForm,
} from "./Layout";
import { SaveDialog, DeleteDialog } from "../Dialog";
//Utils
import EventBus from "js-event-bus";

const { Option } = Select;
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 500,
  },
  {
    title: "Number of Categories",
    dataIndex: "categories",
    key: "categories",
    width: 500,
  },
];

const eventBus = EventBus();

class Collections extends Component {
  state = {
    page: 1,
    function: "",
    search: "",
    selectedCollection: "",
    nameError: "",
    categoriesError: "",
    priorityError: "",
    deleteModal: false,
  };

  componentWillUnmount() {
    this.handleClearState();
    eventBus.detach("collection-function-success");
    eventBus.detach("collection-function-error");
  }

  componentDidMount() {
    eventBus.on("collection-function-success", this.handleClearState);
    eventBus.on("collection-function-error", this.handleError);
  }

  handleError = (err) => {
    this.setState(
      {
        fileError: err,
        nameError: err,
        booksError: err,
        descriptionError: err,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleClearState = () => {
    this.setState(
      {
        function: "",
        search: "",
        selectedCollection: "",
        nameError: "",
        categoriesError: "",
        priorityError: "",
        deleteModal: false,
      },
      () => {
        this.props.handleLoading(false);
      }
    );
  };

  handleFunction = (type, collectionData) => {
    let tempCollection = "";
    if (collectionData)
      tempCollection = JSON.parse(JSON.stringify(collectionData));
    else
      tempCollection = {
        name: "",
        priority: "",
        categories: [],
      };

    this.handleClearState();
    this.setState(
      {
        function: type,
        selectedCollection: tempCollection,
      },
      () => {
        this.props.handleDataChanged(false);
      }
    );
  };

  handleChange = (event) => {
    let tempCollection = this.state.selectedCollection;
    tempCollection[event.target.id] = event.target.value;

    this.setState(
      {
        selectedCollection: tempCollection,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleDropdown = (id, value) => {
    let tempCollection = this.state.selectedCollection;
    tempCollection[id] = value;
    this.setState(
      {
        selectedCollection: tempCollection,
      },
      () => {
        this.props.handleDataChanged(true);
      }
    );
  };

  handleSubmit = (type) => {
    this.props.handleLoading(true);

    if (!this.state.selectedCollection.name)
      this.setState({ nameError: "Please insert a name" });
    else this.setState({ nameError: null });

    if (
      !this.state.selectedCollection.categories ||
      this.state.selectedCollection.categories.length < 1
    )
      this.setState({ categoriesError: "Please select some categories" });
    else this.setState({ categoriesError: null });

    if (!this.state.selectedCollection.priority)
      this.setState({ priorityError: "Please insert a priority" });
    else if (isNaN(this.state.selectedCollection.priority))
      this.setState({ priorityError: "Priority should be a number only" });
    else this.setState({ priorityError: null });

    setTimeout(() => {
      if (
        !this.state.nameError &&
        !this.state.categoriesError &&
        !this.state.priorityError
      ) {
        if (this.state.function === "create")
          this.props.createCollection({
            name: this.state.selectedCollection.name,
            categories: this.state.selectedCollection.categories,
            priority: Number(this.state.selectedCollection.priority),
          });
        else
          this.props.editCollection({
            id: this.state.selectedCollection.id,
            name: this.state.selectedCollection.name,
            categories: this.state.selectedCollection.categories,
            priority: Number(this.state.selectedCollection.priority),
          });
        this.props.handleDataChanged(false);
      } else this.props.handleLoading(false);
    }, 500);
  };

  handleDelete = (type) => {
    if (type === "open") this.setState({ deleteModal: true });
    else if (type === "close") this.setState({ deleteModal: false });
    else {
      this.props.handleLoading(true);
      this.props.deleteCollection({
        id: this.state.selectedCollection.id,
        backgroundImage: this.state.selectedCollection.backgroundImage
          ? true
          : false,
      });
      this.props.handleDataChanged(false);
      this.setState({ deleteModal: false });
    }
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
      page: 1,
    });
  };

  handlePagination = (page) => {
    this.setState({ page: page });
  };

  renderDeleteButton = () => {
    if (this.state.function === "edit") {
      let staticCollection = ["0f37fb87-414c-4c76-b0ec-508fddd41997"];
      if (!staticCollection.includes(this.state.selectedCollection.id))
        return (
          <TopBarDeleteButton onClick={this.handleDelete.bind(this, "open")}>
            Delete
          </TopBarDeleteButton>
        );
    }
  };

  renderCollections = () => {
    let collectionsView = [];
    let collections = [];

    if (this.props.collections && this.props.collections.length > 0) {
      let min = (this.state.page - 1) * 100;
      let max =
        this.state.page * 100 > this.props.collections.length
          ? this.props.collections.length
          : this.state.page * 100;
      if (this.state.search) collections = this.props.collections;
      else collections = this.props.collections.slice(min, max);
      collections.map((eachCollection, index) => {
        if (
          eachCollection &&
          eachCollection.name
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        )
          collectionsView.push({
            key: index,
            categories: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachCollection)}
              >
                {eachCollection.categories.length}
              </TableContent>
            ),
            name: (
              <TableContent
                onClick={this.handleFunction.bind(this, "edit", eachCollection)}
              >
                {eachCollection.name}
              </TableContent>
            ),
          });
      });
    }

    return collectionsView;
  };

  renderCategoriesSelection = () => {
    let categoryList = [];
    if (this.props.categories && this.props.categories.length > 0)
      this.props.categories.map((eachCategory, index) => {
        categoryList.push(
          <Option key={index} value={eachCategory.id}>
            {eachCategory.discoverName}
          </Option>
        );
      });
    return categoryList;
  };

  renderTagSelection = () => {
    let tagList = [];
    if (this.props.tags && this.props.tags.length > 0)
      this.props.tags.map((eachTag, index) => {
        tagList.push(
          <Option key={index} value={eachTag.id}>
            {eachTag.title}
          </Option>
        );
      });
    return tagList;
  };

  renderTopBarContent = () => {
    if (this.state.function)
      return (
        <>
          <BackButton onClick={this.handleFunction.bind(this, "", null)} />
          <TopBarBreadcrumb>
            <BreadcrumbLink onClick={this.handleFunction.bind(this, "", null)}>
              Collections
            </BreadcrumbLink>
            <BreadcrumbActive>
              {this.state.function === "edit"
                ? "Editing Collections"
                : "Creating Collections"}
            </BreadcrumbActive>
          </TopBarBreadcrumb>

          <div style={{ flex: 1 }} />
          {this.renderDeleteButton()}
          <TopBarSaveButton
            onClick={this.handleSubmit.bind(this, this.state.function)}
          >
            Save
          </TopBarSaveButton>
          <div style={{ width: 30 }} />
        </>
      );
    else
      return (
        <>
          <TopBarBreadcrumb style={{ left: 20 }}>
            <BreadcrumbActive>Collections</BreadcrumbActive>
          </TopBarBreadcrumb>
          <TopBarIcon
            onClick={this.handleFunction.bind(this, "create", null)}
            style={{ left: 130 }}
          />
          <TopBarSearch
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search"
          />
        </>
      );
  };

  renderContent = () => {
    if (this.state.function) {
      return (
        <CustomForm>
          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.nameError ? "error" : "success"}
              help={this.state.nameError ? this.state.nameError : null}
              style={{ width: "48%" }}
            >
              <FormText>Name</FormText>
              <Input
                id="name"
                value={
                  this.state.selectedCollection.name
                    ? this.state.selectedCollection.name
                    : ""
                }
                onChange={this.handleChange}
                style={{ borderRadius: 10 }}
              />
            </CustomForm.Item>
            <CustomForm.Item
              validateStatus={this.state.priorityError ? "error" : "success"}
              help={this.state.priorityError ? this.state.priorityError : null}
              style={{ width: "48%", marginLeft: 20 }}
            >
              <FormText>Priority</FormText>
              <Input
                id="priority"
                value={
                  this.state.selectedCollection.priority
                    ? this.state.selectedCollection.priority
                    : ""
                }
                onChange={this.handleChange}
                style={{ borderRadius: 10 }}
              />
            </CustomForm.Item>
          </FlexContentContainer>

          <FlexContentContainer>
            <CustomForm.Item
              validateStatus={this.state.categoriesError ? "error" : "success"}
              help={
                this.state.categoriesError ? this.state.categoriesError : null
              }
              style={{ marginTop: 20, width: "48%" }}
            >
              <FormText>Categories</FormText>
              <Select
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={this.handleDropdown.bind(this, "categories")}
                placeholder="Add Categories"
                value={
                  this.state.selectedCollection.categories
                    ? this.state.selectedCollection.categories
                    : []
                }
              >
                {this.renderCategoriesSelection()}
              </Select>
            </CustomForm.Item>
          </FlexContentContainer>
        </CustomForm>
      );
    } else
      return (
        <>
          <TableLayout
            columns={columns}
            dataSource={this.renderCollections()}
            pagination={false}
            scroll={{ y: window.innerHeight - 250 }}
          />
          {this.props.collections !== undefined && (
            <NavBar>
              <Pagination
                current={this.state.page}
                pageSize={1}
                onChange={this.handlePagination}
                total={
                  this.state.page * 100 >= this.props.collections.length ||
                  this.state.search
                    ? this.state.page
                    : this.state.page + 1
                }
              />
            </NavBar>
          )}
        </>
      );
  };

  render() {
    return (
      <>
        <DeleteDialog
          handleDelete={this.handleDelete}
          deleteModal={this.state.deleteModal}
        />
        <SaveDialog
          history={this.props.history}
          switchPage={this.props.switchPage}
          visible={this.props.switchPage ? true : false}
          handleSwitchPage={this.props.handleSwitchPage}
          handleSubmit={this.handleSubmit}
        />
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createCollection: (data) => dispatch(createCollection(data)),
    editCollection: (data) => dispatch(editCollection(data)),
    deleteCollection: (data) => dispatch(deleteCollection(data)),
  };
};

export default connect(null, mapDispatchToProps)(Collections);
