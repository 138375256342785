import React, { Component } from "react";
import { Select, Input, InputNumber, Modal, Pagination, Button } from "antd";
import styled from "styled-components";
//Components
import {
  NavBar,
  TableLayout,
  TableContentDefault,
  TopBarSearch,
  TopBar,
  ContentContainer,
  CustomForm,
  FormText,
  TopBarBreadcrumb,
  BreadcrumbActive,
  TopBarIcon,
} from "./Layout";
//Utils
import axios from "axios";

const { Option } = Select;

const columns = [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    width: 100,
  },
  {
    title: "Code Type",
    dataIndex: "type",
    key: "type",
    width: 100,
  },
  {
    title: "Used by",
    dataIndex: "usedBy",
    key: "usedBy",
    width: 100,
  },
];

const downloadableCodes = (codesList, type) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += "No,Code,Type\n";
  codesList.forEach((eachCode, index) => {
    csvContent += index + 1 + "," + eachCode + "," + type + "\n";
  });
  return encodeURI(csvContent);
};

class RedeemCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      function: "",
      search: "",
      redeemCodes: [],
      codeLength: 0,
      generate: false,
      generateClass: {
        codeType: "onemonth",
        prefix: "",
        count: 10,
        length: 6,
      },
      generateClassError: {
        prefix: "",
        count: "",
        length: "",
      },
      loading: false,
      data: {
        title: "",
        description: "",
      },
      page: 1,
    };
    this.timer = null;
  }

  componentDidMount = async () => {
    this.handleGetCodeLength();
    this.handleGetCode(100, 0);
  };

  handleGenerateCode = () => {
    let tempGenerateClassError = this.state.generateClassError;
    //INFO : Check prefix condition
    if (this.state.generateClass.prefix.length <= 0)
      tempGenerateClassError["prefix"] =
        "Please insert characters only for your code prefix";
    else if (!this.state.generateClass.prefix.match(/^[A-Za-z]+$/))
      tempGenerateClassError["prefix"] = "Only characters are only allowed";
    else if (this.state.generateClass.prefix.length >= 6)
      tempGenerateClassError["prefix"] =
        "Only 5 characters for prefix are allowed";
    else tempGenerateClassError["prefix"] = "";

    //INFO : Check count condition
    if (this.state.generateClass.count === null)
      tempGenerateClassError["count"] =
        "Please insert number of promo code generated";
    else if (this.state.generateClass.count <= 0)
      tempGenerateClassError["count"] = "Value must be greater than 0";
    else if (this.state.generateClass.count >= 1001)
      tempGenerateClassError["count"] =
        "You can only generate 1000 codes max at once";
    else tempGenerateClassError["count"] = "";

    //INFO : Check promo code length
    if (this.state.generateClass.length === null)
      tempGenerateClassError["length"] =
        "Please insert length of promo code generated";
    else if (this.state.generateClass.length <= 5)
      tempGenerateClassError["length"] = "Value must be greater than 5";
    else if (this.state.generateClass.length >= 10)
      tempGenerateClassError["length"] = "Value must be lesser than 10";
    else tempGenerateClassError["length"] = "";

    this.setState(
      {
        generateClassError: tempGenerateClassError,
      },
      () => {
        if (
          !this.state.generateClassError["count"] &&
          !this.state.generateClassError["prefix"] &&
          !this.state.generateClassError["length"]
        )
          this.setState(
            {
              loading: true,
              button: false,
              cancelButton: false,
            },
            async () => {
              try {
                let codeResponse = await axios.post(
                  "https://us-central1-mebooks-plus.cloudfunctions.net/generateCodes",
                  {
                    type: this.state.generateClass.codeType,
                    count: this.state.generateClass.count,
                    length: this.state.generateClass.length,
                    prefix: this.state.generateClass.prefix,
                  }
                );
                if (codeResponse.data)
                  this.setState(
                    {
                      loading: false,
                      data: {
                        title: `Generate Success (Total : ${codeResponse.data.total})`,
                        description: `Generated codes are being downloaded on your browser`,
                      },
                      button: true,
                      cancelButton: false,
                    },
                    () => {
                      window.open(
                        downloadableCodes(
                          codeResponse.data.generatedCodes,
                          codeResponse.data.type
                        )
                      );
                    }
                  );
              } catch (err) {
                this.setState({
                  loading: false,
                  data: {
                    title: "Generate Error",
                    description: err.response.data.error.description,
                  },
                  button: true,
                  cancelButton: true,
                });
              }
            }
          );
      }
    );
  };

  handleGetCodeLength = async () => {
    let codeLengthRes = await axios.get(
      "https://us-central1-mebooks-plus.cloudfunctions.net/getCodeLength"
    );
    if (codeLengthRes.data)
      this.setState({
        codeLength: Object.values(codeLengthRes.data[0])[0],
      });
  };

  handleGetCode = async (limit, offset) => {
    let codeRes = await axios.post(
      "https://us-central1-mebooks-plus.cloudfunctions.net/getCodes",
      {
        limit: limit,
        offset: offset,
      }
    );
    if (codeRes.data) {
      this.setState({
        redeemCodes: codeRes.data,
      });
    }
  };

  handleSearch = (event) => {
    this.setState(
      {
        search: event.target.value,
        redeemCodes: [],
        page: 1,
      },
      () => {
        clearInterval(this.timer);
        this.timer = setInterval(this.handleTimerSearch, 500);
      }
    );
  };

  handleTimerSearch = () => {
    if (this.state.search === "") this.handleGetCode(100, 0);
    else this.handleSearchAPI(this.state.search);
    clearInterval(this.timer);
  };

  handleSearchAPI = async (data) => {
    let codeRes = await axios.post(
      "https://us-central1-mebooks-plus.cloudfunctions.net/searchCodes",
      {
        search: data,
      }
    );
    if (codeRes.data)
      this.setState({
        redeemCodes: codeRes.data,
      });
  };

  handleGenerateDialog = (bool, force) => {
    if (
      (!this.state.loading &&
        !this.state.data.title &&
        !this.state.data.description) ||
      force
    )
      this.setState({
        generate: bool,
        loading: false,
        generateClass: {
          codeType: "onemonth",
          prefix: "",
          count: 10,
          length: 6,
        },
        generateClassError: { prefix: "", count: "", length: "" },
        data: { title: "", description: "" },
      });
  };

  handleChange = (event) => {
    let eventId = event.target.id;
    let tempGenerateClass = this.state.generateClass;
    tempGenerateClass[eventId] = event.target.value;

    this.setState({
      generateClass: tempGenerateClass,
    });
  };

  handleChangeNumber = (id, value) => {
    let eventId = id;
    let tempGenerateClass = this.state.generateClass;
    tempGenerateClass[eventId] = value;

    this.setState({
      generateClass: tempGenerateClass,
    });
  };

  handleDropDown = (id, value) => {
    let tempGenerateClass = this.state.generateClass;
    tempGenerateClass[id] = value;
    this.setState({
      generateClass: tempGenerateClass,
    });
  };

  handlePagination = (page) => {
    if (this.state.search === "") {
      let offset = (page - 1) * 100;
      this.setState({ page: page }, () => {
        this.handleGetCode(100, offset);
      });
    }
  };

  promoCodeType = () => {
    return (
      <>
        <Option key={1} value="onemonth">
          One Month
        </Option>
        <Option key={2} value="threemonths">
          Three Months
        </Option>
        <Option key={3} value="sixmonths">
          Six Months
        </Option>
        <Option key={4} value="twelvemonths">
          Twelve Months
        </Option>
      </>
    );
  };

  renderTopBarContent = () => {
    return (
      <>
        <TopBarBreadcrumb style={{ left: 20 }}>
          <BreadcrumbActive>Redeem Codes (Me Books Plus)</BreadcrumbActive>
        </TopBarBreadcrumb>

        <TopBarIcon
          onClick={this.handleGenerateDialog.bind(this, true, false)}
          style={{ left: 285 }}
        />
        <TopBarSearch
          value={this.state.search}
          onChange={this.handleSearch}
          placeholder="Search"
        />
      </>
    );
  };

  renderContent = () => {
    return (
      <>
        <TableLayout
          columns={columns}
          dataSource={this.renderRedeemCodes()}
          pagination={false}
          scroll={{ y: window.innerHeight - 250 }}
        />
        <NavBar>
          <Pagination
            current={this.state.page}
            pageSize={1}
            onChange={this.handlePagination}
            total={
              this.state.search === "" && Math.ceil(this.state.codeLength / 100)
            }
            showSizeChanger={false}
          />
        </NavBar>
      </>
    );
  };

  renderRedeemCodes = () => {
    let redeemCodeView = [];

    if (this.state.redeemCodes && this.state.redeemCodes.length > 0)
      this.state.redeemCodes.map((eachCode, index) => {
        if (
          eachCode.code &&
          eachCode.code
            .toLowerCase()
            .trim()
            .includes(this.state.search.toLowerCase().trim())
        )
          redeemCodeView.push({
            key: index,
            code: <TableContentDefault>{eachCode.code}</TableContentDefault>,
            type: <TableContentDefault>{eachCode.type}</TableContentDefault>,
            usedBy: (
              <TableContentDefault>
                {eachCode.user_id ? eachCode.user_id : "-"}
              </TableContentDefault>
            ),
          });
      });
    return redeemCodeView;
  };

  renderModalContent = () => {
    if (this.state.loading)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: 20,
          }}
        >
          <FormText>Generating... </FormText>
          <FormText>Please do not close the browser</FormText>
        </div>
      );
    else if (this.state.data.title && this.state.data.description)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: 20,
            marginBottom: 0,
          }}
        >
          <FormText>{this.state.data.title}</FormText>
          <FormText style={{ textAlign: "center" }}>
            {this.state.data.description}
          </FormText>
          <StyledButtonContainer>
            <StyledButton
              onClick={this.handleGenerateDialog.bind(this, false, true)}
            >
              Ok
            </StyledButton>
          </StyledButtonContainer>
        </div>
      );
    else
      return (
        <>
          <CustomForm style={{ marginTop: 0, marginBottom: 0 }}>
            <CustomForm.Item>
              <FormText>Type of Promo Code</FormText>
              <Select
                onChange={this.handleDropDown.bind(this, "codeType")}
                placeholder="Select Roles"
                style={{ width: "100%", marginBottom: 20 }}
                value={this.state.generateClass.codeType}
              >
                {this.promoCodeType()}
              </Select>
            </CustomForm.Item>

            <CustomForm.Item
              validateStatus={
                this.state.generateClassError["prefix"] ? "error" : "success"
              }
              help={
                this.state.generateClassError["prefix"]
                  ? this.state.generateClassError["prefix"]
                  : null
              }
            >
              <FormText>Prefix of Promo Code</FormText>
              <Input
                id="prefix"
                value={this.state.generateClass.prefix}
                onChange={this.handleChange}
                placeholder="MBN"
                style={{ borderRadius: 10, marginBottom: 10 }}
              />
            </CustomForm.Item>

            <CustomForm.Item
              validateStatus={
                this.state.generateClassError["count"] ? "error" : "success"
              }
              help={
                this.state.generateClassError["count"]
                  ? this.state.generateClassError["count"]
                  : null
              }
            >
              <FormText>Number of Promo Code Generated</FormText>
              <InputNumber
                min={1}
                defaultValue={1000}
                value={this.state.generateClass.count}
                onChange={this.handleChangeNumber.bind(this, "count")}
                style={{ width: "100%", borderRadius: 10, marginBottom: 10 }}
              />
            </CustomForm.Item>

            <CustomForm.Item
              validateStatus={
                this.state.generateClassError["length"] ? "error" : "success"
              }
              help={
                this.state.generateClassError["length"]
                  ? this.state.generateClassError["length"]
                  : null
              }
            >
              <FormText>Promo Code Length</FormText>
              <InputNumber
                min={6}
                max={9}
                defaultValue={6}
                value={this.state.generateClass.length}
                style={{ width: "100%", marginBottom: 10, borderRadius: 10 }}
                onChange={this.handleChangeNumber.bind(this, "length")}
              />
            </CustomForm.Item>
            <StyledButtonContainer>
              <StyledButton onClick={this.handleGenerateCode}>
                Generate Code
              </StyledButton>
            </StyledButtonContainer>
          </CustomForm>
        </>
      );
  };

  render() {
    return (
      <>
        <Modal
          title="Generate Promo Codes"
          visible={this.state.generate}
          onCancel={this.handleGenerateDialog.bind(this, false, false)}
          footer={null}
        >
          {this.renderModalContent()}
        </Modal>
        <TopBar>{this.renderTopBarContent()}</TopBar>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </>
    );
  }
}

export default RedeemCode;

const StyledButton = styled(Button)`
  border-radius: 10px;
  background: rgb(10, 133, 255) !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  border: none !important;
  width: auto;
  height: 40px;
  &:hover {
    background: rgb(64, 155, 255) !important;
  }
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`;
